import React from 'react';
import axios from 'axios';
import Loader from './../../Loader.js';
import toast, { Toaster } from 'react-hot-toast';
import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

export default function CourseLinkMultiple() {
  const [loading, setLoading] = useState(true);
  const [courses, setCourses] = useState([]);
  const [open, setOpen] = useState(false);
  const course = useRef(null);
  const modules = useRef({});
  const [selected, setSelected] = useState([]);
  const navigate = useNavigate();

  const toggle = (inout,value) => {
    let ary = JSON.parse(JSON.stringify(selected));
    if (inout) ary.push(value);
    else ary.splice(ary.indexOf(value),1);
    setSelected(ary);
  }

  const createCourseHolder = async () => {
    //name, modules
    setLoading(true);
    var obj = {};
    obj.ids = [];
    for (var i=0; i<selected.length; i++) {
        obj.ids.push(
            courses[selected[i]].id
        )
    }
    obj.name = document.getElementById("course-holder-name").value;
    obj.eartraining = modules.current.eartraining;
    obj.singing = modules.current.singing;
    obj.counterpoint = modules.current.counterpoint;
    obj.analysis = modules.current.analysis;

    try {
      var res = await axios.post('/api/lms/create-lms-course',obj);
      if (res.data.noteacher) {
        alert('Course creation failed!');
        return;
      }
      window.location.replace('/courses');
    } catch(e) {
      //error creating course
    }
  }

  useEffect(()=>{
    const getCourses = async () => {
      var res = await axios.get('/api/lms/getcourses');
      if (res.data.needflow) {
        window.location.href = res.data.url;
        return;
      }
      var crs = res.data;
      crs.sort(function(a, b) {
        return (b.created_at < a.created_at) ? -1 : ((b.created_at > a.created_at) ? 1 : 0);
      });
      setCourses(res.data);
      setLoading(false);
    }
    getCourses()
  },[])

  if (loading) {
    return (
      <div style={{padding:'15px'}}>
          <div>Loading courses</div>
          <Loader/>
      </div>
    )
  }

  return (
    <div style={{padding:'15px'}}>
        <h2 style={{marginTop:0}}>Link Canvas Courses</h2>
        <p>Choose courses below to link to Eartrainer.io. This will link <b>ALL</b> courses to a single course in Eartrainer.io.</p>
        <p>This will allow you to manage assignments in the Eartrainer.io dashboard and have the changes synced across every Canvas course.</p>
        <div className="courses-holder">
          {
              courses.map((item,index)=>{
                return (
                <div key={item+index} className="course-link-item">
                  <div>{item.name}</div>
                  {(item.linkedToEartrainer)?<div style={{color:'green',fontWeight:'bold'}}>Linked</div>:<input style={{cursor:'pointer'}} type="checkbox" onChange={(e)=>{toggle(e.target.checked,index)}}></input>}
                </div>)
              })
          }
        </div>
        <div style={{marginTop:'1em'}}>
            <button onClick={()=>{setOpen(true)}} disabled={selected.length==0?true:false} style={{fontSize:'20px',cursor:'pointer'}}>Link Selected</button>
        </div>
        { open && 
          <div id={"bigshit-creator"} style={{width:'100%',height:'100%',position:'absolute',top:'0px',left:'0px',zIndex:100000}}>
            <div style={{width:'100%',height:'100%',opacity:0.5,background:'black'}}></div>
            <div style={{minWidth:'500px',background:'white',position:'absolute',top:'50%',left:'50%',transform:'translate(-50%,-50%)',padding:'25px'}}>
              <h2 style={{marginTop:'0px'}}>Finish Linking</h2>
              <p>All selected courses will be linked to a single Eartrainer.io course.</p>
              <div>
                <input type="text" placeholder="Course Name" id="course-holder-name"></input>
              </div>
              <div>
                <p>Please choose which assignment modules to include:</p>
              </div>
              <div className="moduleCheck">
                <input onChange={(e)=>{modules.current.eartraining=e.target.checked;}} type="checkbox" id="eartraining" name="eartraining"/>
                <label htmlFor="eartraining">Ear Training</label>
              </div>
              <div className="moduleCheck">
                <input onChange={(e)=>{modules.current.singing=e.target.checked;}} type="checkbox" id="singing" name="singing"/>
                <label htmlFor="singing">Singing</label>
              </div>
              <div className="moduleCheck">
                <input onChange={(e)=>{modules.current.counterpoint=e.target.checked;}} type="checkbox" id="counterpoint" name="counterpoint"/>
                <label htmlFor="counterpoint">Counterpoint</label>
              </div>
              <div className="moduleCheck">
                <input onChange={(e)=>{modules.current.analysis=e.target.checked;}} type="checkbox" id="analysis" name="analysis"/>
                <label htmlFor="analysis">Analysis</label>
              </div>
              <div style={{marginTop:'1em',display:'flex',alignItems:'center',justifyContent:'center',gap:'0.25em'}}>
                <button onClick={()=>{setOpen(false)}}>Cancel</button>
                <button onClick={()=>{createCourseHolder()}}>Finish Linking</button>
              </div>
            </div>
          </div>
        }
        <Toaster/>
    </div>
  )
}