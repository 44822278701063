import copy from './copy.svg';
import { Tooltip } from 'react-tooltip';

export default function Copy(props) {
  const id = Math.random();
  return (
    <>
      <img data-tooltip-id={"question-type-tooltip-"+id} className="icon" src={copy} alt="copy" />
    </>
  )
};