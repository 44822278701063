import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { courseAdded } from './../../../slices/course/courseSlice';
import { useNavigate } from "react-router-dom";
import axios from 'axios';

export default function AddPostForm() {
    const [title, setTitle] = useState('')
    const [modules, setModules] = useState({'Ear Training':false,'Singing':false,'Counterpoint':false,'Analysis':false})

    const navigate = useNavigate();

    function goToCourses() {
      navigate("/courses");
    }

    const dispatch = useDispatch()
  
    const onTitleChanged = e => setTitle(e.target.value)

    const onAddCourseClicked = async () => {
        if (title && moduleCheck()) {
          //create course API
          //save to database
          let res = await axios.post('/create-course',{name:title,eartraining:modules['Ear Training'],singing:modules.Singing,counterpoint:modules.Counterpoint,analysis:modules.Analysis})
          //then put it in list
          dispatch(
            courseAdded(res.data)
          )
    
          setTitle('')
          setModules({'Ear Training':false,'Singing':false,'Counterpoint':false,'Analysis':false})

          goToCourses()
        }
    }

    const moduleCheck = () => {
      let checked = false;
      for (var i=0; i<moduleList.length; i++)
        if (modules[moduleList[i]])
            checked = true;
      return checked;
    }

    const moduleList = ['Ear Training','Singing','Counterpoint','Analysis'];
    const moduleOptions = () => {
      return moduleList.map((item,index)=>{
        return <div key={item+index} className="module-choice"><span>{item}</span><input checked={modules[item]} onChange={(e)=>{setModules({...modules,[''+item]:e.target.checked})}} type="checkbox"></input></div>
      })
    }
  
    return (
      <section>
        <form>
          <div className="form-heading mb25">Course Title</div>
          <input
            type="text"
            id="courseTitle"
            name="courseTitle"
            value={title}
            onChange={onTitleChanged}
          />
          <div className="flexer mt1 mb25">
            <div className="form-heading">Course Modules</div><div className="info">(?)</div>
          </div>
          <div className="module-choice-holder">
            {moduleOptions()}
          </div>
          <button className="mt1" type="button" onClick={onAddCourseClicked}>
            Create Course
            </button>
        </form>
      </section>
    )
  }