import { createSlice, nanoid } from '@reduxjs/toolkit';

const initialState = [];

export const courseSlice = createSlice({
  name: 'course',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    courseAdded(state, action) {
      state.push(action.payload)
    },
    courseDeleted(state, action) {
      const { id } = action.payload;
      let arr = state.filter(item => item.id !== id)
      return arr;
    },
    courseUpdated(state, action) {
      const { id, title, modules } = action.payload
      const existingCourse = state.find(post => post.id === id)
      if (existingCourse) {
        existingCourse.title = title
        existingCourse.modules = modules
      }
    },
    setCourses(state, action) {
      return action.payload;
    },
  },
});

export const { courseAdded, courseUpdated, courseDeleted, setCourses } = courseSlice.actions;
//export const selectCourse = (state) => state.user.value;

export default courseSlice.reducer;
