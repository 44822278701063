import Snap from 'snapsvg-cjs';

export function hfac(type,options,bullshit) {
	if (options) {
		var holder = document.createElement(type);
		for (var i=0; i<options.length; i++) {
			var opt = document.createElement('option');
			opt.innerHTML = options[i];
			if (bullshit) {
			  opt.ariaLabel = "time signature option "+options[i];
			}
			holder.appendChild(opt);
		}
		return holder;
	} else {
		return document.createElement(type);
	}
  }

export function makePOST(url, formData) {
	const iPromise = new Promise((resolve, reject) => {
        var xhr = new XMLHttpRequest()
        xhr.open('POST', url, true)
        xhr.addEventListener('readystatechange', function(e) {
            if (xhr.readyState == 4 && xhr.status == 200) {
                // Done. Inform the user
                resolve(JSON.parse(xhr.responseText));
            }
            else if (xhr.readyState == 4 && xhr.status != 200) {
                // Error. Inform the user
                reject(JSON.parse(xhr.responseText));
            }
        })
    
        xhr.send(formData);
	})
  
	return iPromise;
}

//utils
export function parseScore(parent, osmd) {
    //get bottom of systems
    var systems = osmd.graphic.musicPages[0].musicSystems;
    var maxY;
    var maxTop, minBot;
    var minY;
    var voices = ["Treble", "Bass"];
    if (osmd.graphic.MeasureList[0].length==1)
        voices = ["Treble"];
    if (osmd.graphic.MeasureList[0].length == 3)
        voices = ["Top", "Middle", "Bass"];
    for (var i = 0; i < systems.length; i++) {
      maxY = -1000000;
      minY = 1000000;
      maxTop = -1000000;
      minBot = 1000000;
      var measures = systems[i].graphicalMeasures;
      for (var j = 0; j < measures.length; j++) {
        var m = measures[j][1];
        if (!m) m = measures[j][0];
        var y = m.boundingBox.absolutePosition.y + m.boundingBox.boundingRectangle.height + m.boundingBox.borderTop;
        if (y > maxY) maxY = y;
  
        //look for min of bot
        var ym = m.boundingBox.absolutePosition.y;
        if (ym < minBot) minBot = ym;
  
        //look for max of top
        var mtop = measures[j][0];
        var ytop = mtop.boundingBox.absolutePosition.y + mtop.boundingBox.boundingRectangle.height + mtop.boundingBox.borderTop;
        if (ytop > maxTop) maxTop = ytop;
  
        //look for min of top
        var yt = mtop.boundingBox.absolutePosition.y;
        if (yt < minY) minY = yt;
      }
      systems[i].maxY = maxY + 3;
      systems[i].midY = (maxTop + minBot) / 2;
      systems[i].minY = minY - 1;
      //parent.snapScore.circle(0,10*(maxY+3),5,5).attr({fill:'red'})
    }
  
    //getting to a note
    var obj = {};
    obj.measures = [];
    obj.noteheads = [];
    var mbj, sbj, ve, gve;
    var pbb = parent.scoreSVG.getBoundingClientRect();
    for (var i = 0; i < osmd.graphic.MeasureList.length; i++) {
      mbj = {};
      mbj.maxY = osmd.graphic.MeasureList[i][0].parentMusicSystem.maxY;
      mbj.midY = osmd.graphic.MeasureList[i][0].parentMusicSystem.midY;
      mbj.minY = osmd.graphic.MeasureList[i][0].parentMusicSystem.minY;
  
      for (var v = 0; v < voices.length; v++) {
        sbj = {};
        sbj.verticalEntries = [];
        sbj.position =
          osmd.graphic.MeasureList[i][v].boundingBox.absolutePosition;
        sbj.box = osmd.graphic.MeasureList[i][v].boundingBox.boundingRectangle;
        for (var j = 0; j < osmd.graphic.MeasureList[i][v].staffEntries.length; j++) {
          ve = {};
          ve.graphicalVoiceEntries = [];
          for (var k = 0; k < osmd.graphic.MeasureList[i][v].staffEntries[j].graphicalVoiceEntries.length; k++) {
            var entry = osmd.graphic.MeasureList[i][v].staffEntries[j].graphicalVoiceEntries[k];
            gve = {};
            gve.bb = entry.boundingBox;
            gve.notes = entry.notes;
            gve.noteheads = [];
            for (var n = 0; n < entry.notes.length; n++) {
              var nt = entry.notes[n];
              var vf = nt.vfnote[0].attrs.el;
              var ary = vf.getElementsByClassName("vf-notehead");
              var nh;
              for (var m = 0; m < ary.length; m++) {
                nh = {};
                nh.ele = ary[m];
                nh.bb = ary[m].getBoundingClientRect(); //only use height and width
                nh.x = nh.bb.x - pbb.x;
                nh.y = nh.bb.y - pbb.y;
                nh.note = nt;
                nh.measure = i;
                nh.staff = voices[v];
                nh.gve = gve;
                nh.index = i + "|" + j + "|" + k + "|" + m;
                var found = false;
                for (var z = 0; z < gve.noteheads.length; z++) {
                  if (gve.noteheads[z].x == nh.x && gve.noteheads[z].y == nh.y)
                    found = true;
                }
                if (found) continue;
                gve.noteheads.push(nh);
                obj.noteheads.push(nh);
              }
            }
            ve.graphicalVoiceEntries.push(gve);
          }
          sbj.verticalEntries.push(ve);
        }
        mbj[voices[v]] = sbj;
      }
  
      obj.measures.push(mbj);
    }
    return obj;
  }

export function svgToGlobal(svg, x) {
    if (!svgPoint) svgPoint = svg.createSVGPoint();
    svgPoint.x = x;
    svgPoint.y = 0;
    return svgPoint.matrixTransform(svg.getScreenCTM());
  }

export function findNote(cp,hnotes,key,mode) {
    var y = cp.y;
    var lnsp = 17.75;
    var nd = 0;
    for (var i = 0; i < hnotes.length; i++) {
        if (y > (i * lnsp) / 2 - 29 - 1.5 * lnsp) nd = i;
    }
    var nts = scaleMod(copyAry(hnotes),key,mode);
    return nts[nd];
};

export function timeSignature(staff,ts,rhythmTog) {
	var S = staff.whole;
	var t1, t2, t1a, t1b;
	var m = 40; //numspace
	var sp = 225; //staffspace
	var ofs = 80;
	var x=0;
	switch(ts) {
		case "7/4":
			t1 = S.path(sevenPath).transform('t'+(x+22)+' '+(2-ofs)+' s0.081 -0.081');
			t2 = S.path(fourPath).transform('t'+x+' '+(m-ofs)+' s0.081 -0.081');
			break;
		case "6/4":
			t1 = S.path(sixPath).transform('t'+(x+28)+' '+(-ofs)+' s0.081 -0.081');
			t2 = S.path(fourPath).transform('t'+x+' '+(m-ofs)+' s0.081 -0.081');
			break;
		case "5/4":
			t1 = S.path(fivePath).transform('t'+(x+25)+' '+(-ofs)+' s0.081 -0.081');
			t2 = S.path(fourPath).transform('t'+x+' '+(m-ofs)+' s0.081 -0.081');
			break;
		case "4/4":
			t1 = S.path(fourPath).transform('t'+x+' '+(-ofs)+' s0.081 -0.081');
			t2 = S.path(fourPath).transform('t'+x+' '+(m-ofs)+' s0.081 -0.081');
			break;
		case "25/4":
			t1 = S.path(fourPath).transform('t'+x+' '+(-ofs)+' s0.081 -0.081');
			t2 = S.path(fourPath).transform('t'+x+' '+(m-ofs)+' s0.081 -0.081');
			break;
		case "3/4":
			t1 = S.path(threePath).transform('t'+(x+34)+' '+(-ofs)+' s0.081 -0.081');
			t2 = S.path(fourPath).transform('t'+x+' '+(m-ofs)+' s0.081 -0.081');
			break;
		case "2/4":
			t1 = S.path(twoPath).transform('t'+(x+15)+' '+(-ofs)+' s0.081 -0.081');
			t2 = S.path(fourPath).transform('t'+x+' '+(m-ofs)+' s0.081 -0.081');
			break;
		case "3/8":
			x+=12;
			t1 = S.path(threePath).transform('t'+(x+20)+' '+(-ofs)+' s0.081 -0.081');
			t2 = S.path(eightPath).transform('t'+x+' '+(m-ofs)+' s0.081 -0.081');
			break;
		case "6/8":
			x+=12;
			t1 = S.path(sixPath).transform('t'+(x+15)+' '+(-ofs)+' s0.081 -0.081');
			t2 = S.path(eightPath).transform('t'+x+' '+(m-ofs)+' s0.081 -0.081');
			break;
		case "7/8":
			x+=12;
			t1 = S.path(sevenPath).transform('t'+(x+10)+' '+(2-ofs)+' s0.081 -0.081');
			t2 = S.path(eightPath).transform('t'+x+' '+(m-ofs)+' s0.081 -0.081');
			break;
		case "9/8":
			x+=12;
			t1 = S.path(ninePath).transform('t'+(x+15)+' '+(-ofs)+' s0.081 -0.081');
			t2 = S.path(eightPath).transform('t'+x+' '+(m-ofs)+' s0.081 -0.081');
			break;
		case "12/8":
			x+=18;
			t1a = S.path(onePath).transform('t'+(x+15)+' '+(-ofs)+' s0.081 -0.081');
			t1b = S.path(twoPath).transform('t'+(x+15)+' '+(-ofs)+' s0.081 -0.081');
			t1 = S.group(t1a,t1b);
			t2 = S.path(eightPath).transform('t'+x+' '+(m-ofs)+' s0.081 -0.081');
			break;
		case "2/2":
			t1 = S.path(twoPath).transform('t'+(x+15)+' '+(-ofs)+' s0.081 -0.081');
			t2 = S.path(twoPath).transform('t'+(x+15)+' '+(40-ofs)+' s0.081 -0.081');
			break;
		case "3/2":
			x+=14;
			t1 = S.path(threePath).transform('t'+(x+18)+' '+(0.5-ofs)+' s0.081 -0.081');
			t2 = S.path(twoPath).transform('t'+(x+1)+' '+(40.5-ofs)+' s0.081 -0.081');
			break;
		case "4/2":
			x+=14;
			t1 = S.path(fourPath).transform('t'+(x-16)+' '+(m-ofs-39.5)+' s0.081 -0.081');
			t2 = S.path(twoPath).transform('t'+(x+1)+' '+(40.5-ofs)+' s0.081 -0.081');
			break;
	}
	var whole = S.group(t1,t2);

	//whole.node.tabIndex = "0";
	//whole.node.ariaLabel = "Time signature, "+parseInt(ts.split('/')[0])+", "+parseInt(ts.split('/')[1]);
	
	whole.transform('t-150 -155 s0.88');
	return whole;
}

export function svgRhythm(S,type) {
	var svg=S.group();
	var x = 0;
	var y = 0;
	var note1, note2;
	var sdx = 2.2;
	var sdy = 3.2;
	var stemW = 2;
	var stemH = 50;
	if (!y)
		var y=215;
	//type = 'trip';
	switch(type) {
		case '1s1e1s':
			x-=152.5;
			y-=179.5;
			note1 = S.path(closedPath).transform('t'+(x)+' '+y+' s0.07 -0.07');
			svg.append(note1);
			var bb = note1.getBBox();
			var stem = S.rect(bb.x+bb.width-sdx,bb.y-stemH+sdy,stemW,stemH);
			svg.append(stem);
			
			stem = S.rect(bb.x+bb.width-sdx,bb.y-stemH+3,60+stemW,7); //beam
			svg.append(stem);
			
			stem = S.rect(bb.x+bb.width-sdx,bb.y-stemH+14,30+stemW,7); //beam
			svg.append(stem);
			
			note2 = S.path(closedPath).transform('t'+(x+30)+' '+y+' s0.07 -0.07');
			svg.append(note2);
			bb = note2.getBBox();
			stem = S.rect(bb.x+bb.width-sdx,bb.y-stemH+sdy,stemW,stemH);
			svg.append(stem);
			
			note2 = S.path(closedPath).transform('t'+(x+60)+' '+y+' s0.07 -0.07');
			svg.append(note2);
			bb = note2.getBBox();
			stem = S.rect(bb.x+bb.width-sdx,bb.y-stemH+sdy,stemW,stemH);
			svg.append(stem);
			break;
		case '2s1e':
			x-=152.5;
			y-=179.5;
			note1 = S.path(closedPath).transform('t'+(x)+' '+y+' s0.07 -0.07');
			svg.append(note1);
			var bb = note1.getBBox();
			var stem = S.rect(bb.x+bb.width-sdx,bb.y-stemH+sdy,stemW,stemH);
			svg.append(stem);
			
			stem = S.rect(bb.x+bb.width-sdx,bb.y-stemH+3,60+stemW,7); //beam
			svg.append(stem);
			
			stem = S.rect(bb.x+bb.width-sdx,bb.y-stemH+14,30+stemW,7); //beam
			svg.append(stem);
			
			note2 = S.path(closedPath).transform('t'+(x+30)+' '+y+' s0.07 -0.07');
			svg.append(note2);
			bb = note2.getBBox();
			stem = S.rect(bb.x+bb.width-sdx,bb.y-stemH+sdy,stemW,stemH);
			svg.append(stem);
			
			note2 = S.path(closedPath).transform('t'+(x+60)+' '+y+' s0.07 -0.07');
			svg.append(note2);
			bb = note2.getBBox();
			stem = S.rect(bb.x+bb.width-sdx,bb.y-stemH+sdy,stemW,stemH);
			svg.append(stem);
			break;
		case '1e2s':
			x-=152.5;
			y-=179.5;
			note1 = S.path(closedPath).transform('t'+(x)+' '+y+' s0.07 -0.07');
			svg.append(note1);
			var bb = note1.getBBox();
			var stem = S.rect(bb.x+bb.width-sdx,bb.y-stemH+sdy,stemW,stemH);
			svg.append(stem);
			
			stem = S.rect(bb.x+bb.width-sdx,bb.y-stemH+3,60+stemW,7); //beam
			svg.append(stem);
			
			stem = S.rect(bb.x+bb.width-sdx+30,bb.y-stemH+14,30+stemW,7); //beam
			svg.append(stem);
			
			note2 = S.path(closedPath).transform('t'+(x+30)+' '+y+' s0.07 -0.07');
			svg.append(note2);
			bb = note2.getBBox();
			stem = S.rect(bb.x+bb.width-sdx,bb.y-stemH+sdy,stemW,stemH);
			svg.append(stem);
			
			note2 = S.path(closedPath).transform('t'+(x+60)+' '+y+' s0.07 -0.07');
			svg.append(note2);
			bb = note2.getBBox();
			stem = S.rect(bb.x+bb.width-sdx,bb.y-stemH+sdy,stemW,stemH);
			svg.append(stem);
			break;
		case '1de1s':
			x-=152.5;
			y-=179.5;
			note1 = S.path(closedPath).transform('t'+(x)+' '+y+' s0.07 -0.07');
			svg.append(note1);
			var bb = note1.getBBox();
			var stem = S.rect(bb.x+bb.width-sdx,bb.y-stemH+sdy,stemW,stemH);
			svg.append(stem);
			
			stem = S.rect(bb.x+bb.width-sdx,bb.y-stemH+3,50+stemW,7); //beam
			svg.append(stem);
			
			stem = S.rect(bb.x+bb.width-sdx+33,bb.y-stemH+14,17+stemW,7); //beam
			svg.append(stem);
			
			var dot = S.circle(bb.x+bb.width+9,bb.y+bb.height/2-8,3);
			svg.append(dot);
			
			note2 = S.path(closedPath).transform('t'+(x+50)+' '+y+' s0.07 -0.07');
			svg.append(note2);
			bb = note2.getBBox();
			stem = S.rect(bb.x+bb.width-sdx,bb.y-stemH+sdy,stemW,stemH);
			svg.append(stem);	
			break;
		case '1s1de':
			x-=152.5;
			y-=179.5;
			note1 = S.path(closedPath).transform('t'+(x)+' '+y+' s0.07 -0.07');
			svg.append(note1);
			var bb = note1.getBBox();
			var stem = S.rect(bb.x+bb.width-sdx,bb.y-stemH+sdy,stemW,stemH);
			svg.append(stem);
			
			stem = S.rect(bb.x+bb.width-sdx,bb.y-stemH+3,50+stemW,7); //beam
			svg.append(stem);
			
			stem = S.rect(bb.x+bb.width-sdx,bb.y-stemH+14,17+stemW,7); //beam
			svg.append(stem);
			
			note2 = S.path(closedPath).transform('t'+(x+50)+' '+y+' s0.07 -0.07');
			svg.append(note2);
			bb = note2.getBBox();
			var dot = S.circle(bb.x+bb.width+9,bb.y+bb.height/2-8,3);
			svg.append(dot);
			stem = S.rect(bb.x+bb.width-sdx,bb.y-stemH+sdy,stemW,stemH);
			svg.append(stem);	
			break;
		case '88':
			x-=152.5;
			y-=179.5;
			note1 = S.path(closedPath).transform('t'+(x)+' '+y+' s0.07 -0.07');
			svg.append(note1);
			var bb = note1.getBBox();
			var stem = S.rect(bb.x+bb.width-sdx,bb.y-stemH+sdy,stemW,stemH);
			svg.append(stem);
			stem = S.rect(bb.x+bb.width-sdx,bb.y-stemH+3,30+stemW,7); //beam
			svg.append(stem);
			
			note2 = S.path(closedPath).transform('t'+(x+30)+' '+y+' s0.07 -0.07');
			svg.append(note2);
			bb = note2.getBBox();
			stem = S.rect(bb.x+bb.width-sdx,bb.y-stemH+sdy,stemW,stemH);
			svg.append(stem);
			break;
		case '1':
			x-=152.5;
			y-=168.5;
			var note = S.path(wholePath).transform('t'+(x-81.5)+' '+(y-11)+' s0.068 -0.068');
			svg.append(note);
			break;
		case '3t':
			x-=152.5;
			y-=179.5;
			stemH-=10;
			note1 = S.path(closedPath).transform('t'+(x)+' '+y+' s0.07 -0.07');
			svg.append(note1);
			var bb = note1.getBBox();
			var stem = S.rect(bb.x+bb.width-sdx,bb.y-stemH+sdy,stemW,stemH);
			svg.append(stem);
			
			stem = S.rect(bb.x+bb.width-sdx,bb.y-stemH+3,60+stemW,7); //beam
			svg.append(stem);
			
			note2 = S.path(closedPath).transform('t'+(x+30)+' '+y+' s0.07 -0.07');
			svg.append(note2);
			bb = note2.getBBox();
			stem = S.rect(bb.x+bb.width-sdx,bb.y-stemH+sdy,stemW,stemH);
			svg.append(stem);
			
			note2 = S.path(closedPath).transform('t'+(x+60)+' '+y+' s0.07 -0.07');
			svg.append(note2);
			bb = note2.getBBox();
			stem = S.rect(bb.x+bb.width-sdx,bb.y-stemH+sdy,stemW,stemH);
			svg.append(stem);
			
			svg.append(S.text(x+200,y-50,'3').attr({fontSize:'20px',fontStyle:'italic',fontWeight:'bold'}));
			break;
		case '888':
			x-=152.5;
			y-=179.5;
			note1 = S.path(closedPath).transform('t'+(x)+' '+y+' s0.07 -0.07');
			svg.append(note1);
			var bb = note1.getBBox();
			var stem = S.rect(bb.x+bb.width-sdx,bb.y-stemH+sdy,stemW,stemH);
			svg.append(stem);
			
			stem = S.rect(bb.x+bb.width-sdx,bb.y-stemH+3,60+stemW,7); //beam
			svg.append(stem);
			
			note2 = S.path(closedPath).transform('t'+(x+30)+' '+y+' s0.07 -0.07');
			svg.append(note2);
			bb = note2.getBBox();
			stem = S.rect(bb.x+bb.width-sdx,bb.y-stemH+sdy,stemW,stemH);
			svg.append(stem);
			
			note2 = S.path(closedPath).transform('t'+(x+60)+' '+y+' s0.07 -0.07');
			svg.append(note2);
			bb = note2.getBBox();
			stem = S.rect(bb.x+bb.width-sdx,bb.y-stemH+sdy,stemW,stemH);
			svg.append(stem);
			break;
		case '4s':
			x-=152.5;
			note1 = S.path(closedPath).transform('t'+(x)+' '+(y-179.5)+' s0.07 -0.07');
			svg.append(note1);
			var bb = note1.getBBox();
			var stem = S.rect(bb.x+bb.width-sdx,bb.y-stemH+sdy,stemW,stemH);
			svg.append(stem);
			
			stem = S.rect(bb.x+bb.width-sdx,bb.y-stemH+3,75+stemW,7); //beam
			svg.append(stem);
			
			stem = S.rect(bb.x+bb.width-sdx,bb.y-stemH+14,75+stemW,7);
			svg.append(stem);
			
			note2 = S.path(closedPath).transform('t'+(x+25)+' '+(y-179.5)+' s0.07 -0.07');
			svg.append(note2);
			bb = note2.getBBox();
			stem = S.rect(bb.x+bb.width-sdx,bb.y-stemH+sdy,stemW,stemH);
			svg.append(stem);
			
			note2 = S.path(closedPath).transform('t'+(x+50)+' '+(y-179.5)+' s0.07 -0.07');
			svg.append(note2);
			bb = note2.getBBox();
			stem = S.rect(bb.x+bb.width-sdx,bb.y-stemH+sdy,stemW,stemH);
			svg.append(stem);
			
			note2 = S.path(closedPath).transform('t'+(x+75)+' '+(y-179.5)+' s0.07 -0.07');
			svg.append(note2);
			bb = note2.getBBox();
			stem = S.rect(bb.x+bb.width-sdx,bb.y-stemH+sdy,stemW,stemH);
			svg.append(stem);
			break;
		case '8':
			x-=151;
			y-=179.5;
			var flag = S.path(eighthFlagPath).transform('t'+(x+55)+' '+(y-380.5)+' s0.07 0.07');
			svg.append(flag);
			var note = S.path(closedPath).transform('t'+(x-2)+' '+(y)+' s0.07 -0.07');
			svg.append(note);
			var bb = note.getBBox();
			var stem = S.rect(bb.x+bb.width-sdx,bb.y-stemH+sdy,stemW,stemH);
			svg.append(stem);
			break;
		case '48':
			note1 = S.path(closedPath).transform('t'+(x-152.5)+' '+(y-179.5)+' s0.07 -0.07');
			svg.append(note1);
			var bb = note1.getBBox();
			var stem = S.rect(bb.x+bb.width-sdx,bb.y-stemH+sdy,stemW,stemH);
			svg.append(stem);
			
			x-=151;
			y-=179.5;
			x+=50;
			var flag = S.path(eighthFlagPath).transform('t'+(x+55)+' '+(y-380.5)+' s0.07 0.07');
			svg.append(flag);
			var note = S.path(closedPath).transform('t'+(x-2)+' '+(y)+' s0.07 -0.07');
			svg.append(note);
			var bb = note.getBBox();
			var stem = S.rect(bb.x+bb.width-sdx,bb.y-stemH+sdy,stemW,stemH);
			svg.append(stem);
			break;
		case '84':
			x-=151;
			y-=179.5;
			var flag = S.path(eighthFlagPath).transform('t'+(x+55)+' '+(y-380.5)+' s0.07 0.07');
			svg.append(flag);
			var note = S.path(closedPath).transform('t'+(x-2)+' '+(y)+' s0.07 -0.07');
			svg.append(note);
			var bb = note.getBBox();
			var stem = S.rect(bb.x+bb.width-sdx,bb.y-stemH+sdy,stemW,stemH);
			svg.append(stem);
			
			x+=151;
			x+=50;
			y+=179.5;
			
			note1 = S.path(closedPath).transform('t'+(x-152.5)+' '+(y-179.5)+' s0.07 -0.07');
			svg.append(note1);
			var bb = note1.getBBox();
			var stem = S.rect(bb.x+bb.width-sdx,bb.y-stemH+sdy,stemW,stemH);
			svg.append(stem);
			break;
		case '1dq~1e':
			x-=152.5;
			y-=179.5;
			note1 = S.path(closedPath).transform('t'+(x)+' '+y+' s0.07 -0.07');
			svg.append(note1);
			var bb = note1.getBBox();
			var stem = S.rect(bb.x+bb.width-sdx,bb.y-stemH+sdy,stemW,stemH);
			var dot = S.circle(bb.x+bb.width+9,bb.y+bb.height/2-8,3);
			svg.append(stem);
			svg.append(dot);
			x+=50;
			var flag = S.path(eighthFlagPath).transform('t'+(x+105.5)+' '+(y-378.5)+' s0.07 0.07');
			svg.append(flag);
			var note = S.path(closedPath).transform('t'+(x+48)+' '+(y)+' s0.07 -0.07');
			svg.append(note);
			var bb = note.getBBox();
			var stem = S.rect(bb.x+bb.width-sdx,bb.y-stemH+sdy+2,stemW,stemH);
			svg.append(stem);
			note1.y = 35.5;
			note.y = 35.5;
			var tie = drawTie(S,note1,note,1);
			svg.append(tie);
			break;
		case '1dq1e':
			x-=152.5;
			y-=179.5;
			note1 = S.path(closedPath).transform('t'+(x)+' '+y+' s0.07 -0.07');
			svg.append(note1);
			var bb = note1.getBBox();
			var stem = S.rect(bb.x+bb.width-sdx,bb.y-stemH+sdy,stemW,stemH);
			var dot = S.circle(bb.x+bb.width+9,bb.y+bb.height/2-8,3);
			svg.append(stem);
			svg.append(dot);
			x+=50;
			var flag = S.path(eighthFlagPath).transform('t'+(x+75.5)+' '+(y-378.5)+' s0.07 0.07');
			svg.append(flag);
			var note = S.path(closedPath).transform('t'+(x+18)+' '+(y)+' s0.07 -0.07');
			svg.append(note);
			var bb = note.getBBox();
			var stem = S.rect(bb.x+bb.width-sdx,bb.y-stemH+sdy+2,stemW,stemH);
			svg.append(stem);
			break;
		case '1e1dq':
			x-=152.5;
			y-=179.5;
			note1 = S.path(closedPath).transform('t'+(x)+' '+y+' s0.07 -0.07');
			svg.append(note1);
			var bb = note1.getBBox();
			var stem = S.rect(bb.x+bb.width-sdx,bb.y-stemH+sdy,stemW,stemH);
			var dot = S.circle(bb.x+bb.width+9,bb.y+bb.height/2-8,3);
			svg.append(stem);
			svg.append(dot);
			x+=50;
			var flag = S.path(eighthFlagPath).transform('t'+(x+75.5)+' '+(y-378.5)+' s0.07 0.07');
			svg.append(flag);
			var note = S.path(closedPath).transform('t'+(x+18)+' '+(y)+' s0.07 -0.07');
			svg.append(note);
			var bb = note.getBBox();
			var stem = S.rect(bb.x+bb.width-sdx,bb.y-stemH+sdy+2,stemW,stemH);
			svg.append(stem);
			break;
		case '4':
			note1 = S.path(closedPath).transform('t'+(x-152.5)+' '+(y-179.5)+' s0.07 -0.07');
			svg.append(note1);
			var bb = note1.getBBox();
			var stem = S.rect(bb.x+bb.width-sdx,bb.y-stemH+sdy,stemW,stemH);
			svg.append(stem);
			break;
		case '1qr':
			x-=150;
			y-=161;
			note1 = S.path(quarterRestPath).transform('t'+(x+50)+' '+(y-60)+' s0.07 -0.07');
			svg.append(note1);
			break;
		case '1dqr':
			x-=150;
			y-=161;
			note1 = S.path(quarterRestPath).transform('t'+(x+50)+' '+(y-60)+' s0.07 -0.07');
			svg.append(note1);
			var bb = note1.getBBox();
			var dot = S.circle(bb.x+bb.width+9,bb.y+bb.height/2-8,3);
			svg.append(dot);
			break;
		case '1er':
			x-=156;
			y-=161;
			note1 = S.path(eighthRestPath).transform('t'+(x+40)+' '+(y+8)+' s0.06 -0.06');
			svg.append(note1);
			break;
		case '2':
			note1 = S.path(openPath).transform('t'+(x-161)+' '+(y-179.5)+' s0.07 -0.07');
			svg.append(note1);
			var bb = note1.getBBox();
			var stem = S.rect(bb.x+bb.width-sdx,bb.y-stemH+sdy,stemW,stemH);
			svg.append(stem);
			break;
		case '1hr':
			x-=157;
			y-=162;
			note1 = S.path(halfRestPath).transform('t'+(x-17.5)+' '+(y-100)+' s0.07 -0.07');
			svg.append(note1);
			var bb = note1.getBBox();
			//var line = S.rect(bb.x-5,bb.y+bb.height,bb.width+10,4);
			//svg.append(line);
			break;
        case '1wr':
			x-=157;
			y-=152;
			note1 = S.path(halfRestPath).transform('t'+(x-17.5)+' '+(y-100)+' s0.07 -0.07');
			svg.append(note1);
			var bb = note1.getBBox();
			//var line = S.rect(bb.x-5,bb.y+bb.height,bb.width+10,4);
			//svg.append(line);
			break;
		case '2.':
			note1 = S.path(openPath).transform('t'+(x-161)+' '+(y-179.5)+' s0.07 -0.07');
			svg.append(note1);
			var bb = note1.getBBox();
			var stem = S.rect(bb.x+bb.width-sdx,bb.y-stemH+sdy,stemW,stemH);
			var dot = S.circle(bb.x+bb.width+9,bb.y+bb.height/2-8,3);
			svg.append(stem);
			svg.append(dot);
			break;
		case '4.':
			note1 = S.path(closedPath).transform('t'+(x-152.5)+' '+(y-179.5)+' s0.07 -0.07');
			svg.append(note1);
			var bb = note1.getBBox();
			var stem = S.rect(bb.x+bb.width-sdx,bb.y-stemH+sdy,stemW,stemH);
			svg.append(stem);
			var dot = S.circle(bb.x+bb.width+9,bb.y+bb.height/2-8,3);
			svg.append(dot);
			break;
	}
	return svg;
}

export function staff2Global(staff, x) {
    if (!svgPoint) svgPoint = staff.paper.node.createSVGPoint();
    svgPoint.x = x;
    svgPoint.y = 0;
    return svgPoint.matrixTransform(staff.whole.node.getScreenCTM());
}

export function harmonicInput(ele, type) {
    ele.style.fontFamily = 'myFirstFont';
    ele.oninput = function (e) {
        var val = ele.value;
        //while (val.indexOf(' ') != -1) val = val.replace(' ', '');

        //now special shit
        val = val.replace('b', '\u0231');
        val = val.replace('6', '\u01A0');
        val = val.replace('8', '\u01A7');
        val = val.replace('/o', '\u01C1');
        val = val.replace('o', '\u01BD');
        val = val.replace('0', '\u01BD');
        val = val.replace('7', '\u01BE');
        val = val.replace('\u01BE5', '\u01A8');
        val = val.replace('\u01A05', '\u01A2');
        val = val.replace('\u01A04', '\u01A1');
        val = val.replace('43', '\u01A3');
        val = val.replace('42', '\u01A4');
        val = val.replace('53', '\u01A6');
        ele.value = val;

        ele.style.fontSize = '28px';
        if (type == 'rn') {
            ele.style.fontSize = '25px';
            if (val.length == 2) {
                ele.style.fontSize = '24px';
                ele.style.lineHeight = '24';
            }
            if (val.length == 3) {
                ele.style.fontSize = '23px';
                ele.style.lineHeight = '23';
            }
            if (val.length == 4) {
                ele.style.fontSize = '20px';
                ele.style.lineHeight = '20';
            }
            if (val.length == 5) {
                ele.style.fontSize = '17px';
                ele.style.lineHeight = '17';
            }
            if (val.length >= 6) {
                ele.style.fontSize = '14px';
                ele.style.lineHeight = '14';
            }
            if (val == 'CT\u01BD\u01BE') {
                ele.style.fontSize = '16px';
                ele.style.lineHeight = '16';
            }
            if (val == 'CT\u01BD\u01A2') {
                ele.style.fontSize = '16px';
                ele.style.lineHeight = '16';
            }
            if (val == 'CT\u01BD\u01A3') {
                ele.style.fontSize = '16px';
                ele.style.lineHeight = '16';
            }
            if (val == 'CT\u01BD\u01A4') {
                ele.style.fontSize = '16px';
                ele.style.lineHeight = '16';
            }
            if (val == 'cad\u01A1') {
                ele.style.fontSize = '18px';
                ele.style.lineHeight = '18';
            }
        }
    };
}

export function cleanName(nt) {
	return nt.replace('♯','').replace('bb','').replace('\u0231','').replace('x','').replace('n','');
}

export function htmlFactory(type,className,innerHTML) {
    var ele = document.createElement(type);
    if (className)
        ele.className = className;
    if (innerHTML)
        ele.innerHTML = innerHTML;
    return ele;
}

export function makeSnap(id) {
    var s = window.Snap(1, 1);
    document.getElementById(id).appendChild(s.node);
    return s;
}

export function copyAry(ary) {
    var nAry = [];
    for (var i = 0; i < ary.length; i++) nAry.push(ary[i]);
    return nAry;
}

export function scaleMod(nts, key, mode) {
    var scale = scaleMaker(key, mode);
    for (var i = 0; i < nts.length; i++) {
        for (var j = 0; j < scale.length; j++) {
            if (nts[i][0] == scale[j][0]) nts[i] = scale[j] + retNum(nts[i]);
        }
    }
    return nts;
}

export function scaleMaker(tonic, type, direction) {
    tonic = tonic.replace('♭', '\u0231');
    type = type.toLowerCase();
    if (type == 'augmented') {
        //augmented scale, for interval naming
        tonic = tonic.replace('y', '');
        return [
            tonic,
            noteM(noteM(notem(noteM(tonic)))),
            notea(notem(noteM(tonic))),
            noteM(noteM(notem(notem(noteM(tonic))))),
            noteM(noteM(tonic)),
            notea(tonic),
            null,
            notea(notem(tonic)),
        ];
    }
    if (type == 'diminished') {
        //diminished scale, for interval naming
        tonic = tonic.replace('x', '');
        return [
            tonic,
            null,
            null,
            notem(notem(notem(notem(noteM(tonic))))),
            notem(notem(tonic)),
            null,
            notem(notem(notem(tonic))),
            notem(noteM(notem(notem(notem(notem(noteM(tonic))))))),
        ];
    }
    if (type == 'chromatic-up') {
        //chromatic scale
        var crm = ['C', 'C♯', 'D', 'D♯', 'E', 'F', 'F♯', 'G', 'G♯', 'A', 'A♯', 'B'];
        tonic = tonic.replace('z', '');
        while (crm[0] != tonic) crm = invert(crm, 1);
        crm = crm.concat([tonic]);
        return crm;
    }
    if (type == 'chromatic-down') {
        //chromatic descending
        var crm = ['C', 'B', 'B\u0231', 'A', 'A\u0231', 'G', 'G\u0231', 'F', 'E', 'E\u0231', 'D', 'D\u0231'];
        tonic = tonic.replace('q', '');
        while (crm[0] != tonic) crm = invert(crm, 1);
        crm = crm.concat([tonic]);
        return crm;
    }

    //special shit
    /*if (tonic=='G♯') return ['G♯','A♯','B♯','C♯','D♯','E♯','F♯♯']; //special case
	if (tonic=='B♯') return ['B♯','C♯♯','D♯♯','E♯','F♯♯','G♯♯','A♯♯']; //special case
	if (tonic=='b♯') return ['B♯','C♯♯','D♯','E♯','F♯♯','G♯','A♯']; //special case
	if (tonic=='A♯') return ['A♯','B♯','C♯♯','D♯♯','E♯','F♯♯','G♯♯']; //special case
	if (tonic=='E♯') return ['E♯','F♯♯','G♯♯','A♯','B♯','C♯♯','D♯♯']; //special case
	if (tonic=='e♯') return ['E♯','F♯♯','G♯','A♯','B♯','C♯','D♯']; //special case
	if (tonic=='D♯') return ['D♯','E♯','F♯♯','G♯','A♯','B♯','C♯♯']; //special case
	if (tonic=='g\u0231') return ['G\u0231','A\u0231','Bff','C\u0231','D\u0231','E\u0231','F'];
	if (tonic=='F\u0231') return ['F\u0231','G\u0231','A\u0231','Bff','C\u0231','D\u0231','E\u0231'];
	if (tonic=='f\u0231') return ['F\u0231','G\u0231','Aff','Bff','C\u0231','Dff','Eff'];
	if (tonic=='c\u0231') return ['C\u0231','D\u0231','Eff','F\u0231','G\u0231','Aff','Bff'];*/

    var scale = ['A', 'B', 'C', 'D', 'E', 'F', 'G'];
    var tnc = tonic;
    var shft = false;
    if (type.indexOf('minor') != -1) {
        tonic = notem(tonic.toUpperCase().replace('\u0230', '\u0231'));
        shft = true;
    }
    var shrp = ['C', 'G', 'D', 'A', 'E', 'B', 'F♯', 'C♯'];
    var flat = ['C', 'F', 'B\u0231', 'E\u0231', 'A\u0231', 'D\u0231', 'G\u0231', 'C\u0231'];

    var sloc = [0, 2, 18, 19, 27, 91, 95, 127];
    var floc = [0, 32, 36, 100, 108, 109, 125, 127];

    var pos;

    switch (tonic[tonic.length - 1]) {
        case '♯':
            pos = dec2Bin(sloc[shrp.indexOf(tonic)]);
            var t = pos.length;
            for (var i = 0; i < 7 - t; i++) pos = '0' + pos;
            for (var i = 0; i < pos.length; i++) {
                if (pos[i] == 1) scale[i] = scale[i] + '♯';
            }
            break;
        case '\u0231':
            pos = dec2Bin(floc[flat.indexOf(tonic)]);
            var t = pos.length;
            for (var i = 0; i < 7 - t; i++) pos = '0' + pos;
            for (var i = 0; i < pos.length; i++) {
                if (pos[i] == 1) scale[i] = scale[i] + '\u0231';
            }
            break;
        case 'F':
            pos = dec2Bin(floc[flat.indexOf(tonic)]);
            var t = pos.length;
            for (var i = 0; i < 7 - t; i++) pos = '0' + pos;
            for (var i = 0; i < pos.length; i++) {
                if (pos[i] == 1) scale[i] = scale[i] + '\u0231';
            }
            break;
        default:
            pos = dec2Bin(sloc[shrp.indexOf(tonic)]);
            var t = pos.length;
            for (var i = 0; i < 7 - t; i++) pos = '0' + pos;
            for (var i = 0; i < pos.length; i++) {
                if (pos[i] == 1) scale[i] = scale[i] + '♯';
            }
            break;
    }
    scale = scale.slice(scale.indexOf(tonic)).concat(scale.slice(0, scale.indexOf(tonic)));
    if (shft) {
        //minor
        var sls = scale.slice(0, scale.length - 2);
        var sls2 = scale.slice(scale.length - 2);
        scale = sls2.concat(sls);
    }
    if (type == 'melodic minor' && direction == 'Both') {
        var bscale = copyAry(scale);
        bscale[5] = noteM(notem(noteM(notem(noteM(notem(noteM(bscale[5]))))))); //sharp 6
        bscale[6] = noteM(notem(noteM(notem(noteM(notem(noteM(bscale[6]))))))); //sharp 7
        bscale.push(bscale[0]);
        for (var i = 6; i > 0; i--) bscale.push(scale[i]);
        scale = bscale;
        scale.push(scale[0]);
        return scale;
    }
    if (type == 'melodic minor' && direction == 'Up') {
        scale[5] = noteM(notem(noteM(notem(noteM(notem(noteM(scale[5]))))))); //sharp 6
        scale[6] = noteM(notem(noteM(notem(noteM(notem(noteM(scale[6]))))))); //sharp 7
    }
    if (type == 'harmonic minor') {
        scale[6] = notea(notem(scale[6])); //sharp 7
    }

    if (direction == 'Both') {
        var bscale = copyAry(scale);
        bscale.push(bscale[0]);
        for (var i = 6; i > 0; i--) bscale.push(scale[i]);
        scale = bscale;
    }

    scale.push(scale[0]);
    return scale;
}

export function dec2Bin(dec) {
    if (dec >= 0) {
        return dec.toString(2);
    } else {
        return (~dec).toString(2);
    }
}

//music utilities
export function derpBeat(type,tsig) {
	var staff = {};
	staff.params = {};
	staff.params.tsig = tsig;
	return rBeat(staff,type);
}

export function rBeat(staff,type) {
	var tsig = staff.params.tsig;
	var fac = 1;
	if (tsig.indexOf('/8')!=-1)
		fac = 2;
	if (tsig.indexOf('/2')!=-1)
		fac = 0.5;
	if (tsig.indexOf('/16')!=-1)
		fac = 4;
	type = type.replace('r','');
	switch(type) {
		case '64':
			return fac*0.0625;
			break;
		case '32':
			return fac*0.125;
			break;
		case '16':
			return fac*0.25;
			break;
		case 's16':
			return fac*0.166;
			break;
		case 't16':
			return fac*0.166;
			break;
		case '16.':
			return fac*0.375;
			break;
		case '8':
			return fac*0.5;
			break;
		case '8.':
			return fac*0.75;
			break;
		case 't8':
			return fac*0.333;
			break;
		case 't4':
			return fac*0.666;
			break;
		case 'd4':
			return fac*1.5;
			break;
		case 'd8':
			return fac*0.75;
			break;
		case 'f8':
			return fac*0.375;
			break;
		case '4':
			return fac*1;
			break;
		case '2':
			return fac*2;
			break;
		case '4.':
			return fac*1.5;
			break;
		case '2.':
			return fac*3;
			break;
		case '1':
			return fac*4;
			break;
		case '1.':
			return fac*6;
			break;
	}
}

export function getAxe(note) {
    var reps = [1,2,3,4,5,6,7,8,9,'A','B','C','D','E','F','G'];
    for (var i=0; i<reps.length; i++) {
        while(note.indexOf(reps[i])!=-1) note = note.replace(reps[i],'');
    }
    return note;
}

export function noNum(nt) {
    return nt
        .replace('0', '')
        .replace('1', '')
        .replace('2', '')
        .replace('3', '')
        .replace('4', '')
        .replace('5', '')
        .replace('6', '')
        .replace('7', '')
        .replace('8', '');
}

export function cleanNote(note) {
	if (!note) return;
	if (note.stem)
		note.stem.remove();
	if (note.beam)
		note.beam.remove();
	if (note['16-16']) {
		note['16-16'] = null;
	}
	if (note['8-16']) {
		note['8-16'].remove();
		note['8-16'] = null;
	}
	if (note['8.-16']) {
		note['8.-16'].remove();
		note['8.-16'] = null;
	}
	if (note.beams) {
		for (var i=0; i<note.beams.length; i++)
			note.beams[i].remove();
		note.beams = [];
	}
	if (note.beam2)
		note.beam2.remove();
	if (note.beam3)
		note.beam3.remove();
	if (note.flag)
		note.flag.remove();
	if (note.t3)
		note.t3.remove();
}

export function retNum(nt) {
    var reps = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'x', '♯', '\u0231','n'];
    for (var i = 0; i < reps.length; i++) while (nt.indexOf(reps[i]) != -1) nt = nt.replace(reps[i], '');
    return nt;
}

export function retBase(nt) {
    var reps = ['♯', 'bb', '\u0231', 'x', 'n', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
    for (var i = 0; i < reps.length; i++) while (nt.indexOf(reps[i]) != -1) nt = nt.replace(reps[i], '');
    return nt;
}

export function octaveToI(clef, note) {
    var n = note[0]; //note name
    var o = note[note.length - 1]; //octave number
    var hnotes1 = [
        'E',
        'D',
        'C',
        'B',
        'A',
        'G',
        'F',
        'E',
        'D',
        'C',
        'B',
        'A',
        'G',
        'F',
        'E',
        'D',
        'C',
        'B',
        'A',
        'G',
        'F',
    ];
    var hnotes2 = [
        'G',
        'F',
        'E',
        'D',
        'C',
        'B',
        'A',
        'G',
        'F',
        'E',
        'D',
        'C',
        'B',
        'A',
        'G',
        'F',
        'E',
        'D',
        'C',
        'B',
        'A',
    ];
    var hnotes3 = [
        'F',
        'E',
        'D',
        'C',
        'B',
        'A',
        'G',
        'F',
        'E',
        'D',
        'C',
        'B',
        'A',
        'G',
        'F',
        'E',
        'D',
        'C',
        'B',
        'A',
        'G',
    ];
    var ocs1 = [6, 6, 6, 5, 5, 5, 5, 5, 5, 5, 4, 4, 4, 4, 4, 4, 4, 3, 3, 3, 3];
    var ocs2 = [4, 4, 4, 4, 4, 3, 3, 3, 3, 3, 3, 3, 2, 2, 2, 2, 2, 2, 2, 1, 1];
    var ocs3 = [4, 4, 4, 4, 3, 3, 3, 3, 3, 3, 3, 2, 2, 2, 2, 2, 2, 2, 1, 1, 1];

    let hnotes;
    let ocs;
    switch (clef) {
        case 'Treble':
            hnotes = hnotes1;
            ocs = ocs1;
            break;
        case 'Bass':
            hnotes = hnotes2;
            ocs = ocs2;
            break;
        case 'Alto':
            hnotes = hnotes3;
            ocs = ocs3;
            break;
        default:
            throw new Error('Invalid clef');
    }

    for (var i = 0; i < hnotes.length; i++) {
        if (n == hnotes[i] && o == ocs[i]) return i - 6;
    }
}

export function noteM(note) {
    var majorThird;
    switch (note) {
        case 'C':
            majorThird = 'E';
            break;
        case 'C\u0231':
            majorThird = 'E\u0231';
            break;
        case 'C♯':
            majorThird = 'E♯';
            break;
        case 'Cff':
            majorThird = 'Eff';
            break;
        case 'Cx':
            majorThird = 'Ex';
            break;
        case 'D':
            majorThird = 'F♯';
            break;
        case 'D\u0231':
            majorThird = 'F';
            break;
        case 'D♯':
            majorThird = 'Fx';
            break;
        case 'Dff':
            majorThird = 'F\u0231';
            break;
        case 'E':
            majorThird = 'G♯';
            break;
        case 'E\u0231':
            majorThird = 'G';
            break;
        case 'Eff':
            majorThird = 'G\u0231';
            break;
        case 'E♯':
            majorThird = 'Gx';
            break;
        case 'F':
            majorThird = 'A';
            break;
        case 'F\u0231':
            majorThird = 'A\u0231';
            break;
        case 'Fff':
            majorThird = 'Aff';
            break;
        case 'Fx':
            majorThird = 'Ax';
            break;
        case 'F♯':
            majorThird = 'A♯';
            break;
        case 'G':
            majorThird = 'B';
            break;
        case 'G\u0231':
            majorThird = 'B\u0231';
            break;
        case 'G♯':
            majorThird = 'B♯';
            break;
        case 'Gx':
            majorThird = 'Bx';
            break;
        case 'Gff':
            majorThird = 'Bff';
            break;
        case 'A':
            majorThird = 'C♯';
            break;
        case 'A♯':
            majorThird = 'Cx';
            break;
        case 'A\u0231':
            majorThird = 'C';
            break;
        case 'Aff':
            majorThird = 'C\u0231';
            break;
        case 'B':
            majorThird = 'D♯';
            break;
        case 'B♯':
            majorThird = 'Dx';
            break;
        case 'B\u0231':
            majorThird = 'D';
            break;
        case 'Bff':
            majorThird = 'D\u0231';
            break;
    }
    return majorThird;
}

export function notem(note) {
    var minorThird;
    switch (note) {
        case 'C':
            minorThird = 'E\u0231';
            break;
        case 'C\u0231':
            minorThird = 'Eff';
            break;
        case 'C♯':
            minorThird = 'E';
            break;
        case 'C#':
            minorThird = 'E';
            break;
        case 'Cx':
            minorThird = 'E♯';
            break;
        case 'D':
            minorThird = 'F';
            break;
        case 'D\u0231':
            minorThird = 'F\u0231';
            break;
        case 'D♯':
            minorThird = 'F♯';
            break;
        case 'Dff':
            minorThird = 'Fff';
            break;
        case 'E':
            minorThird = 'G';
            break;
        case 'E\u0231':
            minorThird = 'G\u0231';
            break;
        case 'Eff':
            minorThird = 'Gff';
            break;
        case 'E♯':
            minorThird = 'G♯';
            break;
        case 'F':
            minorThird = 'A\u0231';
            break;
        case 'F\u0231':
            minorThird = 'Aff';
            break;
        case 'Fx':
            minorThird = 'A♯';
            break;
        case 'F♯':
            minorThird = 'A';
            break;
        case 'F#':
            minorThird = 'A';
            break;
        case 'G':
            minorThird = 'B\u0231';
            break;
        case 'G\u0231':
            minorThird = 'Bff';
            break;
        case 'G♯':
            minorThird = 'B';
            break;
        case 'Gx':
            minorThird = 'B♯';
            break;
        case 'Gff':
            minorThird = 'A\u0231';
            break;
        case 'A':
            minorThird = 'C';
            break;
        case 'A♯':
            minorThird = 'C♯';
            break;
        case 'A\u0231':
            minorThird = 'C\u0231';
            break;
        case 'Aff':
            minorThird = 'Cff';
            break;
        case 'Ax':
            minorThird = 'Cx';
            break;
        case 'B':
            minorThird = 'D';
            break;
        case 'B♯':
            minorThird = 'D♯';
            break;
        case 'B\u0231':
            minorThird = 'D\u0231';
            break;
        case 'Bff':
            minorThird = 'Dff';
            break;
    }
    return minorThird;
}

export function notea(note) {
    var aug6;
    switch (note) {
        case 'C':
            aug6 = 'A♯';
            break;
        case 'C\u0231':
            aug6 = 'A';
            break;
        case 'D':
            aug6 = 'B♯';
            break;
        case 'D\u0231':
            aug6 = 'B';
            break;
        case 'E\u0231':
            aug6 = 'C♯';
            break;
        case 'E':
            aug6 = 'Cx';
            break;
        case 'Eff':
            aug6 = 'C';
            break;
        case 'F':
            aug6 = 'D♯';
            break;
        case 'F\u0231':
            aug6 = 'D';
            break;
        case 'G\u0231':
            aug6 = 'E';
            break;
        case 'G':
            aug6 = 'E♯';
            break;
        case 'Aff':
            aug6 = 'F';
            break;
        case 'A\u0231':
            aug6 = 'F♯';
            break;
        case 'A':
            aug6 = 'Fx';
            break;
        case 'B\u0231':
            aug6 = 'G♯';
            break;
        case 'B':
            aug6 = 'Gx';
            break;
    }
    return aug6;
}

export function objCompare(first,second) {
	for (var prop in first) {
		var val = JSON.stringify(first[prop]);
		if ((second[prop]=="null" || second[prop]==null || second[prop]==undefined) && (first[prop]!="null" && first[prop]!=null && first[prop]!=undefined)) return false;
		var val2 = JSON.stringify(second[prop]);
		if (val!=val2) return false;
	}
	return true;
}

var TIETIME;
export function handleTie(staff,MB) {
	var resetDB = function() {
		if (staff.DB)
			staff.DB.tied = false;
	}
	var dir = staff.measures[MB.measure].obs[MB.iBeat].i>9?-1:1;
	var m = MB.measure;
	var b = MB.iBeat;
	var n1 = staff.measures[m].obs[b];
	var n2 = staff.measures[m].obs[b+1];
	if (!n2) {
		m++;
		if (staff.measures[m]) {
			n2 = staff.measures[m].obs[0];
		}
	}
	if (!n1.note) {
		resetDB();
		return;
	}
	if (!n2.note) {
		resetDB();
		return;
	}
	if (n1.tieF) {
		resetDB();
		return;
	}
	if (n2.tieB) {
		resetDB();
		return;
	}
	if (n1.name!=n2.name) {alert('Notes cannot be tied!'); resetDB(); return;}
	var tie = drawTie(staff.whole,n1,n2,dir);
	
	n1.tieF = tie;
	n2.tieB = tie;
	
	tie.n1 = n1;
	tie.n2 = n2;

	n1.tied = true;

	//update aria-label
	/*if (staff.type=='Rhythm') {
		n1.note.node.ariaLabel = nType(n1.dur)+" note, measure "+(n1.measure+1)+", beat "+(n1.beat)+", tied to following note";
		n2.note.node.ariaLabel = nType(n2.dur)+" note, measure "+(n2.measure+1)+", beat "+(n2.beat)+", tied from previous note";
	} else {
		n1.note.node.ariaLabel = nType(n1.dur)+" note, "+accessibleName(n1.name)+", measure "+(n1.measure+1)+", beat "+(n1.beat)+", tied to following note";
		n2.note.node.ariaLabel = nType(n2.dur)+" note, "+accessibleName(n2.name)+", measure "+(n2.measure+1)+", beat "+(n2.beat)+", tied from previous note";
	}*/
	
	if (staff.DB)
		staff.DB.tied = false;
}

function dnTan(dxdy,theta) {
	return dxdy*Math.tan(theta);
}

function beamPair(staff,n1,n2,theta,flip) {
	var S = staff.paper;
	var dur1 = n1.dur;
	var dur2 = n2.dur;
	
	if (!n1.beams) n1.beams = [];
	if (!n2.beams) n2.beams = [];
	
	var type = dur1+'-'+dur2;
	var bth = 10; //beam thickness
	var bsp = 5; //space between beams
	var stemW = 2;
	var yBuff = 0.54;
	var xBuff = 0.2;
	
	if (n1.by>n2.by) theta = -theta;
	
	if (!flip) {
		//first beam
		var x1 = n1.x + n1.width - stemW - xBuff;
		var x2 = n2.x + n2.width - xBuff;
		var y1 = n1.stem.getBBox().y - yBuff;
		var y2 = n2.stem.getBBox().y - yBuff;
		var beam = S.polyline(x1,y1, x2,y2, x2,y2+bth, x1,y1+bth, x1,y1);
		staff.whole.prepend(beam);
		n1.beams.push(beam); //keep on first note
		n2.beams.push(beam); //keep on second note
		
		switch(type) {
			case '16-8':
				if (!n1['16-16']&&!n1['8-16']&&!n1['8.-16']) {
					x2 = x1 + n1.width - xBuff;
					y1 = y1 + bth + bsp;
					y2 = y1 + dnTan(n1.width,theta);
					beam = S.polyline(x1,y1, x2,y2, x2,y2+bth, x1,y1+bth, x1,y1);
					staff.whole.prepend(beam);
					n1.beams.push(beam);
					n2.beams.push(beam);
				}
				break;
			case '16-8.':
				if (!n1['16-16']&&!n1['8-16']&&!n1['8.-16']) {
					x2 = x1 + n1.width - xBuff;
					y1 = y1 + bth + bsp;
					y2 = y1 + dnTan(n1.width,theta);
					beam = S.polyline(x1,y1, x2,y2, x2,y2+bth, x1,y1+bth, x1,y1);
					staff.whole.prepend(beam);
					n1.beams.push(beam);
					n2.beams.push(beam);
				}
				break;
			case '8-16':
				//16th part
				x1 = n2.x;
				y2 = y2 + bth + bsp;
				y1 = y2 + dnTan(x1-x2,theta);
				beam = S.polyline(x1,y1, x2,y2, x2,y2+bth, x1,y1+bth, x1,y1);
				staff.whole.prepend(beam);
				n1.beams.push(beam);
				n2.beams.push(beam);
				n2['8-16'] = beam;
				break;
			case '8.-16':
				//16th part
				x1 = n2.x;
				y2 = y2 + bth + bsp;
				y1 = y2 + dnTan(x1-x2,theta);
				beam = S.polyline(x1,y1, x2,y2, x2,y2+bth, x1,y1+bth, x1,y1);
				staff.whole.prepend(beam);
				n1.beams.push(beam);
				n2.beams.push(beam);
				n2['8.-16'] = beam;
				break;
			case '16-16':
				if (n1['8-16']) {n1['8-16'].remove(); n1['8-16'] = null;}
				if (n1['8.-16']) {n1['8.-16'].remove(); n1['8.-16'] = null;}
				y1 += (bth+bsp);
				y2 += (bth+bsp);
				var beam = S.polyline(x1,y1, x2,y2, x2,y2+bth, x1,y1+bth, x1,y1);
				staff.whole.prepend(beam);
				n1.beams.push(beam);
				n2.beams.push(beam);
				n2['16-16'] = true;
				break;
			case 's16-s16':
				y1 += (bth+bsp);
				y2 += (bth+bsp);
				var beam = S.polyline(x1,y1, x2,y2, x2,y2+bth, x1,y1+bth, x1,y1);
				staff.whole.prepend(beam);
				n1.beams.push(beam);
				n2.beams.push(beam);
				break;
		}
	} else {
		//first beam
		var x1 = n1.x;
		var x2 = n2.x;
		var y1 = n1.by + n1.height/2 + n1.stemH;
		var y2 = n2.by + n2.height/2 + n2.stemH;
		var beam = S.polyline(x1,y1, x2,y2, x2,y2-bth, x1,y1-bth, x1,y1);
		staff.whole.prepend(beam);
		n1.beams.push(beam); //keep on first note
		n2.beams.push(beam); //keep on second note
		
		switch(type) {
			case '16-8':
				if (!n1['16-16']&&!n1['8-16']&&!n1['8.-16']) {
					x2 = x1 + n1.width - xBuff;
					y1 = y1 - bth - bsp;
					y2 = y1 + dnTan(n1.width,theta);
					beam = S.polyline(x1,y1, x2,y2, x2,y2-bth, x1,y1-bth, x1,y1);
					staff.whole.prepend(beam);
					n1.beams.push(beam);
					n2.beams.push(beam);
				}
				break;
			case '16-8.':
				if (!n1['16-16']&&!n1['8-16']&&!n1['8.-16']) {
					x2 = x1 + n1.width - xBuff;
					y1 = y1 - bth - bsp;
					y2 = y1 + dnTan(n1.width,theta);
					beam = S.polyline(x1,y1, x2,y2, x2,y2-bth, x1,y1-bth, x1,y1);
					staff.whole.prepend(beam);
					n1.beams.push(beam);
					n2.beams.push(beam);
				}
				break;
			case '8-16':
				//16th part
				x1 = n2.x - n2.width;
				y2 = y2 - bth - bsp;
				y1 = y2 + dnTan(x1-x2,theta);
				beam = S.polyline(x1,y1, x2,y2, x2,y2-bth, x1,y1-bth, x1,y1);
				staff.whole.prepend(beam);
				n1.beams.push(beam);
				n2.beams.push(beam);
				n2['8-16'] = beam;
				break;
			case '8.-16':
				//16th part
				x1 = n2.x - n2.width;
				y2 = y2 - bth - bsp;
				y1 = y2 + dnTan(x1-x2,theta);
				beam = S.polyline(x1,y1, x2,y2, x2,y2-bth, x1,y1-bth, x1,y1);
				staff.whole.prepend(beam);
				n1.beams.push(beam);
				n2.beams.push(beam);
				n2['8-16'] = beam;
				break;
			case '16-16':
				if (n1['8-16']) {n1['8-16'].remove(); n1['8-16'] = null;}
				if (n1['8.-16']) {n1['8.-16'].remove(); n1['8.-16'] = null;}
				y1 -= (bth+bsp);
				y2 -= (bth+bsp);
				var beam = S.polyline(x1,y1, x2,y2, x2,y2-bth, x1,y1-bth, x1,y1);
				staff.whole.prepend(beam);
				n1.beams.push(beam);
				n2.beams.push(beam);
				n2['16-16'] = true;
				break;
			case 's16-s16':
				y1 -= (bth+bsp);
				y2 -= (bth+bsp);
				var beam = S.polyline(x1,y1, x2,y2, x2,y2-bth, x1,y1-bth, x1,y1);
				staff.whole.prepend(beam);
				n1.beams.push(beam);
				n2.beams.push(beam);
				break;
		}
	}
}

export function paletteRhythm(staff,type,x,y) {
	var S = staff.paper;
	var svg=S.group();
	var note1, note2;
	var sdx = 2.2;
	var sdy = 3.2;
	var stemW = 2;
	var stemH = 50;
	if (!y)
		var y=215;
	//type = '3t';
	switch(type) {
		case '1s':
			note1 = S.path(closedPath).transform('t'+(x)+' '+y+' s0.07 -0.07');
			svg.append(note1);
			
			var flag = S.path(sixFlagPath).transform('t'+(x+85.5)+' '+(y+413)+' s0.07 -0.07')
			svg.append(flag);
			
			var bb = note1.getBBox();
			var stem = S.rect(bb.x+bb.width-sdx,bb.y-stemH+sdy,stemW,stemH);
			svg.append(stem);
			break;
		case '1e':
			var flag = S.path(eighthFlagPath).transform('t'+(x+53.5)+' '+(y-381)+' s0.07 0.07');
			svg.append(flag);
			var note = S.path(closedPath).transform('t'+(x-4)+' '+(y)+' s0.07 -0.07');
			svg.append(note);
			var bb = note.getBBox();
			var stem = S.rect(bb.x+bb.width-sdx,bb.y-stemH+sdy,stemW,stemH);
			svg.append(stem);
			//qsvg=S.group(note,flag,stem);
			//dis.whole.append(qsvg);
			break;
		case '1q':
			note1 = S.path(closedPath).transform('t'+(x)+' '+y+' s0.07 -0.07');
			svg.append(note1);
			var bb = note1.getBBox();
			var stem = S.rect(bb.x+bb.width-sdx,bb.y-stemH+sdy,stemW,stemH);
			svg.append(stem);
			break;
		case '1h':
			note1 = S.path(openPath).transform('t'+(x-9)+' '+y+' s0.07 -0.07');
			svg.append(note1);
			var bb = note1.getBBox();
			var stem = S.rect(bb.x+bb.width-sdx,bb.y-stemH+sdy,stemW,stemH);
			svg.append(stem);
			break;
		case '1w':
			var note = S.path(wholePath).transform('t'+(x-81.5)+' '+(y-11)+' s0.068 -0.068');
			svg.append(note);
			break;
	}
	return svg;
}

export function drawStaffLines(staff,width,endType,connector) {
	if (staff.lines) {
		for (var i=0; i<staff.lines.length; i++)
			staff.lines[i].remove();
	}
	if (staff.endLineGroup) staff.endLineGroup.remove();
	staff.lines = [];
	staff.lnsp = 17.75;
	var r;
	for (var i=0; i<5; i++) {
		if (endType=='rhythm') {
			if (i!=2) continue;
		}
		r = staff.whole.rect(0,i*staff.lnsp,width,1);
		staff.lines.push(r);
		staff.whole.prepend(r);
	}
	//end lines
	var barlineGroup = staff.whole.group();
	if (!endType) {
		r = staff.whole.rect(width-15,0,3,4*staff.lnsp);
		staff.lines.push(r);
		barlineGroup.append(r);
		
		r = staff.whole.rect(width-8,0,8,4*staff.lnsp);
		staff.lines.push(r);
		barlineGroup.append(r);

		staff.whole.prepend(barlineGroup);
	} else {
		switch(endType) {
			case 'bar':
				r = staff.whole.rect(width-1.2,0,1.2,4*staff.lnsp);
				barlineGroup.append(r);
				staff.lines.push(r);

				staff.whole.prepend(barlineGroup);

				if (connector) {
					staff.whole.prepend(staff.whole.rect(width-1.2,0,1.2,12*staff.lnsp))
				}
				break;
			case '|':
				r = staff.whole.rect(width-1.2,0,1.2,4*staff.lnsp);
				barlineGroup.append(r);
				staff.lines.push(r);

				staff.whole.prepend(barlineGroup);

				if (connector) {
					staff.whole.prepend(staff.whole.rect(width-1.2,0,1.2,12*staff.lnsp))
				}
				break;
			case '- - -':
				r = staff.whole.line(width-1.2,0,width-1.2,4*staff.lnsp).attr({stroke:'black',strokeWidth:1,strokeDasharray:'4',opacity:0.2});
				barlineGroup.append(r);
				staff.lines.push(r);

				staff.whole.prepend(barlineGroup);

				if (connector) {
					staff.whole.prepend(staff.whole.line(width-1.2,0,width-1.2,12*staff.lnsp).attr({stroke:'black',strokeWidth:1,strokeDasharray:'4',opacity:0.2}))
				}
				break;
			case 'double-thin':
				r = staff.whole.rect(width-7.2,0,1.2,4*staff.lnsp);
				barlineGroup.append(r);
				staff.lines.push(r);
				
				r = staff.whole.rect(width-1.2,0,1.2,4*staff.lnsp);
				barlineGroup.append(r);
				staff.lines.push(r);

				staff.whole.prepend(barlineGroup);
				break;
			case '||':
				r = staff.whole.rect(width-7.2,0,1.2,4*staff.lnsp);
				barlineGroup.append(r);
				staff.lines.push(r);
				
				r = staff.whole.rect(width-1.2,0,1.2,4*staff.lnsp);
				barlineGroup.append(r);
				staff.lines.push(r);

				staff.whole.prepend(barlineGroup);
				break;
			case 'grand':
				r = staff.whole.rect(width-15,0,3,8*staff.lnsp+staff.staffSpace);
				staff.lines.push(r);
				barlineGroup.append(r);
				
				r = staff.whole.rect(width-8,0,8,8*staff.lnsp+staff.staffSpace);
				staff.lines.push(r);
				barlineGroup.append(r);
				
				staff.whole.prepend(barlineGroup);
			case 'none':
				break;
			case 'rhythm':
				var lnsp = 17.75;
				var l1 = staff.whole.rect(width-15,lnsp,3,2*lnsp);
				var l2 = staff.whole.rect(width-8,lnsp,8,2*lnsp);
				barlineGroup.append(l1);
				barlineGroup.append(l2);
				staff.whole.prepend(barlineGroup);
				staff.lines.push(l1);
				staff.lines.push(l2);
				break;
		}
	}

	/*if (barlineGroup) {
		staff.endLineGroup = barlineGroup;
		barlineGroup.node.tabIndex = "0";
		barlineGroup.node.ariaLabel = "Bar line, end of piece";
	}*/
}

export function drawStems(staff,notes,type,dir) {
	//closest note to middle line gets normal stem (maybe limit in extreme cases)
	//max angle
	var rad = (2*3.1415)/360;
	var maxTheta = 15;
	maxTheta*=rad;
	var sdx = 2.2;
	var sdy = 3.2;
	var stemW = 2;
	var stemH = 45;
	var bth = 10;
	
	//paper
	var S = staff.paper;
	
	//save stem buddies
	for (var i=0; i<notes.length; i++) {
		if (!notes[i]) continue; //these are rests
		notes[i].stemBuddies = [];
		for (var j=0; j<notes.length; j++) {
			if (i!=j) notes[i].stemBuddies.push(notes[j]);
		}
	}
	
	//just get the notes
	var tmp = copyAry(notes);
	notes = [];
	for (var i=0; i<tmp.length; i++) {
		if (tmp[i]) notes.push(tmp[i]);
	}
	
	var copyObject = function(obj) {
		var nbj = {};
		for (var prop in obj) {
			nbj[prop] = obj[prop];
		}
		return nbj;
	}
	
	switch(dir) {
		case 'up':
			var min = 1000000;
			var minNote;
			for (var i=0; i<notes.length; i++) {
				//boxNote(notes[i]);
				if (notes[i].by<min) {
					min = notes[i].by; 
					minNote = i;
				}
			}
			
			//attempt angle change
			var theta = 0; //start with flat beam
			var incr = 1;
			var stop = false;
			if (minNote==0) {
				while(!stop) {
					theta+=incr*rad; //increment and convert to radians
					if (theta>=maxTheta) {break;}
					//check stem height
					for (var i=0; i<notes.length; i++) {
						if ( (notes[i].by) < (min+dnTan(notes[i].x-notes[minNote].x,theta))) {
							stop = true;
							theta-=incr*rad;
							break;
						}
					}
				}
			}
			
			if (minNote==(notes.length-1)) {
				while(!stop) {
					theta+=incr*rad; //increment and convert to radians
					if (theta>=maxTheta) {break;}
					//check stem height
					for (var i=0; i<notes.length; i++) {
						if ( (notes[i].by) < (min-dnTan(notes[i].x-notes[minNote].x,theta))) {
							stop = true;
							theta-=incr*rad;
							break;
						}
					}
				}
			}
			
			//minNote has full stemH
			notes[minNote].stemH = 3.5*17.75;
			var sign = -1;
			if (minNote==0) sign = 1;
			for (var i=0; i<notes.length; i++) {
				if (i==minNote) continue;
				notes[i].stemH = (notes[i].by + notes[i].height/2) - (notes[minNote].by + notes[minNote].height/2 - notes[minNote].stemH + sign*dnTan(notes[i].x - notes[minNote].x, theta));
			}
			
			//change stems
			for (var i=0; i<notes.length; i++) {
				var stem = S.rect(notes[i].x+notes[i].width-sdx,notes[i].by+notes[i].height/2-notes[i].stemH,stemW,notes[i].stemH);
				notes[i].stem = stem;
				staff.whole.prepend(stem);
			}
			
			//beam in pairs
			for (var i=0; i<notes.length-1; i++) {
				beamPair(staff,notes[i],notes[i+1],theta);
			}
			break;
		case 'down':
			var max = -1000000;
			var maxNote;
			for (var i=0; i<notes.length; i++) {
				//boxNote(notes[i]);
				if (notes[i].by>max) {
					max = notes[i].by; 
					maxNote = i;
				}
			}
			
			//attempt angle change
			var theta = 0; //start with flat beam
			var incr = 1;
			var stop = false;
			if (maxNote==(notes.length-1)) {
				while(!stop) {
					theta+=incr*rad; //increment and convert to radians
					if (theta>=maxTheta) {break;}
					//check stem height
					for (var i=0; i<notes.length; i++) {
						if ( (notes[i].by) > (max+dnTan(notes[i].x-notes[maxNote].x,theta))) {
							stop = true;
							theta-=incr*rad;
							break;
						}
					}
				}
			}
			
			if (maxNote==0) {
				while(!stop) {
					theta+=incr*rad; //increment and convert to radians
					if (theta>=maxTheta) {break;}
					//check stem height
					for (var i=0; i<notes.length; i++) {
						if ( (notes[i].by) > (max-dnTan(notes[i].x-notes[maxNote].x,theta))) {
							stop = true;
							theta-=incr*rad;
							break;
						}
					}
				}
			}
			
			//minNote has full stemH
			notes[maxNote].stemH = 3.5*17.75;
			var sign = 1;
			if (maxNote==0) sign = -1;
			for (var i=0; i<notes.length; i++) {
				if (i==maxNote) continue;
				notes[i].stemH = (notes[maxNote].by + notes[maxNote].height/2 + notes[maxNote].stemH + sign*dnTan(notes[i].x - notes[maxNote].x, theta)) - (notes[i].by + notes[i].height/2);
			}
			
			//change stems
			for (var i=0; i<notes.length; i++) {
				var stem = S.rect(notes[i].x,notes[i].by+notes[i].height/2,stemW,notes[i].stemH);
				notes[i].stem = stem;
				staff.whole.prepend(stem);
			}
			
			//beam in pairs
			for (var i=0; i<notes.length-1; i++) {
				beamPair(staff,notes[i],notes[i+1],theta,true);
			}
			break;
	}
	
	//draw numbers on tuplets
	var durs = '';
	for (var i=0; i<notes.length; i++) {
		durs+=notes[i].dur;
	}
	switch(durs) {
		case 'f8f8f8f8':
			var mn = notes[1];
			var mn2 = notes[2];
			var x = (mn.x+mn2.x)/2+mn.width-5;
			var y = (mn.by-mn.stemH)/2+(mn2.by-mn2.stemH)/2+5;
			if (dir=='up') {

			} else {
				x = (mn.x+mn2.x)/2;
				y = (mn.by+mn.stemH)/2+(mn2.by+mn2.stemH)/2+25;
			}
			mn.t3 = staff.whole.text(x,y,'4').attr({fontStyle:'italic'});
			staff.whole.prepend(mn.t3);
			break;
		case 'f8f8f8': //three
			var mn = notes[1];
			var x = mn.x;
			var y = mn.by;
			if (dir=='up') {y-=mn.stemH-5; x+=mn.width/2+5;}
			else {y+=mn.stemH+25;}
			mn.t3 = staff.whole.text(x,y,'4').attr({fontStyle:'italic'});
			staff.whole.prepend(mn.t3);
			break;
		case 'f8f8': //two
			var mn = notes[0];
			var mn2 = notes[1];
			var x = (mn.x+mn2.x)/2+mn.width-5;
			var y = (mn.by-mn.stemH)/2+(mn2.by-mn2.stemH)/2+5;
			if (dir=='up') {

			} else {
				x = (mn.x+mn2.x)/2;
				y = (mn.by+mn.stemH)/2+(mn2.by+mn2.stemH)/2+25;
			}
			mn.t3 = staff.whole.text(x,y,'4').attr({fontStyle:'italic'});
			staff.whole.prepend(mn.t3);
			break;
		case 't8t8t8': //all three
			var mn = notes[1];
			var x = mn.x;
			var y = mn.by;
			if (dir=='up') {y-=mn.stemH-5; x+=mn.width/2+5;}
			else {y+=mn.stemH+25;}
			mn.t3 = staff.whole.text(x,y,'3').attr({fontStyle:'italic'});
			staff.whole.prepend(mn.t3);
			break;
		case 't8t8': //two
			var mn = notes[0];
			var mn2 = notes[1];
			var x = (mn.x+mn2.x)/2+mn.width-5;
			var y = (mn.by-mn.stemH)/2+(mn2.by-mn2.stemH)/2+5;
			if (dir=='up') {

			} else {
				x = (mn.x+mn2.x)/2;
				y = (mn.by+mn.stemH)/2+(mn2.by+mn2.stemH)/2+25;
			}
			mn.t3 = staff.whole.text(x,y,'3').attr({fontStyle:'italic'});
			staff.whole.prepend(mn.t3);
			break;
		case 'd8d8':
			var mn = notes[0];
			var mn2 = notes[1];
			var x = (mn.x+mn2.x)/2+mn.width-5;
			var y = (mn.by-mn.stemH)/2+(mn2.by-mn2.stemH)/2+5;
			if (dir=='up') {

			} else {
				x = (mn.x+mn2.x)/2;
				y = (mn.by+mn.stemH)/2+(mn2.by+mn2.stemH)/2+25;
			}
			mn.t3 = staff.whole.text(x,y,'2').attr({fontStyle:'italic'});
			staff.whole.prepend(mn.t3);
			break;
		case 's16s16':
			var mn = notes[0];
			var mn2 = notes[1];
			var x = (mn.x+mn2.x)/2+mn.width-5;
			var y = (mn.by-mn.stemH)/2+(mn2.by-mn2.stemH)/2+5;
			if (dir=='up') {

			} else {
				x = (mn.x+mn2.x)/2;
				y = (mn.by+mn.stemH)/2+(mn2.by+mn2.stemH)/2+25;
			}
			mn.t3 = staff.whole.text(x,y,'6').attr({fontStyle:'italic'});
			staff.whole.prepend(mn.t3);
			break;
		case 's16s16s16': //three sextuplets
			var mn = notes[1];
			var x = mn.x;
			var y = mn.by;
			if (dir=='up') {y-=mn.stemH-5; x+=mn.width/2+5;}
			else {y+=mn.stemH+25;}
			mn.t3 = staff.whole.text(x,y,'6').attr({fontStyle:'italic'});
			staff.whole.prepend(mn.t3);
			break;
		case 's16s16s16s16':
			var mn = notes[1];
			var mn2 = notes[2];
			var x = (mn.x+mn2.x)/2+mn.width-5;
			var y = (mn.by-mn.stemH)/2+(mn2.by-mn2.stemH)/2+5;
			if (dir=='up') {

			} else {
				x = (mn.x+mn2.x)/2;
				y = (mn.by+mn.stemH)/2+(mn2.by+mn2.stemH)/2+25;
			}
			mn.t3 = staff.whole.text(x,y,'6').attr({fontStyle:'italic'});
			staff.whole.prepend(mn.t3);
			break;
		case 's16s16s16s16s16': //three sextuplets
			var mn = notes[2];
			var x = mn.x;
			var y = mn.by;
			if (dir=='up') {y-=mn.stemH-5; x+=mn.width/2+5;}
			else {y+=mn.stemH+25;}
			mn.t3 = staff.whole.text(x,y,'6').attr({fontStyle:'italic'});
			staff.whole.prepend(mn.t3);
			break;
		case 's16s16s16s16s16s16':
			var mn = notes[2];
			var mn2 = notes[3];
			var x = (mn.x+mn2.x)/2+mn.width-5;
			var y = (mn.by-mn.stemH)/2+(mn2.by-mn2.stemH)/2+5;
			if (dir=='up') {

			} else {
				x = (mn.x+mn2.x)/2;
				y = (mn.by+mn.stemH)/2+(mn2.by+mn2.stemH)/2+25;
			}
			mn.t3 = staff.whole.text(x,y,'6').attr({fontStyle:'italic'});
			staff.whole.prepend(mn.t3);
			break;
	}
}

export function drawClef(staff,clef) {
	removeClef(staff);
	switch(clef) {
		case 'Treble':
			staff.staffClef = staff.whole.path(treblePath).attr({fill:'black'}).transform('t-290 -247 s0.07 -0.07');
			break;
		case 'Bass':
			staff.staffClef = staff.whole.path(bassPath).attr({fill:'black'}).transform('t-290 152.3 s0.074 -0.074');
			break;
		case 'Alto':
			staff.staffClef = staff.whole.path(altoPath).attr({fill:'black'}).transform('t-310 36 s0.071 -0.071');
			break;
		case 'Rhythm':
			var lnsp = 17.75;
			var l1 = staff.whole.rect(0,lnsp,8,2*lnsp);
			var l2 = staff.whole.rect(12,lnsp,8,2*lnsp);
			staff.staffClef = staff.whole.group(l1,l2);
			staff.staffClef.transform('t8 0');
			break;
		default:
			staff.staffClef = staff.whole.path(treblePath).attr({fill:'black'}).transform('t-290 -247 s0.07 -0.07');
			break;
	}
	staff.clef = clef;

	//accessibility
	//staff.staffClef.node.tabIndex = "0";
	//staff.staffClef.node.ariaLabel = "clef, "+clef;
}
export function removeClef(staff) {
	if (staff.staffClef) staff.staffClef.remove();
	staff.staffClef = null;
}

export function handleTwoVoiceTie(staff,MB) {
	var resetDB = function() {
		if (staff.DB)
			staff.DB.tied = false;
	}
	var dir = staff[MB.staff+'measures'][MB.measure].obs[MB.iBeat].i>9?-1:1;
	var m = MB.measure;
	var b = MB.iBeat;
	var n1 = staff[MB.staff+'measures'][m].obs[b];
	var n2 = staff[MB.staff+'measures'][m].obs[b+1];
	if (!n2) {
		m++;
		if (staff[MB.staff+'measures'][m]) {
			n2 = staff[MB.staff+'measures'][m].obs[0];
		}
	}
	if (!n1.note) {
		resetDB();
		return;
	}
	if (!n2.note) {
		resetDB();
		return;
	}
	if (n1.tieF) {
		resetDB();
		return;
	}
	if (n2.tieB) {
		resetDB();
		return;
	}
	if (n1.name!=n2.name) {alert('Notes cannot be tied!'); resetDB(); return;}
	var tie = drawTie(staff[MB.staff+'Staff'].whole,n1,n2,dir);
	
	n1.tieF = tie;
	n2.tieB = tie;
	
	tie.n1 = n1;
	tie.n2 = n2;

	n1.tied = true;

	var stext = MB.staff=='top' ? 'top staff':'bottom staff';
	//n1.note.node.ariaLabel = stext+', '+nType(n1.dur)+" note, "+accessibleName(n1.name)+", measure "+(n1.measure+1)+", beat "+(n1.beat)+", tied to following note";
	//n2.note.node.ariaLabel = stext+', '+nType(n2.dur)+" note, "+accessibleName(n2.name)+", measure "+(n2.measure+1)+", beat "+(n2.beat)+", tied from previous note";
	
	if (staff.DB)
		staff.DB.tied = false;
}

export function drawTimeSignature(staff,ts) {
	staff.timeSignature = timeSignature(staff,ts);
}

export function invert(ary,num) {
    for (var i=0; i<num; i++) {
        ary.push(ary[i]);
    }
    ary=ary.slice(num);
    return ary;
}

export function genTie(paper,x1,y1,x2,y2,dir) {
	var first_x_px;
	var last_x_px;
	var first_ys;
	var last_ys;
	var stem_direction;
	
	first_x_px = x1;
	stem_direction = dir;
	first_ys = y1;

	last_x_px = x2;
	stem_direction = dir;
	last_ys = y2;

	var path = renderTie({
	  first_x_px,
	  last_x_px,
	  first_ys,
	  last_ys,
	  direction: stem_direction,
	  cp1: 8,
	  cp2: 12
	});
	
	var tie = paper.path(path);
	tie.transform('t0 5');
	
	return tie;
}

export function drawRest(staff,S,mb,meas,topbot,rop) {
	var svg = S.group();
	var ele;
	var x = mb.x;
	var y;
	var dur = mb.dur.replace('r','');
	switch(dur) {
		case '4':
			y = 17.75*4;
			ele = S.path(quarterRestPath).transform('t'+(x-100)+' '+(y-75)+' s0.07 -0.07');
			svg.append(ele);
			break;
		case '4.':
			y = 17.75*4;
			ele = S.path(quarterRestPath).transform('t'+(x-100)+' '+(y-75)+' s0.07 -0.07');
			var dot = S.circle(x+28,y-44,3.5);
			svg.append(dot);
			svg.append(ele);
			break;
		case '8':
			y = 17.75*4;
			ele = S.path(eighthRestPath).transform('t'+(x-113)+' '+(y-9.75)+' s0.06 -0.06');
			svg.append(ele);
			break;
		case '8.':
			y = 17.75*4;
			ele = S.path(eighthRestPath).transform('t'+(x-113)+' '+(y-9.75)+' s0.06 -0.06');
			var dot = S.circle(x+28,y-44,3.5);
			svg.append(dot);
			svg.append(ele);
			break;
		case '16':
			y = 17.75*3;
			ele = S.path(sixteenthRestPath).transform('t'+(x-128)+' '+(y+126)+' s0.06 -0.06');
			svg.append(ele);
			break;
		case 's16':
			y = 17.75*3;
			ele = S.path(sixteenthRestPath).transform('t'+(x-128)+' '+(y+126)+' s0.06 -0.06');
			var t3 = S.text(x+10,y-56.5,'6').attr({fontStyle:'italic'});
			svg.append(t3);
			svg.append(ele);
			break;
		case 't8':
			y = 17.75*4;
			ele = S.path(eighthRestPath).transform('t'+(x-113)+' '+(y-9.75)+' s0.06 -0.06');
			var t3 = S.text(x+10,y-56.5,'3').attr({fontStyle:'italic'});
			svg.append(t3);
			svg.append(ele);
			break;
		case 'd8':
			y = 17.75*4;
			ele = S.path(eighthRestPath).transform('t'+(x-113)+' '+(y-9.75)+' s0.06 -0.06');
			var t3 = S.text(x+10,y-56.5,'2').attr({fontStyle:'italic'});
			svg.append(t3);
			svg.append(ele);
			break;
		case 'f8':
			y = 17.75*4;
			ele = S.path(eighthRestPath).transform('t'+(x-113)+' '+(y-9.75)+' s0.06 -0.06');
			var t3 = S.text(x+10,y-56.5,'4').attr({fontStyle:'italic'});
			svg.append(t3);
			svg.append(ele);
			break;
		case 't4':
			y = 17.75*4;
			ele = S.path(quarterRestPath).transform('t'+(x-100)+' '+(y-75)+' s0.07 -0.07');
			var t3 = S.text(x+10,y-56.5,'3').attr({fontStyle:'italic'});
			svg.append(t3);
			svg.append(ele);
			break;
		case 'd4':
			y = 17.75*4;
			ele = S.path(quarterRestPath).transform('t'+(x-100)+' '+(y-75)+' s0.07 -0.07');
			var t3 = S.text(x+10,y-56.5,'2').attr({fontStyle:'italic'});
			svg.append(t3);
			svg.append(ele);
			break;
		case '2':
			y = 17.75*2;
			ele = S.path(halfRestPath).transform('t'+(x-176)+' '+(y-83.5)+' s0.07 -0.07');
			svg.append(ele);
			break;
		case '2.':
			y = 17.75*2;
			ele = S.path(halfRestPath).transform('t'+(x-176)+' '+(y-83.5)+' s0.07 -0.07');
			var dot = S.circle(x+33,y-11,3.5);
			svg.append(dot);
			svg.append(ele);
			break;
		case '1':
			y = 17.75*2;
			if (staff.type!='Rhythmic Dictation') y-=17.75;
			ele = S.path(wholeRestPath).transform('t'+(x-177)+' '+(y+84)+' s0.07 -0.07');
			svg.append(ele);
			break;
		case '1.':
			y = 17.75*2;
			if (staff.type!='Rhythmic Dictation') y-=17.75;
			ele = S.path(wholeRestPath).transform('t'+(x-177)+' '+(y+84)+' s0.07 -0.07');
			var dot = S.circle(x+33,y-9,3.5);
			svg.append(dot);
			svg.append(ele);
			break;
	}
	mb.rest = svg;
	mb.type = 'rest';
	if (topbot)
		mb.staff = topbot;
	if (rop) svg.attr({opacity:rop});
	staff.whole.prepend(svg);

	svg.node.onfocus = function() {
		svg.attr({fill:'rgb(99, 21, 255)'});
	}
	svg.node.onblur = function() {
		svg.attr({fill:'black'});
	}

	svg.node.tabIndex = "0";
	if (topbot) {
		var stext = topbot=='top' ? 'top staff':'bottom staff';
		svg.node.tabIndex = "0";
		svg.node.ariaLabel = '';//stext+', '+nType(mb.dur)+" rest, measure "+(mb.measure+1)+", beat "+(mb.beat);
	} else {
		svg.node.ariaLabel = '';//nType(mb.dur)+" rest, measure "+(mb.measure+1)+", beat "+mb.beat;
	}
	
	return svg;
}

export function drawTie(paper,n1,n2,dir) {
	var x1 = n1.x + n1.width/2;
	
	var x2 = n2.x + n2.width/2;
	
	var first_x_px;
	var last_x_px;
	var first_ys;
	var last_ys;
	var stem_direction;
	if (n1) {
	  first_x_px = x1+2;
	  stem_direction = dir;
	  first_ys = n1.y;
	}

	if (n2) {
	  last_x_px = x2-2;
	  stem_direction = dir;
	  last_ys = n2.y;
	}

	var path = renderTie({
	  first_x_px,
	  last_x_px,
	  first_ys,
	  last_ys,
	  direction: stem_direction
	});
	
	var tie = paper.path(path);
	tie.transform('t0 '+(dir*5));
	
	return tie;
}

export function renderTie(params) {
	var render_options = {
	  cp1: params.cp1 ? params.cp1 : 21,      // Curve control point 1
	  cp2: params.cp2 ? params.cp2 : 26,      // Curve control point 2
	  text_shift_x: 0,
	  first_x_shift: 0,
	  last_x_shift: 0,
	  y_shift: 7,
	  tie_spacing: 0
	};

	var cp1 = render_options.cp1;
	var cp2 = render_options.cp2;

	if (Math.abs(params.last_x_px - params.first_x_px) < 10) {
	  cp1 = 2; cp2 = 8;
	}

	var first_x_shift = render_options.first_x_shift;
	var last_x_shift = render_options.last_x_shift;
	var y_shift = render_options.y_shift * params.direction;

	//for (var i = 0; i < 1; i++) {
	var cp_x = ((params.last_x_px + last_x_shift) +
	  (params.first_x_px + first_x_shift)) / 2;
	var first_y_px = params.first_ys + y_shift;
	var last_y_px = params.last_ys + y_shift;

	var top_cp_y = ((first_y_px + last_y_px) / 2) + (cp1 * params.direction);
	var bottom_cp_y = ((first_y_px + last_y_px) / 2) + (cp2 * params.direction);
	
	var path = "M"+params.first_x_px+','+first_y_px+' Q'+cp_x+','+top_cp_y+' '+params.last_x_px+','+last_y_px+' Q'+cp_x+','+bottom_cp_y+' '+params.first_x_px+','+first_y_px;
	return path;

	console.log(cp_x);
	console.log(top_cp_y);
	console.log(bottom_cp_y);
	  /*ctx.beginPath();
	  ctx.moveTo(params.first_x_px + first_x_shift, first_y_px);
	  ctx.quadraticCurveTo(cp_x, top_cp_y, params.last_x_px + last_x_shift, last_y_px);
	  ctx.quadraticCurveTo(cp_x, bottom_cp_y, params.first_x_px + first_x_shift, first_y_px);
	  ctx.closePath();
	  ctx.fill();*/
	//}
}

export function findPickup(staff,beats) {
	var ary = [];
	var rs = ['1.','1','2.','2','4.','4','8','16'];
	var r = 0;
	while(beats>0) {
		if (!rs[r]) break;

		var dur = rs[r];
		var rd = rBeat(staff,dur);
		if (beats-rd >= 0) {
			ary.push({type:'rest',dur:dur});
			beats -= rd;
		} else {
			r++;
		}
	}

	return ary;
}

var svgPoint; // cursorPoint Global SVG Reference Point
export function cursorPoint(evt, paper, ele) {
    // Call this with the CLICK/DBLCLICK event arg
    //console.log('Event: ' + evt.x + ',' + evt.y)
    if (!svgPoint) svgPoint = paper.node.createSVGPoint();
    svgPoint.x = evt.x;
    svgPoint.y = evt.y;
	//console.log(svgPoint);
    return svgPoint.matrixTransform(ele.node.getScreenCTM().inverse());
}

export function findScoreKey(value,mode) {
    switch(value) {
        case '0 sharps/flats':
            if (mode=='Major') return 'C';
            return 'A';
        case '1 sharp':
            if (mode=='Major') return 'G';
            return 'E';
        case '2 sharps':
            if (mode=='Major') return 'D';
            return 'B';
        case '3 sharps':
            if (mode=='Major') return 'A';
            return 'F♯';
        case '4 sharps':
            if (mode=='Major') return 'E';
            return 'C♯';
        case '5 sharps':
            if (mode=='Major') return 'B';
            return 'G♯';
        case '6 sharps':
            if (mode=='Major') return 'F♯';
            return 'D♯';
        case '7 sharps':
            if (mode=='Major') return 'C♯';
            return 'A♯';
        case '7 flats':
            if (mode=='Major') return 'C\u0231';
            return 'A\u0231';
        case '6 flats':
            if (mode=='Major') return 'G\u0231';
            return 'E\u0231';
        case '5 flats':
            if (mode=='Major') return 'D\u0231';
            return 'B\u0231';
        case '4 flats':
            if (mode=='Major') return 'A\u0231';
            return 'F';
        case '3 flats':
            if (mode=='Major') return 'E\u0231';
            return 'C';
        case '2 flats':
            if (mode=='Major') return 'B\u0231';
            return 'G';
        case '1 flat':
            if (mode=='Major') return 'F';
            return 'D';
    }
}

export function keySig(key, mode) {
    if (mode.toLowerCase().indexOf('minor') != -1) key = key[0].toLowerCase() + key.slice(1);
    var sig = [];
    var type;
    switch (key) {
        case 'C':
            type = '';
            break;
        case 'a':
            type = '';
            break;
        case 'G':
            sig = ['F♯'];
            break;
        case 'e':
            sig = ['F♯'];
            break;
        case 'D':
            sig = ['F♯', 'C♯'];
            break;
        case 'b':
            sig = ['F♯', 'C♯'];
            break;
        case 'A':
            sig = ['F♯', 'C♯', 'G♯'];
            break;
        case 'f♯':
            sig = ['F♯', 'C♯', 'G♯'];
            break;
        case 'E':
            sig = ['F♯', 'C♯', 'G♯', 'D♯'];
            break;
        case 'c♯':
            sig = ['F♯', 'C♯', 'G♯', 'D♯'];
            break;
        case 'B':
            sig = ['F♯', 'C♯', 'G♯', 'D♯', 'A♯'];
            break;
        case 'g♯':
            sig = ['F♯', 'C♯', 'G♯', 'D♯', 'A♯'];
            break;
        case 'F♯':
            sig = ['F♯', 'C♯', 'G♯', 'D♯', 'A♯', 'E♯'];
            break;
        case 'd♯':
            sig = ['F♯', 'C♯', 'G♯', 'D♯', 'A♯', 'E♯'];
            break;
        case 'C♯':
            sig = ['F♯', 'C♯', 'G♯', 'D♯', 'A♯', 'E♯', 'B♯'];
            break;
        case 'a♯':
            sig = ['F♯', 'C♯', 'G♯', 'D♯', 'A♯', 'E♯', 'B♯'];
            break;
        case 'F':
            sig = ['B\u0231'];
            break;
        case 'd':
            sig = ['B\u0231'];
            break;
        case 'B\u0231':
            sig = ['B\u0231', 'E\u0231'];
            break;
        case 'g':
            sig = ['B\u0231', 'E\u0231'];
            break;
        case 'E\u0231':
            sig = ['B\u0231', 'E\u0231', 'A\u0231'];
            break;
        case 'c':
            sig = ['B\u0231', 'E\u0231', 'A\u0231'];
            break;
        case 'A\u0231':
            sig = ['B\u0231', 'E\u0231', 'A\u0231', 'D\u0231'];
            break;
        case 'f':
            sig = ['B\u0231', 'E\u0231', 'A\u0231', 'D\u0231'];
            break;
        case 'D\u0231':
            sig = ['B\u0231', 'E\u0231', 'A\u0231', 'D\u0231', 'G\u0231'];
            break;
        case 'b\u0231':
            sig = ['B\u0231', 'E\u0231', 'A\u0231', 'D\u0231', 'G\u0231'];
            break;
        case 'G\u0231':
            sig = ['B\u0231', 'E\u0231', 'A\u0231', 'D\u0231', 'G\u0231', 'C\u0231'];
            break;
        case 'e\u0231':
            sig = ['B\u0231', 'E\u0231', 'A\u0231', 'D\u0231', 'G\u0231', 'C\u0231'];
            break;
        case 'C\u0231':
            sig = ['B\u0231', 'E\u0231', 'A\u0231', 'D\u0231', 'G\u0231', 'C\u0231', 'F\u0231'];
            break;
        case 'a\u0231':
            sig = ['B\u0231', 'E\u0231', 'A\u0231', 'D\u0231', 'G\u0231', 'C\u0231', 'F\u0231'];
            break;
    }
    if (sig.indexOf('F♯') != -1) type = 'sharp';
    if (sig.indexOf('B\u0231') != -1) type = 'flat';
    return [sig, type];
}

export function numToDur(staff,num) {
	var fac = 1;
	var ts = staff.params.tsig;
	if (ts.indexOf('/8')!=-1)
		fac = 2;
	if (ts.indexOf('/2')!=-1)
		fac = 0.5;
	num = num/fac;
	num = quantBeat(num);
	switch(num) {
		case 0.166:
			return 's16';
			break;
		case 0.25:
			return '16';
			break;
		case 0.333:
			return 't8';
			break;
		case 0.375:
			return 'f8';
			break;
		case 0.666:
			return 't4';
			break;
		case 0.75:
			return '8.';
			break;
		case 0.5:
			return '8';
			break;
		case 1:
			return '4';
			break;
		case 2:
			return '2';
			break;
		case 4:
			return '1';
			break;
		case 1.5:
			return '4.';
			break;
		case 1.25:
			return '16,4';
			break;
		case 1.75:
			return '8.,4';
			break;
		case 3:
			return '2.';
			break;
	}
}

export function drawKeySig(staff, clef, key, scale) {
    var lnsp = 17.75;
    key = key.replace('♭', '\u0231');

    var ks = keySig(key, scale);
    var sig = ks[0];
    staff.keySignature = sig;
    var type = ks[1];
    var base = (14 * lnsp) / 2;
    var trebYs = [
        base - 7 * lnsp,
        base - 5.5 * lnsp,
        base - 7.5 * lnsp,
        base - 6 * lnsp,
        base - 4.5 * lnsp,
        base - 6.5 * lnsp,
        base - 5 * lnsp,
    ];
    var bassYs = [
        base - 6 * lnsp,
        base - 4.5 * lnsp,
        base - 6.5 * lnsp,
        base - 5 * lnsp,
        base - 3.5 * lnsp,
        base - 5.5 * lnsp,
        base - 4 * lnsp,
    ];
    var altoYs = [
        base - 6.5 * lnsp,
        base - 5 * lnsp,
        base - 7 * lnsp,
        base - 5.5 * lnsp,
        base - 4 * lnsp,
        base - 6 * lnsp,
        base - 4.5 * lnsp,
    ];
    var trebYf = [
        base - 5 * lnsp,
        base - 6.5 * lnsp,
        base - 4.5 * lnsp,
        base - 6 * lnsp,
        base - 4 * lnsp,
        base - 5.5 * lnsp,
        base - 3.5 * lnsp,
    ];
    var bassYf = [
        base - 4 * lnsp,
        base - 5.5 * lnsp,
        base - 3.5 * lnsp,
        base - 5 * lnsp,
        base - 3 * lnsp,
        base - 4.5 * lnsp,
        base - 2.5 * lnsp,
    ];
    var altoYf = [
        base - 4.5 * lnsp,
        base - 6 * lnsp,
        base - 4 * lnsp,
        base - 5.5 * lnsp,
        base - 3.5 * lnsp,
        base - 5 * lnsp,
        base - 3 * lnsp,
    ];

    var ax = [];
    if (type == 'sharp') {
        if (clef == 'Treble') ax = trebYs;
        if (clef == 'Bass') ax = bassYs;
        if (clef == 'Alto') ax = altoYs;
    } else {
        if (clef == 'Treble') ax = trebYf;
        if (clef == 'Bass') ax = bassYf;
        if (clef == 'Alto') ax = altoYf;
    }
    staff.KSIGDX = sig.length * 18;
    drawKeyAx(staff, type, ax, sig);
};

var drawKeyAx = function (staff, type, ax, sig) {
    var S = staff.paper;
    var axe;
    var dx = 18;
    switch (type) {
        case 'sharp':
            for (var i = 0; i < sig.length; i++) {
                axe = S.path(sharpPath).transform('t' + (dx * i - 65) + ' ' + ax[i] + ' s0.062 -0.062');
                staff.whole.append(axe);
                staff.keyAx.push(axe);
                axe.name = sig[i];
            }
            break;
        case 'flat':
            for (var i = 0; i < sig.length; i++) {
                axe = S.path(flatPath).transform('t' + (dx * i - 15) + ' ' + (ax[i] - 160.5) + ' s0.062 -0.062');
                staff.whole.append(axe);
                staff.keyAx.push(axe);
                axe.name = sig[i];
            }
            break;
    }
};

export function handleTrans(t, e, dx, dy) {
    //for safari bullshit
    if (!t) {
        e.node.setAttribute('transform', 'translate(' + dx + ', ' + dy + ')');
        return;
    }
    if (t.indexOf('matrix') != -1) {
        var ary = t.split(',');
        var txt =
            ary[0] +
            ',' +
            ary[1] +
            ',' +
            ary[2] +
            ',' +
            ary[3] +
            ',' +
            (parseFloat(ary[4]) + dx) +
            ',' +
            (parseFloat(ary[5].replace(')', '')) + dy) +
            ')';
        e.node.setAttribute('transform', txt);
    }
    if (t.indexOf('translate') != -1) {
        var ary = t.split(',');
        var x = parseFloat(ary[0].replace('translate(', '')) + dx;
        var y = parseFloat(ary[1].replace(' ', '').replace(')', '')) + dy;
        e.node.setAttribute('transform', 'translate(' + x + ', ' + y + ')');
    }
}

export function uniqueID() {
	return Math.floor(Math.random() * Date.now())
}

export function moveEle(e, dx, dy) {
    if (navigator.platform.indexOf('Mac') != -1) {
        var t = e.node.getAttribute('transform');
        handleTrans(t, e, dx, dy);
    } else {
        if (e.node.transform.baseVal[0]) {
            e.node.transform.baseVal[0].matrix.e += dx;
            e.node.transform.baseVal[0].matrix.f += dy;
        } else {
            e.transform('t' + dx + ' ' + dy);
        }
    }
}

export function quantBeat(b) {
	var quants = [0.083,0.166,0.25,0.333,0.416,0.5,0.583,0.666,0.75,0.833,0.916,1];
	var base = 0.083;
	
	var tol = 0.015;
	for (var i=0; i<quants.length; i++) {
		if (Math.abs( b - (Math.floor(b)+quants[i]) ) < tol)
			b = Math.floor(b) + quants[i];
	}
	
	return b;
}

export function newX(dur) {
	dur = dur.replace('r','');
	switch(dur) {
		case '1':
			return 70;
			break;
		case '1.':
			return 70;
			break;
		case '2':
			return 45;
			break;
		case '4':
			return 25;
			break;
		case 't4':
			return 25;
			break;
		case 'd4':
			return 50;
			break;
		case '2.':
			return 55;
			break;
		case '4.':
			return 70;
			break;
		case '8':
			return 27;
			break;
		case 'd8':
			return 45;
			break;
		case '8.':
			return 35;
			break;
		case '16':
			return 20;
			break;
		case 's16':
			return 15;
			break;
		case 't8':
			return 15;
			break;
		case 'f8':
			return 15;
			break;
		default:
			return 30;
			break;
	}
}

export function noteW(dur) {
	dur = dur.replace('r','');
	switch(dur) {
		case '1':
			return 42.5;
			break;
		case '1.':
			return 42.5;
			break;
		case '2':
			return 42.5;
			break;
		case '4':
			return 32.5;
			break;
		case 't4':
			return 27.5;
			break;
		case 'd4':
			return 27.5;
			break;
		case '2.':
			return 42.5;
			break;
		case '4.':
			return 42.5;
			break;
		case '8':
			return 20;
			break;
		case 'd8':
			return 20;
			break;
		case '8.':
			return 25;
			break;
		case '16':
			return 20;
			break;
		case 's16':
			return 15;
			break;
		case 't8':
			return 25;
			break;
		case 'f8':
			return 15;
			break;
		default:
			return 42.5;
			break;
	}
}

//paths
export var altoPath =
    'M318 0c0 -33 7 -73 45 -73c29 0 57 31 88 31c123 0 229 -89 229 -208c0 -169 -93 -250 -265 -250c-83 0 -152 61 -152 142c0 42 34 77 76 77s76 -35 76 -77c0 -39 -49 -37 -49 -76c0 -23 24 -38 49 -38c116 0 140 90 140 222c0 106 -12 180 -104 180 c-72 0 -119 -71 -119 -149c0 -9 -7 -14 -14 -14s-13 5 -13 14c0 76 -31 147 -84 200v-471c0 -6 -4 -10 -10 -10h-21c-6 0 -10 4 -10 10v980c0 6 4 10 10 10h21c6 0 10 -4 10 -10v-471c53 53 84 124 84 200c0 9 6 14 13 14s14 -5 14 -14c0 -78 47 -149 119 -149 c92 0 104 74 104 180c0 132 -24 222 -140 222c-25 0 -49 -15 -49 -38c0 -39 49 -37 49 -76c0 -42 -34 -77 -76 -77s-76 35 -76 77c0 81 69 142 152 142c172 0 265 -81 265 -250c0 -119 -106 -208 -229 -208c-31 0 -59 31 -88 31c-38 0 -45 -40 -45 -73zM129 -500h-119 c-6 0 -10 4 -10 10v980c0 6 4 10 10 10h119c6 0 10 -4 10 -10v-980c0 -6 -4 -10 -10 -10z';
export var treblePath =
    'M374 260c5 0 10 1 15 1c154 0 254 -127 254 -259c0 -76 -33 -153 -107 -208c-22 -17 -47 -29 -73 -37c3 -36 5 -72 5 -108c0 -19 0' +
    ' -37 -1 -56c-7 -120 -88 -226 -206 -226c-108 0 -195 87 -195 195c0 58 53 104 112 104c54 0 95 -48 95 -104c0 -52 -43 -95 -95 -95 c-12 0 -24' +
    ' 4 -35 9c26 -41 70 -70 121 -70c96 0 157 93 163 193c1 18 2 35 2 53c0 32 -2 64 -5 96c-29 -5 -58 -8 -89 -8c-188 0 -333 171 -333 372c0 178 133' +
    ' 306 251 440c-18 63 -35 126 -42 191c-6 51 -7 102 -7 154c0 116 55 226 150 295c2 2 5 3 8 3s6 -1 8 -3c70 -84 132 -246 132 -359c0 -144 -87 -256' +
    ' -183 -365c20 -69 39 -138 55 -208zM459 -205c69 24 117 96 117 166c0 92 -69 184 -179 193c25 -117 48 -235 62 -359zM71 31c0 -136 131 -251 267' +
    ' -251c28 0 55 2 82 6c-15 128 -38 247 -64 367c-79 -9 -125 -62 -125 -121c0 -44 26 -92 82 -124c4 -4 7 -9 7 -14c0 -11 -9 -21 -20 -21c-3 0 -6 1' +
    ' -9 2c-80 43 -116 114 -116 184c0 88 57 173 158 196c-14 60 -30 119 -46 178c-108 -121 -216 -242 -216 -402zM411 1050c-99 -50 -161 -151 -161' +
    ' -262c0 -76 15 -137 33 -200c82 97 149 198 149 325 c0 58 -4 84 -21 137z';
export var bassPath =
    'M556 -125c0 29 23 52 52 52s52 -23 52 -52s-23 -52 -52 -52s-52 23 -52 52zM556 125c0 29 23 52 52 52s52 -23 52' +
    ' -52s-23 -52 -52 -52s-52 23 -52 52zM233 261c171 0 292 -86 292 -248c0 -263 -264 -415 -517 -521c-2 -2 -5 -3 -8 -3c-6 0 -11' +
    ' 5 -11 11c0 3 1 6 3 8 c203 118 415 265 415 494c0 121 -64 237 -174 237c-79 0 -138 -57 -164 -133c14 8 28 13 43 13c55 0 100' +
    ' -45 100 -100c0 -58 -44 -107 -100 -107c-60 0 -112 48 -112 107c0 132 103 242 233 242z';
export var sharpPath =
    'M215 -316c0 -9 -8 -17 -17 -17s-17 8 -17 17v151l-87 -31v-162c0 -9 -8 -17 -17 -17s-17 8 -17 17v150l-39' +
    ' -13c-10 -4 -21 4 -21 15v64c0 7 5 13 11 15l49 18v164l-39 -14c-10 -4 -21 4 -21 15v65c0 7 5 13 11 15l49 17v163c0 9 8' +
    ' 17 17 17s17 -8 17 -17v-151l87 31v162 c0 9 8 17 17 17s17 -8 17 -17v-150l39 13c10 4 21 -4 21 -15v-64c0 -7 -5 -13 -11' +
    ' -15l-49 -18v-164l39 14c10 4 21 -4 21 -15v-65c0 -7 -5 -13 -11 -15l-49 -17v-163zM94 67v-164l87 30v164z';
export var doublesharpPath =
    'M63.4219 333.7031 L43.5938 333.7031 L43.5938 321.4688 Q43.5938 315.8438 36 315.8438 Q33.6094 315.8438' +
    ' 31.2188 317.3906 Q28.4062 319.2188 28.4062 321.4688 L28.4062 333.7031 L9.4219 333.7031 L9.4219 314.7188 L21.6562' +
    ' 314.7188 Q27.2812 314.7188 27.2812 307.125 Q27.2812 304.7344 25.5938 302.1328 Q23.9062 299.5312 21.6562 299.5312' +
    ' L9.4219 299.5312 L9.4219 280.4062 L28.4062 280.4062 L28.4062 292.6406 Q28.4062 294.8906 31.2188 296.7188 Q33.6094' +
    ' 298.2656 36 298.2656 Q43.5938 298.2656 43.5938 292.6406 L43.5938 280.4062 L63.4219 280.4062 L63.4219 299.5312 L51.1875' +
    ' 299.5312 Q48.9375 299.5312 47.25 302.1328 Q45.5625 304.7344 45.5625 307.125 Q45.5625 314.7188 51.1875 314.7188 L63.4219' +
    ' 314.7188 L63.4219 333.7031 Z';
export var flatPath =
    'M27 41l-1 -66v-11c0 -22 1 -44 4 -66c45 38 93 80 93 139c0 33 -14 67 -43 67c-31 0 -52 -30 -53 -63zM-15 -138l-12 595c8' +
    ' 5 18 8 27 8s19 -3 27 -8l-7 -345c25 21 58 34 91 34c52 0 89 -48 89 -102c0 -80 -86 -117 -147 -169c-15 -13 -24 -38 -45 -38 c-13 0 -23 11 -23 25z';
export var doubleflatPath =
    'M28.125 392.4844 L28.125 266.625 L35.4375 266.625 L35.4375 352.4062 L42.4688 343.6875 Q49.2188 337.5 56.5312 337.5' +
    ' Q61.3125 337.5 65.9531 339.6094 Q70.5938 341.7188 72.8438 346.3594 Q75.0938 351 75.0938 357.4688 Q74.8125 364.2188 68.625 371.25' +
    ' Q62.4375 378.2812 51.1875 383.9062 Q39.9375 389.5312 28.125 392.4844 ZM75.9375 392.4844 L75.9375 266.625 L83.25 266.625 L83.25' +
    ' 352.4062 L90.2812 343.6875 Q97.0312 337.5 104.3438 337.5 Q109.125 337.5 113.7656 339.6094 Q118.4062 341.7188 120.6562 346.3594' +
    ' Q122.9062 351 122.9062 357.4688 Q122.625 364.2188 116.4375 371.25 Q110.25 378.2812 99 383.9062 Q87.75 389.5312 75.9375 392.4844' +
    ' ZM35.4375 378.8438 Q51.4688 373.3594 56.9531 367.6641 Q62.4375 361.9688 62.4375 358.0312 Q62.4375 355.2188 62.0156 353.5312' +
    ' Q61.5938 351.8438 59.7656 350.2969 Q57.9375 348.75 55.6875 348.75 Q53.4375 348.75 49.7812 350.1562 Q46.125 351.5625 43.5938' +
    ' 354.0938 L35.4375 362.25 L35.4375 378.8438 ZM83.25 378.8438 Q99.2812 373.3594 104.7656 367.6641 Q110.25 361.9688 110.25' +
    ' 358.0312 Q110.25 355.2188 109.8281 353.5312 Q109.4062 351.8438 107.5781 350.2969 Q105.75 348.75 103.5 348.75 Q101.25 348.75' +
    ' 97.5938 350.1562 Q93.9375 351.5625 91.4062 354.0938 L83.25 362.25 L83.25 378.8438 Z';
export var naturalPath =
    'M-8 375c8 4 17 7 26 7s17 -3 25 -7l-3 -183l106 20h3c10 0 18 -7 18 -17l7 -570c-8 -4 -16 -7 -25 -7s-17 3 -25 7l3 183l-106 -20h-3c-10 0 -18 7 -18 17zM131 112l-92 -17l-3 -207l92 17z';
export var wholePath =
    'M215 112c-50 0 -69 -43 -69 -88c0 -77 57 -136 134 -136c50 0 69 43 69 88c0 77 -57 136 -134 136zM495 0c0 -43 -35' +
    ' -76 -73 -97c-53 -30 -113 -41 -174 -41s-122 11 -175 41c-38 21 -73 54 -73 97s35 76 73 97c53 30 114 41 175 41s121 -11' +
    ' 174 -41 c38 -21 73 -54 73 -97z';
export var closedPath =
    'M224 136c53 0 105 -26 105 -86c0 -71 -57 -122 -104 -150c-36 -22 -78 -36 -120 -36c-53 0 -105 26 -105' +
    ' 86c0 71 58 122 105 150c36 22 77 36 119 36z';
export var openPath =
    'M318 67c0 25 -21 42 -43 42c-4 0 -9 -1 -13 -2c-31 -9 -79 -41 -117 -65s-85 -55 -106 -80c-7 -8 -11 -19 -11 -29c0 -25 21 -42 43 -42c4' +
    ' 0 8 1 12 2c31 9 79 41 117 65s86 55 107 80c7 8 11 19 11 29zM267 136c44 0 79 -20 79 -68c0 -19 -6 -38 -12 -57c-13 -37 -32 -73 -65 -95c-54' +
    ' -36 -115 -52 -190 -52c-44 0 -79 20 -79 68c0 19 6 38 12 57c13 37 31 73 64 95c54 36 116 52 191 52z';
export var rnGrad2 = 'l(-0.25,-0.25,1.25,1.25)#A0AFB3-#FFFFFF';

//time signatures
export var onePath =
    'M122 503c18 0 34 -16 53 -16c15 0 29 7 42 13c1 1 3 1 4 1c7 0 12 -7 12 -17v-367c0 -48 28 -94 73 -94c8 0 12 -5 12 -11s-4 -12 -12 -12c-44 0 -87 13 -131 13s-87 -13 -131 -13c-8 0 -12 6 -12 12s4 11 12 11c45 0 73 46 73 94v245c0 12 -9 19 -17 19 c-4 0 -8 -3 -10 -7l-64 -136c-3 -6 -7 -9 -12 -9c-8 0 -16 6 -16 14c0 2 1 5 2 7l117 250c1 2 3 3 5 3z';
export var twoPath =
    'M23 11c-1 -7 -6 -11 -11 -11c-6 0 -12 4 -12 11c0 175 235 165 235 344c0 60 -18 122 -70 122c-26 0 -51 -14 -51 -37c0 -27 35 -41 35 -68c0 -38 -30 -69 -68 -69s-69 31 -69 69c0 76 72 128 153 128c96 0 187 -56 187 -145c0 -153 -151 -159 -249 -220c9 2 17 3 26 3 c33 0 70 -11 105 -36c21 -15 46 -23 66 -23c21 0 39 9 44 29c2 6 6 9 11 9c6 0 12 -5 12 -12c0 -67 -90 -105 -138 -105c-36 0 -71 14 -96 44c-13 16 -31 22 -48 22c-30 0 -59 -20 -62 -55z';
export var threePath =
    'M150 477c-29 0 -59 -8 -59 -33c0 -22 36 -25 36 -47c0 -32 -26 -58 -58 -58s-57 26 -57 58c0 65 67 103 138 103c91 0 169 -33 169 -114c0 -43 -4 -82 -42 -100c-9 -4 -14 -12 -14 -21s5 -18 14 -22c41 -19 56 -57 56 -103c0 -92 -79 -140 -179 -140c-79 0 -154 42 -154 113c0 36 30 66 66 66s65 -30 65 -66c0 -25 -40 -28 -40 -53c0 -27 32 -37 63 -37c49 0 63 61 63 117v16c0 54 -2 94 -50 94h-80c-10 0 -14 8 -14 15s4 14 14 14h80c49 0 50 43 50 99v8c0 54 -18 91 -67 91z';
export var fourPath =
    'M204 307c22 14 44 29 60 50c11 15 19 33 27 50c2 4 5 6 9 6c6 0 13 -5 13 -14v-245h72c10 0 15 -7 15 -14s-5 -15 -15 -15h-72v-8c0 -48 27 -94 72 -94c8 0 12 -5 12 -11s-4 -12 -12 -12c-44 0 -87 13 -131 13s-87 -13 -131 -13c-8 0 -12 6 -12 12s4 11 12 11 c45 0 73 46 73 94v8h-167c-23 0 -31 14 -31 23c0 3 1 5 2 6c80 93 138 207 138 330c0 9 6 16 13 16h3c23 -7 47 -13 71 -13s48 6 71 13c2 1 3 1 5 1c11 0 18 -10 12 -17l-284 -330h167v131c0 8 1 17 8 22z';
export var fivePath =
    'M46 500c46 -8 91 -13 137 -13s92 5 138 13h4c12 0 19 -10 13 -16c-68 -68 -166 -93 -263 -93c-9 0 -17 -8 -17 -17v-96c28 28 66 43 105 43c116 0 186 -51 186 -161c0 -94 -87 -160 -186 -160c-82 0 -163 39 -163 113c0 36 30 66 66 66s65 -30 65 -66 c0 -25 -40 -28 -40 -53c0 -30 37 -37 72 -37c57 0 70 71 70 137c0 63 -15 132 -70 132c-42 0 -83 -12 -107 -45c-3 -4 -8 -6 -12 -6c-8 0 -15 6 -15 15v228c0 9 7 16 15 16h2z';
export var sixPath =
    'M170 256c-51 0 -53 -44 -53 -102v-14v-15c0 -58 2 -102 53 -102c57 0 61 51 61 117s-4 116 -61 116zM117 266c17 7 34 13 53 13c101 0 170 -44 170 -139s-69 -140 -170 -140c-112 0 -170 124 -170 250c0 128 72 250 190 250c71 0 138 -39 138 -103c0 -36 -29 -66 -65 -66 s-66 30 -66 66c0 24 39 25 39 49c0 20 -23 31 -46 31c-66 0 -75 -65 -75 -139c0 -24 2 -48 2 -72z';
export var sevenPath =
    'M67 410c-31 0 -38 -43 -38 -70v-75c0 -10 -7 -15 -14 -15s-15 5 -15 15v220c0 10 8 15 15 15s14 -5 14 -15v-14c0 -9 12 -19 15 -15c21 29 48 44 78 44c29 0 58 -13 84 -39c12 -12 27 -17 43 -17c30 0 63 19 84 50c3 5 7 6 11 6c8 0 16 -6 16 -14c0 -3 -1 -6 -3 -9 c-83 -118 -158 -249 -158 -387c0 -25 3 -50 8 -76c2 -9 -5 -17 -13 -17c-1 0 -3 1 -4 1c-24 7 -48 15 -73 15s-50 -8 -74 -15c-1 0 -3 -1 -4 -1c-9 0 -16 9 -12 17c54 128 134 242 216 355c-20 -11 -40 -16 -59 -16c-22 0 -42 8 -60 26s-39 31 -57 31z';
export var eightPath =
    'M259 288c27 29 49 62 49 102c0 55 -57 87 -117 87c-47 0 -75 -36 -75 -71c0 -20 10 -40 30 -52zM289 271c55 -31 83 -79 83 -127c0 -73 -66 -144 -189 -144c-94 0 -183 54 -183 140c0 50 39 85 76 120c-41 28 -61 67 -61 106c0 68 61 134 176 134c81 0 161 -38 161 -110 c0 -47 -30 -85 -63 -119zM106 242c-33 -29 -62 -59 -62 -102c0 -69 66 -117 139 -117c54 0 86 42 86 83c0 24 -10 48 -35 62z';
export var ninePath =
    'M170 244c51 0 53 44 53 102v14v15c0 58 -2 102 -53 102c-57 0 -61 -51 -61 -117s4 -116 61 -116zM223 234c-17 -7 -34 -13 -53 -13c-101 0 -170 44 -170 139s69 140 170 140c112 0 170 -124 170 -250c0 -128 -72 -250 -190 -250c-71 0 -138 39 -138 103c0 36 29 66 65 66 s66 -30 66 -66c0 -24 -39 -25 -39 -49c0 -20 23 -31 46 -31c66 0 75 65 75 139c0 24 -2 48 -2 72z';

//flag paths
export var sixFlagPath =
    'M0 0v-500h-16v500h16zM0 0c0 -178 203 -292 203 -470c0 -33 -9 -65 -23 -95c18 -37 29 -75 29 -117c0 -63 -16 -125 -42 -183c-5 -8 -12 -12 -19 -12c-13 0 -26 11 -23 27c26 52 42 110 42 168c0 97 -95 191 -167 257v212c0 -118 91 -206 153 -304c5 15 7 31 7 47 c0 96 -91 190 -160 257v213z';
export var eighthFlagPath =
    'M0 0h-16v250h16c98 58 227 148 227 259c0 57 -16 112 -41 163c-3 16 10 27 23 27c7 0 15 -3 20 -11c25 -56 40 -117 40 -179c0 -204 -269 -305 -269 -509z';
export var eighthFlagPathU =
    'M0 0h-16v250h16c98 58 227 148 227 259c0 57 -16 112 -41 163c-3 16 10 27 23 27c7 0 15 -3 20 -11c25 -56 40 -117 40 -179c0 -204 -269 -305 -269 -509z';
export var eighthFlag =
    'M0 0c0 -198 209 -335 209 -533c0 -71 -16 -141 -42 -207c-5 -8 -12 -12 -19 -12c-13 0 -26 11 -23 27c26 61 42 126 42 192c0 104 -95 208 -167 283h-16v250h16z';
export var sixteenthFlag =
    'M0 0c0 -178 203 -292 203 -470c0 -33 -9 -65 -23 -95c18 -37 29 -75 29 -117c0 -63 -16 -125 -42 -183c-5 -8 -12 -12 -19 -12c-13 0 -26 11 -23 27c26 52 42 110 42 168c0 97 -95 191 -167 257v-75h-16v500h16zM153 -517c5 15 7 31 7 47c0 96 -91 190 -160 257 c0 -118 91 -206 153 -304z';
export var eighthFlagI =
    'M0 0h-16v250h16c98 58 227 148 227 259c0 57 -16 112 -41 163c-3 16 10 27 23 27c7 0 15 -3 20 -11c25 -56 40 -117 40 -179c0 -204 -269 -305 -269 -509z';
export var sixteenthFlagI =
    'M0 205c92 51 214 131 214 233c0 24 -5 48 -14 70c-80 -89 -200 -160 -200 -283v-20zM269 663c0 -44 -15 -82 -38 -116c16 -34 25 -71 25 -109c0 -181 -256 -257 -256 -438h-16v500h16v-70c98 49 227 127 227 233c0 21 -6 42 -14 62c-3 16 10 27 23 27c30 0 33 -63 33 -89z';

//rest paths
export var sixteenthRestPath =
    'M66 -500l101 327c-34 -12 -70 -23 -106 -23c-46 0 -87 33 -87 79c0 40 32 72 72 72c25 0 49 -15 57 -39c10 -28 5 -59 34 -59c16 0 55 51 60 68l46 151c-34 -12 -68 -22 -104 -22c-46 0 -87 33 -87 79c0 40 33 72 73 72c25 0 48 -15 56 -39c10 -28 5 -59 34 -59 c15 0 50 49 57 63c6 12 23 12 28 0l-179 -670c-8 -7 -17 -10 -27 -10s-20 3 -28 10z';
export var eighthRestPath =
    'M72 -250l117 326c-34 -12 -69 -22 -105 -22c-46 0 -87 33 -87 79c0 40 33 72 73 72c25 0 48 -15 56 -39c10 -28 6 -59 35 -59c16 0 54 48 61 63c6 12 23 12 28 0l-123 -420c-8 -7 -17 -10 -27 -10s-20 3 -28 10z';
export var quarterRestPath =
    'M-23 -116c0 28 11 42 40 42c33 0 78 -13 119 -31l-132 158c-7 9 -10 17 -10 25c0 34 50 66 87 99c25 22 37 52 37 83c0 24 -8 49 -25 69l-35 42c-3 3 -4 7 -4 10c0 9 9 15 17 15c4 0 8 -1 11 -5l151 -180c7 -9 10 -17 10 -25c0 -34 -50 -66 -87 -99 c-25 -22 -37 -52 -37 -83c0 -24 7 -49 24 -69l84 -99c3 -3 4 -7 4 -10c0 -9 -8 -16 -16 -16c-4 0 -9 2 -12 6c-18 21 -63 38 -97 38c-41 0 -53 -26 -53 -67c0 -35 11 -74 28 -94c5 -6 0 -13 -6 -13c-2 0 -4 0 -6 2c-45 54 -92 148 -92 202z';
export var halfRestPath = 'M365 0h-355c-6 0 -10 4 -10 10v136c0 6 4 10 10 10h355c6 0 10 -4 10 -10v-136c0 -6 -4 -10 -10 -10z';
export var wholeRestPath =
    'M365 -156h-355c-6 0 -10 4 -10 10v136c0 6 4 10 10 10h355c6 0 10 -4 10 -10v-136c0 -6 -4 -10 -10 -10z';

export var bracePath =
    "M-12 -656c0 -352 -132 -696 -132 -1032c0 -180 36 -344 156 -484c4 -4 8 -8 8 -12c0 -8 -12 -16 -20 -16c-4 0 -8 0 -12 4c-160 184 -216 416 -216 656c0 360 140 712 140 1052c0 176 -40 344 -160 480v8v8c120 136 160 304 160 480c0 340 -140 692 -140 1052 c0 240 56 472 216 656c4 4 8 4 12 4c8 0 20 -8 20 -16c0 -4 -4 -8 -8 -12c-120 -140 -156 -304 -156 -484c0 -336 132 -680 132 -1032c0 -240 -52 -472 -208 -656c156 -184 208 -416 208 -656z";