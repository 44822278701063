import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { assignmentDeleted } from './../../../slices/assignment/assignmentSlice';
import { useNavigate, useParams } from 'react-router-dom';
import Canvas from './../../icons/Canvas';
import InnerNav from './InnerNav';
import axios from 'axios';

export default function Assignment() {
  const assignments = useSelector(state => state.assignments)
  const courses = useSelector(state => state.courses)
  //const user = useSelector(state => state.user)
  const navigate = useNavigate()
  const { courseid } = useParams()
  const [loading, setLoading] = useState(true)

  const dispatch = useDispatch()

  const deleteAssignment = async (id) => {
    await axios.post('/api/lms/delete-lms-assignment',{id:id})
    dispatch(
      assignmentDeleted({id:id})
    )
  }

  const editAssignment = (id) => {
    navigate('/courses/'+courseid+'/assignments/'+id+'/edit')
  }

  let myAss = assignments.filter((ass)=>ass.CourseId==courseid)
  let myCourse = courses.find((crs)=>crs.id==courseid)
  const renderedPosts = myAss.map((item,index) => (
    <div className="assignment" key={item.id}>
      <div style={{flex:1,padding:'15px'}} onClick={()=>{editAssignment(item.id)}}>
          <h3 style={{marginTop:'0px',marginBottom:'0px'}}>{item.name}</h3>
      </div>
      <div>
        <button onClick={()=>{deleteAssignment(item.id)}}>Delete</button>
      </div>
    </div>
  ))
  
  //if (loading) return <div>Loading</div>
  return (
    <div>
      <div className="course-topbar">
        <div style={{fontSize:'1.25em',fontWeight:'bold',padding:'0.5em'}}>{myCourse.name}</div>
        <div style={{display:'flex',alignItems:'center',gap:'0.5em'}}>
          {(myCourse.linked) && 
            <div style={{display:'flex',alignItems:'center',gap:'0.5em',marginRight:'1em'}}>
              <Canvas/>
            </div>
          }
        </div>
      </div>
      <div style={{display:'flex'}}>
        <InnerNav/>
        <div style={{marginLeft:'1em'}} className="posts-list">
          <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',width:'800px'}}>
            <h2>Assignments</h2>
            <div style={{display:'flex',alignItems:'center'}}>
              <button>Import Assignment(s)</button>
              <button onClick={()=>{navigate('/courses/'+courseid+'/assignments/create')}}>Create Assignment</button>
            </div>
          </div>
          {myAss.length>0?renderedPosts:<p>No assignments yet, click Create Assignment or import an existing assignment to get started.</p>}
        </div>
      </div>
    </div>
  )
}