import { createSlice, nanoid } from '@reduxjs/toolkit';

const initialState = [];

export const assignmentSlice = createSlice({
  name: 'assignment',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    assignmentAdded(state, action) {      
      state.push(action.payload)
    },
    assignmentDeleted(state, action) {
      const { id } = action.payload;
      let arr = state.filter(item => item.id !== id)
      return arr;
    },
    assignmentUpdated(state, action) {
      const { id, name, settings, module, questions, dueDate } = action.payload
      console.log(action.payload)
      const existingAssignment = state.find(post => post.id == id)
      if (existingAssignment) {
        existingAssignment.name = name
        existingAssignment.settings = settings
        existingAssignment.module = module
        existingAssignment.questions = questions
        existingAssignment.dueDate = dueDate
        console.log('updated')
      }
    },
    setAssignments(state, action) {
      return action.payload;
    },
  },
});

export const { assignmentAdded, assignmentUpdated, assignmentDeleted, setAssignments } = assignmentSlice.actions;
//export const selectassignment = (state) => state.user.value;

export default assignmentSlice.reducer;
