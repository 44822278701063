import { useNavigate, useParams } from 'react-router-dom';

export default function InnerNav() {
    const { courseid } = useParams()
    const navigate = useNavigate()
    const path = window.location.pathname;
    const assignmentsPath = '/courses/'+courseid+'/assignments';
    const studentsPath = '/courses/'+courseid+'/students';
    const gradebookPath = '/courses/'+courseid+'/gradebook';
    const settingsPath = '/courses/'+courseid+'/settings';
    return (
        <div className="inner-nav">
            <div className={(path==assignmentsPath)?"inner-nav-active":"inner-nav-inactive"} onClick={()=>{navigate(assignmentsPath)}}>
                <div className={(path==assignmentsPath)?"nav-active":"nav-inactive"}>Assignments</div>
            </div>
            <div className={(path==studentsPath)?"inner-nav-active":"inner-nav-inactive"} onClick={()=>{navigate(studentsPath)}}>
                <div className={(path==studentsPath)?"nav-active":"nav-inactive"}>Students</div>
            </div>
            <div className={(path==gradebookPath)?"inner-nav-active":"inner-nav-inactive"} onClick={()=>{navigate(gradebookPath)}}>
                <div className={(path==gradebookPath)?"nav-active":"nav-inactive"}>Gradebook</div>
            </div>
            <div className={(path==settingsPath)?"inner-nav-active":"inner-nav-inactive"} onClick={()=>{navigate(settingsPath)}}>
                <div className={(path==settingsPath)?"nav-active":"nav-inactive"}>Settings</div>
            </div>
        </div>
    )
}