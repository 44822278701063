import React from 'react';
import Loader from './../Loader.js';
import axios from 'axios';

export default class Validate extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        startWatcher();
    }

    render() {
        return (
            <div style={{display:'flex',alignItems:'center'}}>
                <Loader/>
                <div>Please wait while we process your payment</div>
            </div>
        )
    }
}

function startWatcher() {
    const searchParams = new URLSearchParams(window.location.search);
    var id = searchParams.get('session_id');
    setInterval(
        async function() {
            var res = await axios.get('/session-status?session_id='+id);
            if (res.data.status=='complete') {
                window.location.href = 'https://localhost:3000';
            }
        },1000
    )
}