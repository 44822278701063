import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { setUser } from './../../../slices/user/userSlice';
import toast, { Toaster } from 'react-hot-toast';
import axios from 'axios';
import PageLoader from './../../PageLoader';

export default function Account() {
    const user = useSelector(state => state.user)
    const connected = user.connectedInstitutionId ? true : false;
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()

    const saveProfile = async () => {

    }

    const updateUser = (data) => {
        let ubj = JSON.parse(JSON.stringify(user));
        ubj.institutionName = data.institutionName;
        ubj.connectedInstitutionId = data.connectedInstitutionId;
        dispatch(
            setUser(
                ubj
            )
        )
    }

    if (loading) return <PageLoader/>

    return (
      <div className="course-list">
        <div>
          <h2>My Account</h2>
          <div style={{fontWeight:'bold'}}>Profile</div>
          <div style={{marginTop:'0.5em'}}>
            <div>First Name</div>
            <input defaultValue={user.firstName}></input>
            <div>Last Name</div>
            <input defaultValue={user.lastName}></input>
            <div>Email</div>
            <input defaultValue={user.email} disabled></input>
            <div>
                <button onClick={()=>{saveProfile()}}>Save</button>
            </div>
          </div>
          <div style={{fontWeight:'bold',marginTop:'2em'}}>Institution</div>
          <div style={{marginTop:'0.5em'}}>
            {connected ? user.institutionName : <ConnectInstitution updateUser={updateUser}/>} 
          </div>
        </div>
        <Toaster/>
      </div>
    )
}

function ConnectInstitution(props) {
    const [open, setOpen] = useState(false)
    const [institutions, setInstitutions] = useState(null)

    useEffect(()=>{
        const loadInstitutions = async () => {
            let res = await axios.get('/api/account/institutions');
            setInstitutions(res.data.list);
        }
        loadInstitutions()
    },[])

    const linkInstitution = async () => {
        let name = document.getElementById("institution-picker").value;
        let res = await axios.post('/api/account/connect-institution',{name:name});
        toast.success('Institution linked successfully!');
        props.updateUser(res.data);
        setOpen(false);
    }

    return <div>
        <button onClick={()=>{setOpen(true)}}>Connect</button>
        <div style={{visibility:open?"":"hidden",position:"absolute",top:0,left:0,height:'100%',width:'100%'}}>
            <div className="institution-chooser-background"></div>
            <div className="institution-chooser">
                <h2>Choose Institution</h2>
                <select id="institution-picker">
                {institutions?institutions.map((item,index)=>{
                    return <option key={item.name}>{item.name}</option>
                }):<></>}
                </select>
                <div style={{marginTop:'1em',display:'flex',justifyContent:'center',gap:'0.5em'}}>
                    <button onClick={()=>{setOpen(false)}}>Cancel</button>
                    <button onClick={()=>{linkInstitution()}}>Connect</button>
                </div>
            </div>
        </div>
    </div>
}

//Account
//Name
//Email
//Institution
//  connect an institution
//  opens dialog -> list of institutions
//      button to connect