import rhythmicDictation from './../lib/student/rhythmicdictation.js';
import melodicDictation from './../lib/student/melodicdictation.js';

//question type handlers
export function rdHandler(parent) {
    var params = parent.props.submission.answers[parent.props.selected].musicJSON;
    var id = parent.id+"-score";
    parent.editor = new rhythmicDictation(id,params);
    parent.editor.load(params);
    parent.editor.autosaver(parent.updateMusicJSON);

    //if answer is checked show on editor
    if (parent.props.submission.answers[parent.props.selected].checked) {
        parent.editor.createErrorLists(parent.props.submission.answers[parent.props.selected].errors);
        parent.editor.hideAllErrorButtons();
    }

    //score
    var score = parent.props.submission.answers[parent.props.selected].score;
    parent.score.current.setState({score:score});

    //recording
    parent.recording.current.stop();

    //set counters
    var count = parent.props.assignment.questions[parent.props.selected].settings.listenings - parent.props.submission.answers[parent.props.selected].listens;
    parent.recording.current.counter.current.setState({count:count});

    var count = parent.props.assignment.questions[parent.props.selected].settings.retries - parent.props.submission.answers[parent.props.selected].checks;
    parent.checker.current.counter.current.setState({count:count});

    var checked = parent.props.submission.answers[parent.props.selected].checked;
    parent.checker.current.setState({checked:checked});
}

export function mdHandler(parent) {
    var params = parent.props.submission.answers[parent.props.selected].musicJSON;
    var id = parent.id+"-score";
    parent.editor = new melodicDictation(id,params);
    parent.editor.load(params);
    parent.editor.autosaver(parent.updateMusicJSON);

    //if answer is checked show on editor
    if (parent.props.submission.answers[parent.props.selected].checked) {
        parent.editor.createErrorLists(parent.props.submission.answers[parent.props.selected].errors);
        parent.editor.hideAllErrorButtons();
    }

    //score
    var score = parent.props.submission.answers[parent.props.selected].score;
    parent.score.current.setState({score:score});

    //recording
    parent.recording.current.stop();

    //set counters
    var count = parent.props.assignment.questions[parent.props.selected].settings.listenings - parent.props.submission.answers[parent.props.selected].listens;
    parent.recording.current.counter.current.setState({count:count});

    var count = parent.props.assignment.questions[parent.props.selected].settings.retries - parent.props.submission.answers[parent.props.selected].checks;
    parent.checker.current.counter.current.setState({count:count});

    var checked = parent.props.submission.answers[parent.props.selected].checked;
    parent.checker.current.setState({checked:checked});
}