import React from 'react';
import { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import { Navigate, Outlet } from 'react-router-dom'
import { useStytchSession } from '@stytch/react';
import { useDispatch } from 'react-redux';
import axios from 'axios';

//auth
import Login from './components/auth/Login';
import Authenticate from './components/auth/Authenticate';
import Reset from './components/auth/Reset';

//misc
import PageLoader from './components/PageLoader';
import Onboarding from './components/Onboarding';
import Navbar from './components/Navbar';
//import OnFlow from './components/OnFlow';

//error pages
import NeedAccess from './components/errors/NeedAccess';
import InvalidScope from './components/errors/InvalidScope';

//teacher components
import Account from './components/dashboard/teacher/Account';
import CourseLink from './components/dashboard/teacher/CourseLink';
//import CourseLinkMultiple from './components/dashboard/teacher/CourseLinkMultiple';
import CreateCourse from './components/dashboard/teacher/CreateCourse';
import Courses from './components/dashboard/teacher/Courses';
import ViewCourse from './components/dashboard/teacher/ViewCourse';
import ViewStudents from './components/dashboard/teacher/ViewStudents';
import AssignmentEditor from './components/dashboard/teacher/AssignmentEditor';

//student components
import Assignment from './components/assignment/Assignment';
import Preview from './components/assignment/Preview';
import Submission from './components/assignment/Submission';
import Validate from './components/assignment/Validate';

//store stuff
import { setCourses } from './slices/course/courseSlice';
import { setUser } from './slices/user/userSlice';
import { setAssignments } from './slices/assignment/assignmentSlice';

const Dashboard = () => {
  const { session } = useStytchSession();
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch()
  const navigate = useNavigate();

  useEffect(()=>{
    const loadData = async () => {
      if (session) {
        let res = await axios.get('/api/dashboard/teacherdata');
        if (res.data.student) {
          //send to student
        }
        if (res.data.needs_onboarding) {
          //send to onboarding
          navigate('/onboarding');
          return;
        }
        if (res.data.needflow) {
          window.location.href = res.data.url;
          return;
        }
        //if a student -> send them to student dash?
        //if neither -> send them to onboarding
        dispatch(
          setCourses(res.data.courses)
        )
        dispatch(
          setAssignments(res.data.assignments)
        )
        dispatch(
          setUser(res.data.user)
        )
      }
      setLoading(false)
    }
    loadData();
  },[])

  return (
    session ? 
    <div className="dashboard">
      <Navbar/>{ loading ? <PageLoader/> : <Outlet/>}
    </div> : 
    <Navigate to='/login'/>
  )
}

const Onboard = () => {
  const { session } = useStytchSession();
  const navigate = useNavigate();

  if (!session) {
    navigate('/login');
    return;
  }

  return (
    session ? <Outlet/> : <Navigate to='/login'/>
  )
}

export default function App() {
  return (
    <Routes>
      <Route element={<Dashboard/>}>
        <Route path="/validate" element={<Validate />}/>
        <Route path="/account" element={<Account />}/>
        <Route path="/courses/link" element={<CourseLink />}/>
        {/*<Route path="/courses/link-multiple" element={<CourseLinkMultiple />}/>*/}
        <Route path="/courses/create" element={<CreateCourse />}/>
        <Route path="/courses" element={<Courses />}/>
        <Route path="/courses/:courseid/assignments" element={<ViewCourse />}/>
        <Route path="/courses/:courseid/students" element={<ViewStudents />}/>
        <Route path="/courses/:courseid/assignments/create" element={<AssignmentEditor />}/>
        <Route path="/courses/:courseid/assignments/:assignmentid/edit" element={<AssignmentEditor />}/>
        {/*<Route path="/flow/getstarted" element={<OnFlow />}/>*/}
      </Route>
      <Route element={<Onboard/>}>
        <Route path="/onboarding" element={<Onboarding />}/>
      </Route>
      <Route path="/need-access" element={<NeedAccess/>}/>
      <Route path="/invalid-scope" element={<InvalidScope/>}/>
      <Route path="/course/:courseid/assignment/:assignmentid/submission/:submissionid" element={<Submission/>}/>
      <Route path="/course/:courseid/assignment/:assignmentid" element={<Assignment/>}/>
      <Route path="/course/:courseid/assignment/:assignmentid/preview" element={<Preview/>}/>
      <Route path="/login" element={<Login />}/>
      <Route path="/authenticate" element={<Authenticate />}/>
      <Route path="/reset" element={<Reset />}/>
    </Routes>
  );
}