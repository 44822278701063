import { createSlice, nanoid } from '@reduxjs/toolkit';

const initialState = {
    type: 'Ear Training',
    index: null,
};

export const editorSlice = createSlice({
  name: 'editor',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    changeType(state, action) {
        state.type = action.payload;
    },
    changeIndex(state, action) {
        state.index = action.payload;
    },
  },
});

export const { changeType, changeIndex } = editorSlice.actions;
//export const selecteditor = (state) => state.user.value;

export default editorSlice.reducer;
