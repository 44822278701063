import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setQuestions, clearQuestions } from './../../../slices/question/questionSlice';
import { useSelector } from 'react-redux';
import { assignmentDeleted, setAssignments } from './../../../slices/assignment/assignmentSlice';
import { changeIndex } from './../../../slices/editor/editorSlice';
import { useNavigate, useParams } from 'react-router-dom';
import Canvas from './../../icons/Canvas';
import axios from 'axios';
import InnerNav from './InnerNav';

export default function Assignment() {
    const courses = useSelector(state => state.courses)
    const navigate = useNavigate()
    const { courseid } = useParams()

    let myCourse = courses.find((crs)=>crs.id==courseid)
    
    //if (loading) return <div>Loading</div>
    return (
      <div>
        <div className="course-topbar">
          <div style={{fontSize:'1.25em',fontWeight:'bold',padding:'0.5em'}}>{myCourse.name}</div>
          {myCourse.linked && 
            <div style={{display:'flex',alignItems:'center',gap:'0.5em',marginRight:'1em'}}>
              <div>Linked to</div>
              <Canvas/>
            </div>
          }
        </div>
        <div style={{display:'flex'}}>
          <InnerNav/>
          <div style={{marginLeft:'1em'}} className="posts-list">
            <div>Student list</div>
          </div>
        </div>
      </div>
    )
  }