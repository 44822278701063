import React from 'react';
import axios from 'axios';
import Loader from '../Loader.js';
import Logo from '../logo/Logo.js';
import Save from '../icons/Save.js';
import Submit from '../icons/Submit.js';
import { Tooltip } from 'react-tooltip';
import ReactHowler from 'react-howler';
import abbreviation from './../lib/abbreviation.js';

//questions
import {rdHandler, mdHandler} from './WidgetHandlers.js';

export default class Preview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {loading:true,selected:null};
        this.assignmentView = React.createRef();
        this.changeQuestion = this.changeQuestion.bind(this);
    }

    changeQuestion(val) {
        //save current state
        this.submission.answers[this.state.selected].musicJSON = this.assignmentView.current.doer.current.save();
        this.setState({selected:val});
    }

    async componentDidMount() {
        //get assignment data
        //if not member of course, return nonono bad student
        var url = window.location.href;
        var courseid = url.split('/course/')[1].split('/assignment/')[0];
        var assignmentid = url.split('/assignment/')[1].split('/preview')[0];
        var res = await axios.get('/assignment?courseid='+courseid+'&assignmentid='+assignmentid);
        this.assignment = res.data;

        var res = await axios.post('/create-preview',{AssignmentId:assignmentid});
        this.submission = res.data;
        this.setState({loading:false,selected:0});
    }

    render() {
        if (this.state.loading) return <Loader/>
        return (
            <div className="assignment-bro">
                <AssignmentNav changeQuestion={this.changeQuestion} selected={this.state.selected} questions={this.assignment.questions}/>
                <AssignmentView ref={this.assignmentView} selected={this.state.selected} submission={this.submission} assignment={this.assignment}/>
            </div>
        )
    }
}

class AssignmentView extends React.Component {
    constructor(props) {
        super(props);

        this.doer = React.createRef();
    }

    render() {
        return (
            <div className="assignment-view">
                <TopBar selected={this.props.selected} questions={this.props.assignment.questions} assignment={this.props.assignment}/>
                <Instructions questions={this.props.assignment.questions} selected={this.props.selected}/>
                <QuestionDoer ref={this.doer} selected={this.props.selected} submission={this.props.submission} assignment={this.props.assignment}/>
            </div>
        )
    }
}

class Instructions extends React.Component {
    constructor(props) {
        super(props);
        this.instructions = this.props.questions[this.props.selected].instructions;
    }

    render() {
        if (this.instructions!='')
            return (
                <div className="instructions">
                    <div style={{fontWeight:'bold'}}>Instructions</div>
                    <p>{this.instructions}</p>
                </div>
            )
    }
}

class TopBar extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
                <div style={{display:'flex',alignItems:'center',gap:'0.5em'}}>
                    <h2>{this.props.assignment.name}</h2>
                    <div style={{color:'white',fontWeight:'bold',background:'#bfaed8',padding:'5px',borderRadius:'5px'}}>Assignment Preview</div>
                </div>
                <div>
                    <h3 style={{marginTop:0}}>{"Question "+(this.props.selected+1)+" of "+this.props.questions.length+" - "+this.props.questions[this.props.selected].type}</h3>
                </div>
            </>
        )
    }
}

class QuestionDoer extends React.Component {
    constructor(props) {
        super(props);
        this.id = Math.random();

        this.checkWork = this.checkWork.bind(this);
        this.updateMusicJSON = this.updateMusicJSON.bind(this);
        this.showQuestion = this.showQuestion.bind(this);

        this.recording = React.createRef();
        this.checker = React.createRef();
        this.score = React.createRef();
    }

    async checkWork() {
        var answer = this.props.assignment.questions[this.props.selected];
        var submission = this.props.submission.answers[this.props.selected];
        var obj = {answer:answer,submission:submission};
        var res = await axios.post('https://compute.noizy.io:1221/grade',obj);
        var score = res.data.score;
        this.score.current.setState({score:score});
        var errors = res.data.errors;

        //show on editor
        this.editor.createErrorLists(errors);
        this.editor.hideAllErrorButtons();

        //update database
        this.props.submission.answers[this.props.selected].errors = errors;
        this.props.submission.answers[this.props.selected].score = score;
    }

    updateMusicJSON(json) {
        this.props.submission.answers[this.props.selected].musicJSON = json;
    }

    showQuestion() {
        switch(this.props.assignment.questions[this.props.selected].type) {
            case 'Rhythmic Dictation':
                rdHandler(this);
                break;
            case 'Melodic Dictation':
                mdHandler(this);
                break;
        }
    }

    componentDidUpdate() {
        this.showQuestion();
    }

    componentDidMount() {
        this.showQuestion();
    }

    save() {
        return this.editor.save();
    }

    render() {
        //switch on question type
        return (
            <div className="question-doer">
                <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',border:'1px solid lightgray',borderBottom:'none'}}>
                    <div style={{display:'flex',alignItems:'center'}}>
                        <DictationRecording ref={this.recording} question={this.props.assignment.questions[this.props.selected]} answer={this.props.submission.answers[this.props.selected]} recording={this.props.submission.answers[this.props.selected].recording}/>
                        <DictationChecker parent={this} checkWork={this.checkWork} ref={this.checker} question={this.props.assignment.questions[this.props.selected]} answer={this.props.submission.answers[this.props.selected]}/>
                    </div>
                    <Score ref={this.score}/>
                </div>
                <div className="music-editor" id={this.id+"-score"}></div>
            </div>
        )
    }
}

class Score extends React.Component {
    constructor(props) {
        super(props);
        this.state = {score:null}
    }

    render() {
        if (!this.state.score) return <></>
        var col = 'green';
        var text = 'Nice work!';
        if (this.state.score < 0.85) {
            col = 'orange';
            text = 'Good job!';
        }
        if (this.state.score < 0.7) {
            col = '#E60000';
            text = 'Needs improvement!';
        }
        var s = this.state.score.toLocaleString(undefined,{style: 'percent', minimumFractionDigits:0}); 
        return (
            <div style={{margin:'5px',padding:'5px',display:'flex',alignItems:'center',gap:'0.3em'}}>
                <div style={{color:col,fontWeight:'bold'}}>{text}</div>
                <div style={{color:'white',fontWeight:'bold',background:col,borderRadius:'5px',padding:'5px'}}>{s}</div>
            </div>
        )
    }
}

class DictationChecker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {checked:this.props.answer.checked}
        this.handleToggle = this.handleToggle.bind(this);

        this.counter = React.createRef();
    }

    handleToggle() {
        //if counter is 0, no more
        if (this.counter.current.state.count==0) return;

        var status = this.state.checked;
        this.props.answer.checked = !status;

        switch(status) {
            case true:
                //retry
                this.props.parent.editor.clearErrors(); //clears out error divs
                if (this.props.parent.editor.DB)
                    this.props.parent.editor.DB.whole.attr({display:''}); //shows the dur palette
                break;
            case false:
                //check
                this.counter.current.decrement(); //and send a listen to the server
                this.props.checkWork();
                break;
        }

        //update state
        this.setState({checked:!status});
    }

    render() {
        return (
            <div className="check-holder">
                <button onClick={this.handleToggle}>
                {(this.state.checked) ? 'Retry' : 'Check work'}
                </button>
                <CheckCounter ref={this.counter} type={"check"} answer={this.props.answer} question={this.props.question}/>
            </div>
        )
    }
}

class DictationRecording extends React.Component {
    constructor(props) {
        super(props);
        this.state = {playing:false}

        this.handleToggle = this.handleToggle.bind(this);
        this.handleStop = this.handleStop.bind(this);
        this.handleOnEnd = this.handleOnEnd.bind(this);

        this.stop = this.stop.bind(this);

        this.counter = React.createRef();
        //retries -> this.props.question.settings.retries - this.props.answer.checks
        //listens -> this.props.question.settings.listenings - this.props.answer.listenings
    }

    stop() {
        this.setState({playing:false});
    }

    handleOnEnd () {
        this.setState({
          playing: false
        })
    }

    handleToggle () {
        var status = this.state.playing;
        if (!this.state.playing) {
            //if counter is 0, no more
            if (this.counter.current.state.count==0) return;
            this.counter.current.decrement(); //and send a listen to the server
        }
        this.setState({
          playing: !this.state.playing
        })
        if (!this.state.playing) this.player.stop();
    }

    handleStop () {
        this.player.stop()
        this.setState({
          playing: false // Need to update our local state so we don't immediately invoke autoplay
        })
    }

    render() {
        return (
            <div className="recording-holder">
                <ReactHowler
                    src={[this.props.recording]}
                    playing={this.state.playing}
                    onEnd={this.handleOnEnd}
                    ref={(ref) => (this.player = ref)}
                />
                <button onClick={this.handleToggle}>
                {(this.state.playing) ? 'Stop' : 'Play recording'}
                </button>
                <LACounter ref={this.counter} type={"listening"} answer={this.props.answer} question={this.props.question}/>
            </div>
        )
    }
}

class CheckCounter extends React.Component {
    constructor(props) {
        super(props);
        //var count = parseInt(this.props.question.settings.listenings) - this.props.answer.listens;
        this.state = {count:null}

        this.decrement = this.decrement.bind(this);
    }

    decrement() {
        if (this.props.question.settings.retries=='unlimited') return;
        var count = this.state.count;
        this.props.answer.checks = this.props.answer.checks + 1;
        this.setState({count:count-1});
    }

    render() {
        if (this.props.question.settings.retries=='unlimited')
            return (<></>)
        return (
            <div>
                {this.state.count+" "+this.props.type+((this.state.count==1)?"":"s")+" remaining"}
            </div>
        )
    }
}

class LACounter extends React.Component {
    constructor(props) {
        super(props);
        //var count = parseInt(this.props.question.settings.listenings) - this.props.answer.listens;
        this.state = {count:null}

        this.decrement = this.decrement.bind(this);
    }

    decrement() {
        if (this.props.question.settings.listenings=='unlimited') return;
        var count = this.state.count;
        this.props.answer.listens = this.props.answer.listens + 1;
        this.setState({count:count-1});
    }

    render() {
        if (this.props.question.settings.listenings=='unlimited')
            return (<></>)
        return (
            <div>
                {this.state.count+" "+this.props.type+((this.state.count==1)?"":"s")+" remaining"}
            </div>
        )
    }
}

class AssignmentNav extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <Logo/>
                <QuestionList changeQuestion={this.props.changeQuestion} selected={this.props.selected} questions={this.props.questions}/>
            </div>
        )
    }
}

//question list
class QuestionList extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (<div className="student-sidebar">
            {this.props.questions.map((item,index)=>{
                return <div className={index==this.props.selected?"qlist-active":"qlist"} key={"question-number"+index}>
                            <div className="question-item" id={"fuckoff-"+index} style={{flex:1,padding:'3px'}} onClick={()=>{this.props.changeQuestion(index)}}>
                                <div>{"Q"+(index+1)}</div>
                                <div data-tooltip-id={"question-type-tooltip-"+index} data-tooltip-content={item.type} className="abbreviation">{abbreviation(item)}</div>
                            </div>
                            <Tooltip opacity={1} variant="info" id={"question-type-tooltip-"+index}/>
                        </div>
            })}
        </div>)
    }
}