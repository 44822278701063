import React, { useEffect } from 'react';
import { useStytch, useStytchSession } from '@stytch/react';
import { useNavigate, useParams } from 'react-router-dom';

export default function Authenticate() {
  const stytchClient = useStytch();
  const { session } = useStytchSession();
  const navigate = useNavigate()

  console.log('got here')

  useEffect(() => {
    if (session) {
     // The user is authenticated. You can redirect the user to their authenticated experience
     navigate('/courses');
    } else {
      const token = new URLSearchParams(window.location.search).get('token');
      if (!token) {
        navigate('/login');
        return;
      }
      stytchClient.magicLinks.authenticate(token, {
        session_duration_minutes: 60 * 24,
      });
    }
  }, [stytchClient, session]);

  return <div>Loading</div>;
};