import * as lib from './../../lib/lib.js';

export default function melodyInputStaff(id,params) {
	this.params = params;
    var container = document.getElementById(id);
	container.style.position = 'relative';
    var width;

	this.type = 'Melodic Dictation';

	this.appendAll = function() {
		//append elements in the staff
		//eligible elements are
		//	barlines
		//	rests
		//	notes
		//	key signature
		//	time signature
		//	clef
		this.whole.append(this.staffClef);
		this.whole.append(this.timeSignature);
		for (var i=0; i<this.measures.length; i++) {
			for (var j=0; j<this.measures[i].obs.length; j++) {
				if (this.measures[i].obs[j].note) this.whole.append(this.measures[i].obs[j].note)
				if (this.measures[i].obs[j].rest) this.whole.append(this.measures[i].obs[j].rest)
			}
			if (i < (this.measures.length-1))
				this.whole.append(this.barlines[i].barline);
			else
				this.whole.append(this.endLineGroup);
		}

		//append cover
		this.whole.append(this.cr);
	}

	this.clefSpace = function() {
		if (this.staffClef) return this.staffClef.getBBox().width;
		else return 0;
	}
	this.clefRight = function() {
		if (!this.staffClef) return 0;
		var bb = this.staffClef.getBBox();
		return bb.x+bb.width;
	}

	//autosaver function, initializes with this.save(), then checks every 5 seconds if any changes have been made
	this.autosaver = function(callback) { //pass callback in here
		var initial = this.save();
		var self = this;
		window.setInterval(
			function() {
				var current = self.save();
				if (!lib.objCompare(initial,current)) {
					console.log('updated');
					callback(current);
					initial = current;
				}
			}, 1000
		)
	}

	//note highlighter
	this.highlightNotes = function(ary) {
		for (var i=0; i<ary.length; i++) {
			var mb = ary[i].split(',');
			var m = parseInt(mb[0]);
			var b = parseInt(mb[1]);
			var nt = this.measures[m].obs[b];
			nt.note.attr({fill:'#E60000'});
			if (nt.stem)
				nt.stem.attr({fill:'#E60000'});
			if (nt.flag)
				nt.flag.attr({fill:'#E60000'});
		}
	}

	this.unhighlightNotes = function(ary) {
		for (var i=0; i<ary.length; i++) {
			var mb = ary[i].split(',');
			var m = parseInt(mb[0]);
			var b = parseInt(mb[1]);
			var nt = this.measures[m].obs[b];
			nt.note.attr({fill:'black'});
			if (nt.stem)
				nt.stem.attr({fill:'black'});
			if (nt.flag)
				nt.flag.attr({fill:'black'});
		}
	}

	//measure highlighter
	this.highlightMeasure = function(mIndex) {
		var measure = this.measures[mIndex];
		var left = measure.left-26;
		var right = measure.right-1;
		this.currentHighlight = this.whole.rect(left,0,right-left,4*17.75).attr({opacity:0.4,fill:'#E60000'});
	}

	this.unhighlightMeasure = function() {
		if (this.currentHighlight) this.currentHighlight.remove();
		delete this.currentHighlight;
	}

	var ERRORS;
	this.createErrorLists = function(list,yOffset) {
		this.clearErrors(); //clears out error divs
		this.DB.whole.attr({display:'none'});
		this.disabled = true;

		list.sort(function(a,b) {return 1000*(a.measure - b.measure) + (a.iBeat - b.iBeat)})

		var prnt = this.container.parentElement;
		var rec = prnt.getElementsByClassName('recunit')[0];
		var inst = document.getElementById(id+'-instructions');
		var vid = prnt.getElementsByClassName('vidunit')[0];

		var recHeight = rec ? rec.getBoundingClientRect().height : 0;
		var instHeight = inst ? inst.getBoundingClientRect().height : 0;
		var vidHeight = vid ? 300 : 0;
		var yOffset = recHeight + instHeight + vidHeight;
		
		ERRORS = [];

		this.elid = lib.uniqueID();
		
		//put stuff in them
		var eList;
		var ndv;
		var index;
		var type;
		for (var i=0; i<list.length; i++) {
			var mb = list[i];
			var meas = mb.measure;
			var beat = mb.beat;
			var iBeat = mb.iBeat;
			var stf = '';
			if (!this.measures[meas].obs[iBeat]) continue; //isnt one
			//if (missingBeat(GS.MEASURES[meas][stf],mb.beat)) {
			//	continue;
			//}
			var x = this.measures[meas].obs[iBeat].x;
			var mbi = meas+'|'+beat;
			eList = document.getElementById('e'+this.elid+mbi);
			if (!eList) {
				var ediv = document.createElement('div');
				ediv.className = 'errorList';
				ediv.id = 'e'+this.elid+mbi;
				this.container.appendChild(ediv);
				eList = ediv;
				new this.makeErrorButton(x,eList,meas,beat);
			}
			
			var yAdjust = 15;
			var svgPoint = this.paper.node.createSVGPoint();
			svgPoint.x = x;
			svgPoint.y = (8*17.75 + 15);
			var xey = svgPoint.matrixTransform(this.whole.node.getCTM());
			eList.style.left = (20+xey.x)+'px'; //20 is from padding-left
			eList.keepLeft = (20+xey.x);
			eList.style.top = (xey.y+yAdjust+yOffset)+'px'; //y from matrix, yAdjust is custom, yOffset is height of instructions and recording

			if (ERRORS.indexOf(eList)==-1)
				ERRORS.push(eList);
			ndv = document.createElement('div');
			ndv.className = 'errorItem';
			eList.appendChild(ndv);
			ndv.innerHTML = list[i].text;
		}
	}

	var hideAllErrorButtons = function() {
		var ary = dis.errorButtons;
		for (var i=0; i<ary.length; i++)
			ary[i].hide();
	}
	this.hideAllErrorButtons = hideAllErrorButtons;

	this.clearErrors = function() {
		this.disabled = false;
		this.made = [];
		var errorList = this.container.getElementsByClassName('errorList');
		while(errorList.length>0) {
			this.container.removeChild(errorList[0]);
		}
		//remove buttons
		for (var i=0; i<this.errorButtons.length; i++)
			this.errorButtons[i].remove();
		this.errorButtons = [];
	}

	this.made = [];
	this.errorButtons = [];
	this.makeErrorButton = function(x,ele,meas,beat) {
		if (dis.made.indexOf(x)!=-1) return;
		dis.made.push(x);
		
		var y = 8*17.75;
		var bg = dis.whole.circle(x,y,15).attr({fill:lib.rnGrad2,strokeWidth:1});
		var bgt = dis.whole.text(x,y+8,'!').attr({fontSize:'26px',fontWeight:'bold',fill:'#E60000'});
		bgt.transform('t'+(-bgt.getBBox().width/2)+' 0');
		var bgc = dis.whole.circle(x,y,15).attr({opacity:0});
		bgc.node.id="errorBtn";
		var eg = dis.whole.group(bg,bgt,bgc);
		bg.tog = false;
		eg.click(
			function() {
				bg.tog=!bg.tog;
				switch(bg.tog) {
					case true:
						dis.hideAllErrorButtons();
						//GS.BS.closePrompts();
						ele.style.display='';
						bg.attr({stroke:'black'});
						bg.tog = true;
						break;
					case false:
						ele.style.display='none';
						bg.attr({stroke:'none'});
						break;
				}
			}
		)
		eg.transform('t16 0');

		//accessibility
		eg.node.tabIndex = "0";
		eg.node.ariaLabel = "Error button, measure "+(meas+1)+", beat "+beat+", press e to hear the errors";
		eg.node.onkeyup = function(m) {
			if (m.key=='e') {
				bg.tog=!bg.tog;
				switch(bg.tog) {
					case true:						
						//announce errors to screenreader
						var elefart = document.getElementById(dis.container.id+'-notechange');	
						var eText = 'Error list';
						for (var z=0; z<ele.children.length; z++) {
							eText+=(', error '+(z+1)+', ');
							eText+=ele.children[z].innerHTML;
						}
						elefart.innerHTML = eText;

						dis.hideAllErrorButtons();
						//GS.BS.closePrompts();
						ele.style.display='';
						bg.attr({stroke:'black'});
						bg.tog = true;
						break;
					case false:
						ele.style.display='none';
						bg.attr({stroke:'none'});
						break;
				}
			}
		}
		
		this.remove = function() {
			eg.remove();
		}
		
		this.hide = function() {
			ele.style.display = 'none';
			bg.attr({stroke:'none'});
			bg.tog = false;
		}
		
		this.tog = function() {
			return bg.tog;
		}
		
		dis.errorButtons.push(this);
	}

	/*this.displayErrors = function(errors) {
		this.disabled = true;
		this.DB.whole.attr({display:'none'});
		this.errorRects = [];
		var ems = [];
		var xs = [this.measures[0].left-5];
		for (var i=0; i<this.barlines.length; i++) {
			xs.push(this.barlines[i].x);
		}
		xs.push(this.lines[5].getBBox().x);
		for (var i=0; i<errors.length; i++) {
			if (errors[i].meas==undefined) continue;
			if (ems.indexOf(errors[i].meas)==-1) {
				this.errorRects.push(this.whole.rect(xs[errors[i].meas],0,xs[errors[i].meas+1]-xs[errors[i].meas],17.75*4).attr({fill:'red',opacity:0.1}));
				ems.push(errors[i].meas);
			}
		}
	}

	this.clearErrors = function() {
		this.disabled = false;
		this.DB.whole.attr({display:''});
		for (var i=0; i<this.errorRects.length; i++)
			this.errorRects[i].remove();
		this.errorRects = [];
	}*/
	
	//notes
	var hnotes1 = ['E6','D6','C6','B5','A5','G5','F5','E5','D5','C5','B4','A4','G4','F4','E4','D4','C4','B3','A3','G3','F3'];
	var hnotes2 = ['G4','F4','E4','D4','C4','B3','A3','G3','F3','E3','D3','C3','B2','A2','G2','F2','E2','D2','C2','B1','A1'];
	var hnotes3 = ['F5','E5','D5','C5','B4','A4','G4','F4','E4','D4','C4','B3','A3','G3','F3','E3','D3','C3','B2','A2','G2'];
	
	//id
	this.id = id;
	
	//object with answers in it?
	this.answers = {};
	
	//save container div
	this.container = container;
	
	//dis variable for use inside functions
	var dis = this;
	
	this.timeSpace = function() { //width of tsig
		return this.timeSignature.getBBox().width;
	}
	this.timeRight = function() { //right of tsig, where the notes start
		var bb = this.timeSignature.getBBox();
		return bb.x+bb.width;
	}
	this.timeLeft = function() { //right of tsig, where the notes start
		var bb = this.timeSignature.getBBox();
		return bb.x;
	}
	//move to right of key signature
	this.moveTSIG = function() {
		var sigRight;
		if (this.keyAx.length==0) {
			sigRight = this.clefRight();
		} else {
			var lx = this.keyAx[this.keyAx.length-1];
			var bb = lx.getBBox();
			sigRight = bb.x + bb.width;
		}
		
		var dx = this.timeLeft() - sigRight - 10;
		lib.moveEle(this.timeSignature,-dx,0);
	}
	
	//BARLINES==========================================================
	this.drawBarline = function(x) { //after last chord
		var bl = new barline(x);
		this.barlines.push(bl);
		return bl;
	}
	
	var barlineMARGIN = 25;
	var barline = function(x,w) {
		var sw = w ? w : 1;
		this.x = x;
		this.barline = dis.whole.line(x,0,x,17.75*4).attr({stroke:'black',strokeWidth:sw});
		this.remove = function() {
			this.barline.remove();
		}
	}
	
	//MEASURES ROUTINES=================================================
	var insertMeasureBeat = function(ary,index,type,staff) {
		var eMB = ary[index];
		var ex = eMB.x;
		//new beat
		var nb = eMB.beat + lib.rBeat(dis,eMB.dur);
		if (Math.abs( nb - (Math.floor(nb)+0.5) ) < 0.05)
			nb = Math.floor(nb) + 0.5;
		if (Math.abs( nb - (Math.floor(nb)+0.333) ) < 0.05)
			nb = Math.floor(nb) + 0.333;
		//new x
		var nx = ex + lib.noteW(eMB.dur) + lib.newX(eMB.dur);
		
		var mb = new measureBeat(type,nx,nb,eMB.measure);
		
		ary.splice(index+1,0,mb);
	}
	this.insertMeasureBeat = insertMeasureBeat;
	
	var removeMeasureBeat = function(ary,index,type) {
		//remove next beat(s) depending on rhythm of existing one
		//for now try removing one quarter note
		ary.splice(index+1,1);
	}
	this.removeMeasureBeat = removeMeasureBeat;
	
	var measureBeat = function(dur,x,beat,meas,iBeat) {
		this.dur = dur;
		this.beat = beat;
		this.measure = meas;
		this.iBeat = iBeat;
		this.x = x;
		this.voice = 1;
	}

	var getDuration = function(ary) {
		var dur = 0;
		for (var i=0; i<ary.length; i++) {
			dur+=lib.rBeat(dis,ary[i].dur);
		}
		return dur;
	}
	
	var measureObj = function(ary,x,meas,bstart) {
		//ary is array of durations
		this.obs = [];
		
		this.left = x;

		if (dis.params.pickup) {
			this.duration = getDuration(ary);
		} else {
			this.duration = parseInt(dis.params.tsig.split('/')[0]);
		}
		
		//track x values
		var beat = bstart;
		var nobj;
		for (var i=0; i<ary.length; i++) {
			nobj = ary[i];
			this.obs.push(new measureBeat(nobj.dur,x,beat,meas,i));
			
			beat+=lib.rBeat(dis,nobj.dur);
			beat = lib.quantBeat(beat);
			
			//update x
			x += lib.noteW(nobj.dur) + lib.newX(nobj.dur);
		}
		
		//put cx on
		for (var i=1; i<this.obs.length; i++) {
			this.obs[i-1].cx = ( this.obs[i-1].x + this.obs[i].x ) / 2;
		}
		
		this.x = function() {
			return x;
		}
		
		this.right = x;
	}
	//==================================================================
	
	var wholeMeasure = function() {
		var tsig = dis.params.tsig;
		switch(tsig) {
			case '2/2':
				return [{dur:'2',name:'rest'},{dur:'2',name:'rest'}];
				break;
			case '3/2':
				return [{dur:'2',name:'rest'},{dur:'2',name:'rest'},{dur:'2',name:'rest'}];
				break;
			case '4/2':
				return [{dur:'1',name:'rest'},{dur:'1',name:'rest'}];
				break;
			case '2/4':
				return [{dur:'2',name:'rest'}];
				break;
			case '3/4':
				return [{dur:'2.',name:'rest'}];
				break;
			case '4/4':
				return [{dur:'1',name:'rest'}];
				break;
			case '6/4':
				return [{dur:'1.',name:'rest'}];
				break;
			case '3/8':
				return [{dur:'4.',name:'rest'}];
				break;
			case '6/8':
				return [{dur:'4.',name:'rest'},{dur:'4.',name:'rest'}];
				break;
			case '9/8':
				return [{dur:'4.',name:'rest'},{dur:'4.',name:'rest'},{dur:'4.',name:'rest'}];
				break;
			case '12/8':
				return [{dur:'2.',name:'rest'},{dur:'2.',name:'rest'}];
				break;
		}
	}

	var findBeatStart = function() {
		var beats = 0;
		for (var i=0; i<dis.pickup.length; i++) {
			beats+=lib.rBeat(dis,dis.pickup[i].dur);
		}
		beats = lib.quantBeat(beats);
		var ts = parseInt(dis.params.tsig.split('/')[0]);

		return 1 + (ts - beats);
	}
	
	//INTERACTIVITY
	//var dcxs = [];
	this.findMB = function(cp) {
		//for (var i=0; i<dcxs.length; i++) {
		//	dcxs[i].remove();
		//}
		var x = cp.x;
		var ms = this.measures; //array of measures
		var lastMB = {}; //last measure-beat
		var meas;
		for (var i=0; i<ms.length; i++) {
			meas = ms[i]; //a measure
			//lastMB.meas = i; //keep measure
			for (var j=0; j<meas.obs.length; j++) { //going through the treble staff measure-beats
				//lastMB.beat = j; //save beat
				//lastMB.x = meas.obs[j].x; //save x
				//lastMB.dur = meas.obs[j].dur; //save duration
				//dcxs.push(dis.whole.rect(meas.obs[j].cx,0,1,5).attr({fill:'red'}))
				if (meas.obs[j].cx > x) return meas.obs[j]; //return measure-beat
			}
		}
		return meas.obs[meas.obs.length-1];
	}
	
	this.removeTester = function() {
		if (testNote) {
			if (testNote.stem)
				testNote.stem.remove();
			if (testNote.flag)
				testNote.flag.remove();
			if (testNote.dot)
				testNote.dot.remove();
			if (testNote.axe)
				testNote.axe.remove();
			if (testNote.t3)
				testNote.t3.remove();
			if (testNote.ledgers)
				for (var k=0; k<testNote.ledgers.length; k++)
					testNote.ledgers[k].remove();
			testNote.remove();
		}
	}
	
	var copyObject = function(obj) {
		var nbj = {};
		for (var prop in obj) {
			nbj[prop] = obj[prop];
		}
		return nbj;
	}

	var testNote;
	this.placeTester = function(obj,name) {
		var nt, dur;
		nt = name;
		dur = '4';
		
		//var axeIM = axeInMeas(obj);
		
		//remove existing
		this.removeTester();
		
		//name gives y
		nt = nt.replace('\u0231','');
		nt = nt.replace('♯','');
		nt = nt.replace('bb','');
		nt = nt.replace('x','');
		
		var x = obj.x;
		var lnsp = this.lnsp;
		var base = 14*lnsp/2;
		var ndx = nts.indexOf(nt);
		var lndx = ndx;
		var iSv = ndx;
		ndx *= lnsp/2;
		
		var sH = 40;
		var sW = 2;
		x-=4; //adjust x
		var vc = 4;
		switch(dur) {
			case '4':
				//x-=4;
				testNote = this.whole.path(lib.closedPath).transform('t'+(x-150)+' '+(base-ndx)+' s0.07 -0.07'); //notehead
				break;
		}
		
		if (lndx<5 || lndx>15) this.drawLedger(base,lndx,null,x-123,testNote); //depends on duration
		
		testNote.attr({opacity:0.5});
		this.whole.prepend(testNote);
	}

	this.placeNote = function(obj,keep,noStem) {
		if (!obj.name) return;
		var nt, dur;
		nt = obj.name;
		if (!keep)
			dur = '4'; //dummy note is quarter
		else
			dur = obj.dur;
		
		var axeIM = axeInMeas(obj);
		
		//remove existing
		this.removeTester();
		
		var ntSv = nt;
		if (axeIM) {
			if (axeIM!='n')
				ntSv = ntSv[0] + axeIM + ntSv[ntSv.length-1];
			else
				ntSv = ntSv[0] + ntSv[ntSv.length-1];
		}

		obj.name = ntSv;
		
		//name gives y
		nt = nt.replace('\u0231','');
		nt = nt.replace('♯','');
		nt = nt.replace('bb','');
		nt = nt.replace('x','');
		
		var x = obj.x;
		var lnsp = this.lnsp;
		var base = 14*lnsp/2;
		var ndx = nts.indexOf(nt);
		var midi = mids[ndx];
		var lndx = ndx;
		var iSv = ndx;
		ndx *= lnsp/2;
		
		var sH = 40;
		var sW = 2;
		var note;
		x-=4; //adjust x
		var vc = 4;
		switch(dur) {
			case '16':
				sH = 55;
				note = this.whole.path(lib.closedPath).transform('t'+(x-150)+' '+(base-ndx)+' s0.07 -0.07'); //notehead
				obj.stem = (iSv>9) ? this.whole.rect(x+3.05,base-ndx+1.75,sW,sH) : this.whole.rect(x+23.95,base-ndx-sH-1.95,sW,sH);
				var flag = !(iSv>9) ? this.whole.path(lib.sixFlagPath).transform('t'+(x-65)+' '+(base-ndx-sH-318.75+782)+' s0.06 -0.06') : this.whole.path(lib.sixFlagPath).transform('t'+(x-86)+' '+(base-ndx-331+800)+' s0.06 0.06');
				obj.flag = flag;
				this.whole.prepend(obj.stem);
				this.whole.prepend(obj.flag);
				break;
			case 's16':
				sH = 55;
				note = this.whole.path(lib.closedPath).transform('t'+(x-150)+' '+(base-ndx)+' s0.07 -0.07'); //notehead
				obj.stem = (iSv>9) ? this.whole.rect(x+3.05,base-ndx+1.75,sW,sH) : this.whole.rect(x+23.95,base-ndx-sH-1.95,sW,sH);
				var flag = !(iSv>9) ? this.whole.path(lib.sixFlagPath).transform('t'+(x-65)+' '+(base-ndx-sH-318.75+782)+' s0.06 -0.06') : this.whole.path(lib.sixFlagPath).transform('t'+(x-86)+' '+(base-ndx-331+800)+' s0.06 0.06');
				obj.flag = flag;
				var x3 = x+10;
				var y3 = base - ndx - 15;//(iSv>9) ? base-ndx-15 : base-ndx-sH;
				var t3 = this.whole.text(x3,y3,'6').attr({fontStyle:'italic'});
				this.whole.prepend(t3);
				obj.t3 = t3;
				this.whole.prepend(obj.stem);
				this.whole.prepend(obj.flag);
				break;
			case '8':
				note = this.whole.path(lib.closedPath).transform('t'+(x-150)+' '+(base-ndx)+' s0.07 -0.07'); //notehead
				obj.stem = (iSv>9) ? this.whole.rect(x+3.05,base-ndx+1.75,sW,sH) : this.whole.rect(x+23.95,base-ndx-sH-1.95,sW,sH);
				var flag = (iSv>9) ? this.whole.path(lib.eighthFlagPath).transform('t'+(x-114.9)+' '+(base-ndx-368.75+sH)+' s0.06 -0.06') : this.whole.path(lib.eighthFlagPath).transform('t'+(x-94)+' '+(base-ndx-379)+' s0.06 0.06');
				obj.flag = flag;
				this.whole.prepend(obj.stem);
				this.whole.prepend(obj.flag);
				break;
			case '8.':
				note = this.whole.path(lib.closedPath).transform('t'+(x-150)+' '+(base-ndx)+' s0.07 -0.07'); //notehead
				obj.stem = (iSv>9) ? this.whole.rect(x+3.05,base-ndx+1.75,sW,sH) : this.whole.rect(x+23.95,base-ndx-sH-1.95,sW,sH);
				var flag = (iSv>9) ? this.whole.path(lib.eighthFlagPath).transform('t'+(x-114.9)+' '+(base-ndx-368.75+sH)+' s0.06 -0.06') : this.whole.path(lib.eighthFlagPath).transform('t'+(x-94)+' '+(base-ndx-379)+' s0.06 0.06');
				obj.flag = flag;
				var dot = this.whole.circle(x+34,base-ndx-9,3);
				if (iSv%2) dot.transform('t0 9');
				obj.dot = dot;
				this.whole.prepend(dot);
				this.whole.prepend(obj.stem);
				this.whole.prepend(obj.flag);
				break;
			case 't8':
				note = this.whole.path(lib.closedPath).transform('t'+(x-150)+' '+(base-ndx)+' s0.07 -0.07'); //notehead
				obj.stem = !(vc%2) ? this.whole.rect(x+3.05,base-ndx+1.75,sW,sH) : this.whole.rect(x+23.95,base-ndx-sH-1.95,sW,sH);
				var flag = !(vc%2) ? this.whole.path(lib.eighthFlagPath).transform('t'+(x-114.9)+' '+(base-ndx-368.75+sH)+' s0.06 -0.06') : this.whole.path(lib.eighthFlagPath).transform('t'+(x-94)+' '+(base-ndx-379)+' s0.06 0.06');
				obj.flag = flag;
				var x3 = x+10;
				var y3 = base-ndx-15;
				var t3 = this.whole.text(x3,y3,'3').attr({fontStyle:'italic'});
				this.whole.prepend(t3);
				obj.t3 = t3;
				this.whole.prepend(obj.stem);
				this.whole.prepend(obj.flag);
				if (iSv%2) t3.transform('t0 -9');
				break;
			case 'd8':
				note = this.whole.path(lib.closedPath).transform('t'+(x-150)+' '+(base-ndx)+' s0.07 -0.07'); //notehead
				obj.stem = (iSv>9) ? this.whole.rect(x+3.05,base-ndx+1.75,sW,sH) : this.whole.rect(x+23.95,base-ndx-sH-1.95,sW,sH);
				var flag = (iSv>9) ? this.whole.path(lib.eighthFlagPath).transform('t'+(x-114.9)+' '+(base-ndx-368.75+sH)+' s0.06 -0.06') : this.whole.path(lib.eighthFlagPath).transform('t'+(x-94)+' '+(base-ndx-379)+' s0.06 0.06');
				obj.flag = flag;
				var x3 = x+10;
				var y3 = base - ndx - 15;//(iSv>9) ? base-ndx-15 : base-ndx-sH;
				var t3 = this.whole.text(x3,y3,'2').attr({fontStyle:'italic'});
				this.whole.prepend(t3);
				obj.t3 = t3;
				this.whole.prepend(obj.stem);
				this.whole.prepend(obj.flag);
				//if (iSv%2) t3.transform('t0 -9');
				break;
			case 'f8':
				note = this.whole.path(lib.closedPath).transform('t'+(x-150)+' '+(base-ndx)+' s0.07 -0.07'); //notehead
				obj.stem = (iSv>9) ? this.whole.rect(x+3.05,base-ndx+1.75,sW,sH) : this.whole.rect(x+23.95,base-ndx-sH-1.95,sW,sH);
				var flag = (iSv>9) ? this.whole.path(lib.eighthFlagPath).transform('t'+(x-114.9)+' '+(base-ndx-368.75+sH)+' s0.06 -0.06') : this.whole.path(lib.eighthFlagPath).transform('t'+(x-94)+' '+(base-ndx-379)+' s0.06 0.06');
				obj.flag = flag;
				var x3 = x+10;
				var y3 = base - ndx - 15;//(iSv>9) ? base-ndx-15 : base-ndx-sH;
				var t3 = this.whole.text(x3,y3,'4').attr({fontStyle:'italic'});
				this.whole.prepend(t3);
				obj.t3 = t3;
				this.whole.prepend(obj.stem);
				this.whole.prepend(obj.flag);
				//if (iSv%2) t3.transform('t0 -9');
				break;
			case '4':
				//x-=4;
				note = this.whole.path(lib.closedPath).transform('t'+(x-150)+' '+(base-ndx)+' s0.07 -0.07'); //notehead
				if (!noStem) obj.stem = (iSv>9) ? this.whole.rect(x+3.05,base-ndx+1.75,sW,sH) : this.whole.rect(x+23.95,base-ndx-sH-1.95,sW,sH);
				this.whole.prepend(obj.stem);
				break;
			case 't4':
				//x-=4;
				note = this.whole.path(lib.closedPath).transform('t'+(x-150)+' '+(base-ndx)+' s0.07 -0.07'); //notehead
				if (!noStem) obj.stem = (iSv>9) ? this.whole.rect(x+3.05,base-ndx+1.75,sW,sH) : this.whole.rect(x+23.95,base-ndx-sH-1.95,sW,sH);
				this.whole.prepend(obj.stem);
				var x3 = x+10;
				var y3 = base - ndx - 15;//(iSv>9) ? base-ndx-15 : base-ndx-sH;
				var t3 = this.whole.text(x3,y3,'3').attr({fontStyle:'italic'});
				this.whole.prepend(t3);
				obj.t3 = t3;
				break;
			case 'd4':
				//x-=4;
				note = this.whole.path(lib.closedPath).transform('t'+(x-150)+' '+(base-ndx)+' s0.07 -0.07'); //notehead
				if (!noStem) obj.stem = (iSv>9) ? this.whole.rect(x+3.05,base-ndx+1.75,sW,sH) : this.whole.rect(x+23.95,base-ndx-sH-1.95,sW,sH);
				this.whole.prepend(obj.stem);
				var x3 = x+10;
				var y3 = base - ndx - 15;//(iSv>9) ? base-ndx-15 : base-ndx-sH;
				var t3 = this.whole.text(x3,y3,'2').attr({fontStyle:'italic'});
				this.whole.prepend(t3);
				obj.t3 = t3;
				break;
			case '4.':
				note = this.whole.path(lib.closedPath).transform('t'+(x-150)+' '+(base-ndx)+' s0.07 -0.07'); //notehead
				obj.stem = (iSv>9) ? this.whole.rect(x+3.05,base-ndx+1.75,sW,sH) : this.whole.rect(x+23.95,base-ndx-sH-1.95,sW,sH);
				//var bb = note.getBBox();
				var dot = this.whole.circle(x+34,base-ndx-9,3);
				if (iSv%2) dot.transform('t0 9');
				obj.dot = dot;
				this.whole.prepend(dot);
				this.whole.prepend(obj.stem);
				break;
			case '2':
				note = this.whole.path(lib.openPath).transform('t'+(x-160+sW-0.5)+' '+(base-ndx)+' s0.067 -0.067'); //notehead
				if (!noStem) obj.stem = (iSv>9) ? this.whole.rect(x+3.05,base-ndx+1.75,sW,sH) : this.whole.rect(x+23.95,base-ndx-sH-1.95,sW,sH);
				this.whole.prepend(obj.stem);
				break;
			case '2.':
				note = this.whole.path(lib.openPath).transform('t'+(x-160+sW-0.5)+' '+(base-ndx)+' s0.067 -0.067'); //notehead
				obj.stem = (iSv>9) ? this.whole.rect(x+3.05,base-ndx+1.75,sW,sH) : this.whole.rect(x+23.95,base-ndx-sH-1.95,sW,sH);
				//var bb = note.getBBox();
				var dot = this.whole.circle(x+34,base-ndx-9,3);
				if (iSv%2) dot.transform('t0 9');
				obj.dot = dot;
				this.whole.prepend(dot);
				this.whole.prepend(obj.stem);
				break;
			case '1':
				note = this.whole.path(lib.wholePath).transform('t'+(x-233)+' '+(base-ndx)+' s0.0635 -0.0635');
				break;
			case '1.':
				note = this.whole.path(lib.wholePath).transform('t'+(x-233)+' '+(base-ndx)+' s0.0635 -0.0635');
				var dot = this.whole.circle(x+37.5,base-ndx-9,3);
				if (iSv%2) dot.transform('t0 9');
				obj.dot = dot;
				this.whole.prepend(dot);
				break;
		}
		
		if (lndx<5 || lndx>15) this.drawLedger(base,lndx,null,x-123,obj); //depends on duration
		
		//note.midi = midi;
		//note.name = ntSv;
		note.x = obj.x;

		obj.y = base-ndx;
		obj.i = iSv;

		var bb = note.getBBox();
		obj.by = bb.y;
		obj.height = bb.height;
		obj.width = bb.width;
		
		obj.type = 'note';

		note.node.onfocus = function() {
			note.attr({fill:'rgb(99, 21, 255)'});
		}
		note.node.onblur = function() {
			note.attr({fill:'black'});
		}

		obj.staff = this.params.clef;
		
		//keep it
		note.keep = keep;
		
		if (!keep) {
			if (obj.stem)
				obj.stem.attr({opacity:0});
			if (obj.flag)
				obj.flag.attr({opacity:0});
			if (obj.t3)
				obj.t3.attr({opacity:0});
			note.attr({opacity:0.5});
			if (note.dot)
				note.dot.attr({opacity:0.5});
		}

		//put in tab order
		note.node.tabIndex = "0";
		note.node.ariaLabel = '';//nType(obj.dur)+" note, "+accessibleName(obj.name)+", measure "+(obj.measure+1)+", beat "+(obj.beat);
		
		this.whole.prepend(note);
		
		//make the axeMenu, keep the obj
		if (keep) {
			obj.axeMenu = new axeMenu(obj);
			//note.obj = {};
			//note.obj.dur = obj.dur;
			//note.obj.note = ntSv;
			//obj.note = ntSv;
			//note.obj.index = obj.index;
			//note.obj.MB = copyObject(MB);
			
			note.move = function(dx) {
				lib.moveEle(note,dx,0);
				if (obj.stem)
					lib.moveEle(obj.stem,dx,0);
			}
		}
		
		if (!keep) {
			testNote = note;
			testNote.obj = obj;
		} else {
			obj.note = note;
		}
	}
	
	this.axeOpen = function(mb) {
		//var note = this.measures[mb.measure].obs[mb.iBeat].note;
		mb.axeMenu.show();
	}
	
	var drawAccidental = function(mb,type) {
		if (mb.axe) mb.axe.remove();
		var axe;
		var adds=0;
		if (mb.ledgers)
			if (mb.ledgers.length!=0) adds-=3;
		var y = mb.y;
		var x = mb.x;
		x-=4;
		switch(type) {
			case '\u0231':
				axe = S.path(lib.flatPath).transform('t'+(x+12-106)+' '+(y-160.5)+' s0.062 -0.062');
				//note.midi--;
				break;
			case '♯':
				axe = S.path(lib.sharpPath).transform('t'+(x+adds-146)+' '+y+' s0.062 -0.062');
				//note.midi++;
				break;
			case 'n':
				axe = S.path(lib.naturalPath).transform('t'+(x-90)+' '+y+' s0.062 -0.062');
				break;
			case 'bb':
				axe = S.path(lib.doubleflatPath).transform('t'+(x+32-125)+' '+(y-260.5-17.75*4.5)+' s0.32 0.32');
				break;
			case 'x':
				axe = S.path(lib.doublesharpPath).transform('t'+(adds+x+78-125)+' '+(y-306.875)+' s0.32 -0.32');
				break;
		}
		mb.axe = axe;
		axe.type = type;
		dis.whole.prepend(axe);
	}
	
	var axeHandle = function(mb,type) {
		var num = lib.retNum(mb.name);
		var base = lib.retBase(mb.name);
		if (type=='n') {
			mb.name = base+num;
		} else {
			mb.name = base+type+num;
		}
		if (mb.axe) {
			mb.axe.remove();
			delete mb.axe;
		}
		if (needDraw(mb,type))
			drawAccidental(mb,type);
	}
	
	var axeUpdate = function(mb) {
		if (mb.axe) {
			mb.axe.remove();
			delete mb.axe;
		}
		var num = lib.retNum(mb.name);
		var base = lib.retBase(mb.name);
		var nm = mb.name.replace(num,'').replace(base,'');
		var type = nm == '' ? 'n' : nm;
		if (needDraw(mb,type))
			drawAccidental(mb,type);
	}
	
	this.updateAxe = function(mb) {
		//go through the next notes til end of measure
		var ary = dis.measures[mb.measure].obs;
		for (var i=mb.iBeat+1; i<ary.length; i++) {
			if (ary[i].note) {
				axeUpdate(ary[i]);
			}
		}
	}
	
	var printNotes = function() {
		return;
		for (var i=0; i<dis.measures.length; i++) {
			var obs = dis.measures[i].obs;
			for (var j=0; j<obs.length; j++) {
				console.log(obs[j].snapNote?obs[j].snapNote.name:'');
			}
		}
	}
	
	var axeMenu = function(mb) {
		var xN = mb.x - 80;
		var yN = mb.y + 20;
		var vmrg = 5;
		var bw = 25;
		
		var num = lib.retNum(mb.name);
		var base = lib.retBase(mb.name);
		
		var rct = dis.whole.rect(mb.x+23,mb.y-60,24,155,5).attr({fill:'#E0E5E6',stroke:'black'});
		
		var axes = ['x','♯','n','\u0231','bb'];
		var axHov = function(rct,ax,i) {
			rct.node.id = 'pointer';
			var axr = dis.whole.group(ax);
			var axg = dis.whole.group(axr,rct);
			axg.hover(
				function() {axr.transform('s1.2');},
				function() {axr.transform('s1');}
			)
			
			axg.click(
				function() {
					if (mb.tieF||mb.tieB) return;
					if (mb.axe) {
						if (mb.axe.type==axes[i]) {hide(); return;}
					} else {
						if (mb.name.indexOf(axes[i])!=-1) {hide(); return;}
					}
					axeClick(axes[i]);
					printNotes();
				}
			)
			
			return axg;
		}
		
		var axeClick = function(type) {
			axeHandle(mb,type);
			hide();
			
			//update all following
			dis.updateAxe(mb);
			
			//remove rests
			dis.removeRests();
			
			//recalc display
			dis.recalculateDisplay();
		}
		
		var eles = [];
		
		//double sharp
		var yDraw;
		var doubleSharp = dis.whole.path(lib.doublesharpPath).transform('t'+(xN+79)+' '+(yN-373)+' s0.2 -0.2');
		var bb1 = doubleSharp.getBBox();
		yDraw = bb1.y-vmrg;
		var dsr = dis.whole.rect(bb1.x+bb1.width/2-bw/2,yDraw,bw,bb1.height+2*vmrg).attr({opacity:0});//,stroke:'black',fill:'none'});
		eles.push(axHov(dsr,doubleSharp,0));
		
		yDraw+=(bb1.height+2*vmrg);
		
		var sharp = dis.whole.path(lib.sharpPath).transform('t'+(xN-22)+' '+(yN-38)+' s0.032 -0.032');
		var bb2 = sharp.getBBox();
		var sr = dis.whole.rect(bb2.x+bb2.width/2-bw/2,yDraw,bw,bb2.height+2*vmrg).attr({opacity:0});//,stroke:'black',fill:'none'});
		eles.push(axHov(sr,sharp,1));
		
		yDraw+=(bb2.height+2*vmrg);
		
		var natural = dis.whole.path(lib.naturalPath).transform('t'+(xN+32)+' '+(yN-4)+' s0.032 -0.032');
		var bb3 = natural.getBBox();
		var nr = dis.whole.rect(bb2.x+bb2.width/2-bw/2,yDraw,bw,bb3.height+2*vmrg).attr({opacity:0});//,stroke:'black',fill:'none'});
		eles.push(axHov(nr,natural,2));
		
		yDraw+=(bb3.height+2*vmrg);
		
		var flat = dis.whole.path(lib.flatPath).transform('t'+(xN+29)+' '+(yN-122.5)+' s0.032 -0.032');
		var bb4 = flat.getBBox();
		var fr = dis.whole.rect(bb2.x+bb2.width/2-bw/2,yDraw,bw,bb4.height+2*vmrg).attr({opacity:0});//,stroke:'black',fill:'none'});
		eles.push(axHov(fr,flat,3));
		
		yDraw+=(bb4.height+2*vmrg);
		
		var doubleFlat = dis.whole.path(lib.doubleflatPath).transform('t'+(xN+40)+' '+(yN-272)+' s0.15 0.15');
		var bb5 = doubleFlat.getBBox();
		var dfr = dis.whole.rect(bb2.x+bb2.width/2-bw/2,yDraw,bw,bb5.height+2*vmrg).attr({opacity:0});//,stroke:'black',fill:'none'});
		eles.push(axHov(dfr,doubleFlat,4));
		
		var menu = dis.whole.group(rct);
		for (var z=0; z<eles.length; z++)
			menu.append(eles[z]);
		dis.whole.append(menu);
		
		//transform to middle of note
		menu.transform('t12 '+((mb.y+mb.height/2)-(menu.getBBox().y+menu.getBBox().height/2)));
		
		if (mb.i<3) lib.moveEle(menu,0,(3-mb.i)*dis.lnsp/2); //if too high
		
		this.remove = function() {
			menu.remove();
		}
		
		this.move = function(dx) {
			lib.moveEle(menu,dx,0);
		}
		
		this.show = function() {
			if (dis.axeMenuIsOpen)
				dis.axeMenuIsOpen.hide();
			menu.attr({display:''});
			dis.axeMenuIsOpen = this;
			dis.whole.append(menu);
			
			dis.removeTester();
		}
		this.hide = function() {
			menu.attr({display:'none'});
			dis.axeMenuIsOpen = null;
			if (dis.removeTupletIsOpen) {
				dis.removeTupletIsOpen.remove();
				dis.removeTupletIsOpen = null;
			}
		}
		var hide = this.hide;
		this.hide();
	}
	
	var axeInMeas = function(note) {
		var ary = dis.measures[note.measure].obs;
		var start = note.iBeat - 1;
		for (var i=start; i>-1; i--) {
			if (!ary[i]) continue;
			if (!ary[i].note) continue;
			if (ary[i].axe && ary[i].name[0]==note.name[0] && lib.retNum(ary[i].name)==lib.retNum(note.name))
				return ary[i].axe.type;
		}
		return false;
	}
	
	var needDraw = function(note,type) {
		//if (cI==0) return false;
		//need to draw the accidental?
		//draw it when:
		//	another accidental in measure, lib.keySig
		var key = dis.params.key.replace('♭','\u0231');
		var mode = dis.params.mode;
		var ksig = lib.keySig(key,mode)[0];
		var nt = note.name;
		var xim;
		switch(type) {
			case '\u0231': //flat
				//if note before it in measure with accidental
				xim = axeInMeas(note);
				if (xim) {
					switch(xim) {
						case '\u0231':
							//do nothing
							break;
						case '♯':
							return true;
							break;
						case 'n':
							return true;
							break;
						case 'bb':
							return true; //also need natural
							break;
						case 'x':
							return true; //not sure what else to do
							break;
					}
				} else {
					if (ksig.indexOf(lib.noNum(nt))!=-1) return false;
					else return true;
				}
				break;
			case '♯':
				//if note before it in measure with accidental
				xim = axeInMeas(note);
				if (xim) {
					switch(xim) {
						case '\u0231':
							return true;
							break;
						case '♯':
							//do nothing
							break;
						case 'n':
							return true;
							break;
						case 'bb':
							return true; //not sure what else to do
							break;
						case 'x':
							return true; //also need natural
							break;
					}
				} else {
					if (ksig.indexOf(lib.noNum(nt))!=-1) return false;
					else return true;
				}
				break;
			case 'bb':
				//if note before it in measure with accidental
				xim = axeInMeas(note);
				if (xim) {
					switch(xim) {
						case '\u0231':
							return true;
							break;
						case '♯':
							return true;
							break;
						case 'n':
							return true;
							break;
						case 'bb':
							//do nothing
							break;
						case 'x':
							return true;
							break;
					}
				} else {
					return true;
				}
				break;
			case 'x':
				//if note before it in measure with accidental
				xim = axeInMeas(note);
				if (xim) {
					switch(xim) {
						case '\u0231':
							return true;
							break;
						case '♯':
							return true;
							break;
						case 'n':
							return true;
							break;
						case 'bb':
							return true;
							break;
						case 'x':
							//do nothing
							break;
					}
				} else {
					return true;
				}
				break;
			case 'n':
				//if note before it in measure with accidental
				xim = axeInMeas(note);
				if (xim) {
					switch(xim) {
						case '\u0231':
							return true;
							break;
						case '♯':
							return true;
							break;
						case 'n':
							//do nothing
							break;
						case 'bb':
							return true; //not sure what else to do
							break;
						case 'x':
							return true; //not sure what else to do
							break;
					}
				} else {
					for (var z=0; z<ksig.length; z++) {
						if (ksig[z][0]==nt[0]) {
							return true;
						}
					}
					return false;
				}
				break;
		}
	}
	
	//this.ledgers = [];
	this.drawLedger = function(base,rand,dx,DX,mb) {
		var xsp = 50;
		var dx = dx ? xsp : 0;
		var delta = DX ? DX : 0;
		var lnsp = 17.75;
		var line = function(n,dir) {
			var yf = dir?(2-n):(8+n);
			var y = base-yf*lnsp;
			var lx = 120 + dx + delta;
			if (dx)
				var lw = 35;
			else
				var lw = 35;
			var lxr = lx + lw;
			if (mb.dur=='1') {
				lx-=3;
				lxr+=3;
			}
			var ll = S.line(lx,y,lxr,y).attr({stroke:'black'});
			if (!mb.ledgers) mb.ledgers = [];
			mb.ledgers.push(ll);
			//if (!dis.ledgers[cI])
			//	dis.ledgers[cI] = [];
			//dis.ledgers[cI].push(ll);
			dis.whole.prepend(ll);
		}
		
		if (rand<5) line(0,1);
		if (rand<3) line(1,1);
		if (rand<1) line(2,1);
		if (rand>15) line(0);
		if (rand>17) line(1);
		if (rand>19) line(2);
	}
	
	function tupMultiple(dur) {
		if (dur.indexOf('t')!=-1) return 3;
		if (dur.indexOf('d')!=-1) return 2;
		if (dur.indexOf('f')!=-1) return 4;
		if (dur.indexOf('s')!=-1) return 6;
	}
	
	function checkConversion(mb,cdur) {
		var dur = mb.dur;
		var meas = mb.meas;
		var tupM = tupMultiple(cdur);
		var mbDur = lib.rBeat(dis,dur);
		var tpDur = tupM*lib.rBeat(dis,cdur);
		var diff = Math.abs(mbDur-tpDur);
		if (diff<0.1) return true;
		return {error:'Cannot convert into tuplet.'}
	}
	
	function findRemainder(rem,meas) { //converting beats normally
		var durs = ['1','2','4','8','16'];
		var ts = dis.params.tsig;
		if (ts.indexOf('/8')!=-1) {
			durs = ['2.','4.','8','16'];
		}
		var ary = [];
		var prop = 0;
		var pdur;
		var pbeat;
		while(rem!=0) {
			if (!durs[prop]) break;
			pdur = durs[prop]; //proposed dur to put in array
			pbeat = lib.rBeat(dis,pdur);
			if (pbeat<=rem) {
				ary.push(pdur);
				rem-=pbeat;
			} else {
				prop++;
			}
		}
		ary=ary.sort(function(a,b){return parseInt(b)-parseInt(a);})
		return ary;
	}
	
	this.handleTupletChange = function(eis,nis,lastDur,newDur,ary,index) {
		var eDur = lastDur;
		var nDur = newDur;
		//var ary = this.measures[MB.meas].obs;
		//var index = MB.beat;
		var eMB = ary[index];
		var eNum = lib.rBeat(this,eDur);
		var nNum = lib.rBeat(this,nDur);
		
		var ts = this.params.tsig;
		
		if (eNum > nNum) { //existing is greater than new duration
			//if both tuplets, see if they play well together
			if (eis&&nis) {
				//pure tuplets only
			}
			
			//if existing is tuplet and new is not, no allow
			if (eis&&!nis) {
				//not allowed to do anything
			}
			
			//if existing is not tuplet and new is tuplet
			if (!eis&&nis) {
				var tN = tupMultiple(nDur);
				
				ary[index].dur = nDur;
				//convert one beat to tuplet
				var beatGroup = tN*lib.rBeat(this,nDur);
				beatGroup = Math.round(10*beatGroup)/10;
				var numToAdd = Math.round(beatGroup / nNum) - 1;
				for (var i=0; i<numToAdd; i++) {
					this.insertMeasureBeat(ary,index+i,nDur);
				}
				var rem = findRemainder(eNum - beatGroup,eMB.measure);
				for (var i=0; i<rem.length; i++) {
					this.insertMeasureBeat(ary,index+i+numToAdd,rem[i]);
				}
			}
		} else {
			//if both tuplets, see if they play well together
			if (eis&&nis) {
				//pure tuplets only
			}
			
			//if existing is tuplet and new is not, no allow
			if (eis&&!nis) {
				//not allowed to do anything
			}
			
			//if existing is not tuplet and new is tuplet
			if (!eis&&nis) {
				var tN = tupMultiple(nDur);
				
				ary[index].dur = nDur;
				//convert one beat to tuplet
				var beatGroup = tN*lib.rBeat(this,nDur);
				if (ts.indexOf('/8')!=-1) beatGroup = 3;
				var numToAdd = Math.round(beatGroup / nNum) - 1;
				for (var i=0; i<numToAdd; i++) {
					this.insertMeasureBeat(ary,index+i,nDur);
				}
				var rem = findRemainder(eNum - beatGroup,eMB.measure);
				for (var i=0; i<rem.length; i++) {
					this.insertMeasureBeat(ary,index+i+numToAdd,rem[i]);
				}
			}
		}

		for (var i=0; i<ary.length; i++)
			ary[i].iBeat = i;
	}
	
	this.handleChange = function(inputMB,lastDur) { //handles the changes in MB array
		var newDur = this.currentDUR;
		var ary = this.measures[inputMB.measure].obs;
		var index = inputMB.iBeat;
		var eMB = ary[index];
		
		//if a tuplet, use a different function
		var eis = isTuplet(lastDur);
		var nis = isTuplet(newDur);
		if (eis||nis) {
			this.handleTupletChange(eis,nis,lastDur,newDur,ary,index);
			return;
		}
		
		//else proceed
		var eNum = lib.rBeat(this,lastDur);
		var nNum = lib.rBeat(this,newDur);
		var meas = eMB.measure + 1;
		if (eNum > nNum) { //existing duration is greater than the new one
			//change existing dur to new one
			ary[index].dur = newDur;
			
			var rem = findRemainder(eNum-nNum,eMB.measure);
			for (var i=0; i<rem.length; i++) {
				this.insertMeasureBeat(ary,index+i,rem[i]);
			}

		} else { //existing duration is smaller than the new one
			//change quarter to dotted quarter
			ary[index].dur = newDur;
			
			//check for removes
			var rms = [];
			var cb = ary[index].beat + nNum; //calculate the changed beat's window
			var nb;
			for (var i=index+1; i<ary.length; i++) {
				nb = ary[i];
				if (( nb.beat + lib.rBeat(this,nb.dur) ) <= cb)
					rms.push(i);
			}
			//remove the svgs
			for (var i=0; i<rms.length; i++) {
				if (ary[rms[i]].note)
					removeMBNote(ary[rms[i]]);
			}
			ary.splice(rms[0],rms.length); //get out of array
			
			//revert notes
			var noteAry = [];
			for (var i=0; i<ary.length; i++) {
				if (ary[i].note)
					noteAry.push(ary[i].note);
			}
			this.revertNotes(noteAry);
			
			//check for a convert
			var cv;
			for (var i=index+1; i<ary.length; i++) {
				nb = ary[i];
				if (( nb.beat < cb ) && ( nb.beat + lib.rBeat(this,nb.dur) > cb ))
					cv = i;
			}
			if (cv!=undefined) {
				var meas = ary[cv].measure;
				ary[cv].dur = lib.numToDur(this,ary[cv].beat + lib.rBeat(this,ary[cv].dur) - cb,meas); //new duration minus the converted one gives the remainder
				
				for (var i=cv; i<ary.length; i++) {
					ary[i].beat = ary[i-1].beat + lib.rBeat(this,ary[i-1].dur);
				}
				//if has note, remove it
				if (ary[cv].note) {
					removeMBNote(ary[cv]); //remove existing note		
				}
			}
		}

		for (var i=0; i<ary.length; i++)
			ary[i].iBeat = i;
	}
	
	this.getMelody = function() {
		var ary = [];
		var mAry;
		var nobj;
		for (var i=0; i<this.measures.length; i++) {
			mAry = [];
			for (var j=0; j<this.measures[i].obs.length; j++) {
				nobj = {};
				if (this.measures[i].obs[j].snapNote) { //has a note
					nobj.dur = this.measures[i].obs[j].dur;
					nobj.name = this.measures[i].obs[j].snapNote.name;
					nobj.meas = i;
					nobj.beat = this.measures[i].obs[j].beat;
					nobj.iBeat = j;
					nobj.type = 'note';
					if (this.measures[i].obs[j].snapNote.tieF)
						nobj.tie = {meas:i,beat:j};
				}
				if (this.measures[i].obs[j].rest) { //has a rest
					nobj.dur = this.measures[i].obs[j].dur+'r';
					nobj.name = 'rest';
				}
				mAry.push(nobj);
			}
			ary.push(mAry);
		}
		
		return ary;
	}
	
	this.keepNote = function(note,lastDur) {
		this.placeNote(note,true);
		//save note in measures
		//var nbj = {};
		//nbj.dur = note.dur;
		//nbj.note = note.note;
		//nbj.x = note.x;
		//if (note.type!='rest')
		//	this.measures[MB.meas].obs[MB.beat].note = nbj;
		
		//printNotes();
		
		//if the existing MB rhythm is different from GS.currentDUR, adjust the measure-beats array
		if (lastDur!=this.currentDUR) {
			this.removeRests(); //remove all rests before changing the MB arrays
			
			//HANDLE THE CHANGE TO MB ARRAYS
			this.handleChange(note,lastDur);
			
			//CALCULATE NEW Xs FOR EVERYBODY
			this.recalculateDisplay();
			
			return;
		}
		
		this.removeRests();
		
		this.beamNotes();
		
		this.drawRests();

		this.appendAll();
	}
	
	function scaleMod(nts) {
		var scale = lib.scaleMaker(dis.params.key,dis.params.mode);
		for (var i=0; i<nts.length; i++) {
			for (var j=0; j<scale.length; j++) {
				if (nts[i][0]==scale[j][0])
					nts[i]=scale[j]+lib.retNum(nts[i]);
			}
		}
		return nts;
	}
	
	/*function findNote(y,vc) {
		//vc is 1,2 => treble
		//vc is 3,4 => bass
		
		var nts;
		var strt;
		var sttp;
		if (vc<3) {
			nts = ['F6','E6','D6','C6','B5','A5','G5','F5','E5','D5','C5','B4','A4','G4','F4','E4','D4','C4','B3','A3','G3','F3','E3'];
			strt = -7;
			sttp = 0;
		} else {
			nts = ['A4','G4','F4','E4','D4','C4','B3','A3','G3','F3','E3','D3','C3','B2','A2','G2','F2','E2','D2','C2','B1','A1','G1'];
			strt = -7;
			sttp = 12*17.75;
			sttp = 0;
		}
		
		//edit notes based on scale
		nts = scaleMod(nts);
		
		//only returning bare notes, based on y-value
		var lnsp = 17.75/2;
		var ys = [];
		for (var i=strt; i<nts.length; i++) {
			if (sttp+lnsp*(i+0.5)+strt*lnsp > y) return nts[i];
		}
		return false;
	}*/
	
	var findNote = function(cp) {
		var y = cp.y;
		var nd = 0;
		for (var i=0; i<hnotes1.length; i++) {
			if (y>(i*dis.lnsp/2-29-1.5*dis.lnsp)) nd = i;
		}
		var nts = scaleMod(lib.copyAry(hnotes));
		return nts[nd];
	}
	
	function noteExists(MB) {
		var nt = dis.measures[MB.measure].obs[MB.iBeat];
		
		if (nt.note) return true;
		return false;
	}
	
	var CANEDIT = true;
	var TIETIME = false;
	var inputNote;
	this.currentDUR = '4';
	function inputMove(m,r) {
		if (!CANEDIT) return;
		if (!inputNote) inputNote = {};
		if (dis.axeMenuIsOpen) return;
		//else removeInput();
		var cp = lib.cursorPoint(m,S,r); //x,y of mouse
		var inputMB;
		inputMB = dis.findMB(cp); //index in measure, which beat or part of beat we're on
		var newName = findNote(cp);// || testNote.name;
		if (!newName) return;
		
		if (noteExists(inputMB)) return;
		//inputMB.dur = retDur(dis.currentDUR);
		
		//if TIETIME don't draw
		if (TIETIME) {
			return;
		}
		
		dis.placeTester(inputMB,newName);
	}

	function inputD() {
		if (dis.disabled) return;
		var m = key_coord.m;
		var b = key_coord.b;
		var inputMB = dis.measures[m].obs[b];
		var tuplet = clickTuplet(inputMB);
		if (tuplet) {
			var checkFirst = onFirstBeat(inputMB);
			if (!checkFirst) return;
			removeTupletBeat(inputMB);
			dis.placeTester(inputMB,key_coord.n);
			refocus(inputMB);
		}
	}

	function inputAxe(type) {
		if (dis.disabled) return;
		var m = key_coord.m;
		var b = key_coord.b;
		var inputMB = dis.measures[m].obs[b];
		if (clickExisting(inputMB,inputNote.voice)) {
			if (dis.measures[inputMB.measure].obs[inputMB.iBeat].tieF || dis.measures[inputMB.measure].obs[inputMB.iBeat].tieB) {
				alert('Can\'t add accidental to a tied note!'); return;
			}
			axeHandle(inputMB,type);
			
			//update all following
			dis.updateAxe(inputMB);
			
			//remove rests
			dis.removeRests();
			
			//recalc display
			dis.recalculateDisplay();

			//update aria label
			inputMB.note.node.ariaLabel = '';//nType(inputMB.dur)+" note, "+accessibleName(inputMB.name)+", measure "+(inputMB.measure+1)+", beat "+(inputMB.beat);

			refocus(inputMB);
		} else {
			//alert, cant do it
		}
	}

	var key_coord = {m:0,b:-1,n:'B4'};
	function inputUp() {
		if (!CANEDIT) return;
		if (dis.disabled) return;
		if (!inputNote) inputNote = {};
		var m = key_coord.m;
		var b = key_coord.b;
		if (b<0) return;
		var inputMB = dis.measures[m].obs[b];
		if (noteExists(inputMB)) {
			dis.removeTester();
			return;
		}
		
		//if TIETIME don't draw
		if (TIETIME) {
			return;
		}

		var n = key_coord.n;
		var nDX = nts.indexOf(n);
		nDX++;
		n = nts[nDX];
		if (!n) n = nts[nDX-1];
		//increase by 1
		key_coord.n = n;
		
		dis.placeTester(inputMB,key_coord.n);

		return n;
	}

	function inputDown() {
		if (!CANEDIT) return;
		if (dis.disabled) return;
		if (!inputNote) inputNote = {};
		var m = key_coord.m;
		var b = key_coord.b;
		if (b<0) return;
		var inputMB = dis.measures[m].obs[b];
		if (noteExists(inputMB)) {
			dis.removeTester();
			return;
		}
		
		//if TIETIME don't draw
		if (TIETIME) {
			return;
		}

		var n = key_coord.n;
		var nDX = nts.indexOf(n);
		nDX--;
		n = nts[nDX];
		if (!n) n = nts[nDX+1];
		//increase by 1
		key_coord.n = n;
		
		dis.placeTester(inputMB,key_coord.n);

		return n;
	}

	function inputTab() {
		if (!CANEDIT) return;
		if (dis.disabled) return;
		if (!inputNote) inputNote = {};
		console.log('starting');
		console.log(key_coord);
		
		var m = key_coord.m;
		var b = key_coord.b;
		b++;
		if (b==dis.measures[m].obs.length) {
			m = m + 1;
			b = 0;
			console.log('next measure');
		}
		if (m==dis.measures.length) {
			m--;
			b = dis.measures[m].obs.length-1;
			dis.removeTester();
			console.log('end of score');
			return;
		}
		key_coord.m = m;
		key_coord.b = b;
		console.log('ending');
		console.log(key_coord);
		
		var inputMB = dis.measures[m].obs[b];
		if (noteExists(inputMB)) {
			key_coord.n = inputMB.name.replace('♯','').replace('\u0231','').replace('x','').replace('bb','');
			dis.removeTester();
			return;
		}
		
		//if TIETIME don't draw
		if (TIETIME) {
			return;
		}
		
		dis.placeTester(inputMB,key_coord.n);
	}

	function backTab() {
		if (!CANEDIT) return;
		if (dis.disabled) return;
		if (!inputNote) inputNote = {};
		
		var m = key_coord.m;
		var b = key_coord.b;
		b--;
		if (b==-2) return;
		if (b==-1) {
			m = m - 1;
			if (m==-1) {
				m = 0;
				b = 0;
				dis.removeTester();
				console.log('start of score');
				return;
			} else {
				b = dis.measures[m].obs.length-1;
			}
		}
		if (m==-1) {
			m++;
			b = 0;
			dis.removeTester();
			console.log('start of score');
			return;
		}
		key_coord.b = b;
		key_coord.m = m;
		console.log(key_coord);
		
		var inputMB = dis.measures[m].obs[b];
		if (noteExists(inputMB)) {
			key_coord.n = inputMB.name.replace('♯','').replace('\u0231','').replace('x','').replace('bb','');
			dis.removeTester();
			return;
		}
		
		//if TIETIME don't draw
		if (TIETIME) {
			return;
		}
		
		dis.placeTester(inputMB,key_coord.n);
	}

	function inputSpace() {
		var element = document.activeElement;
		if (element.className=="inputstaff") return;
		if (dis.disabled) return;
		if (dis.removeTupletIsOpen) {
			dis.removeTupletIsOpen.remove();
			dis.removeTupletIsOpen = null;
			return;
		}

		var m = key_coord.m;
		var b = key_coord.b;
		var inputMB = dis.measures[m].obs[b];
		
		if (TIETIME) { //handle drawing of ties
			lib.handleTie(dis,inputMB);
			refocus(inputMB);
			return;
		}
		
		//remove
		if (clickExisting(inputMB)) {
			key_coord.n = inputMB.name.replace('♯','').replace('\u0231','').replace('x','').replace('bb','');
			removeMBNote(inputMB); //removes note and all its stuff
			
			dis.recalculateDisplay();
			
			dis.removeTester();

			refocus(inputMB);

			return;
		}
		
		//check if have enough beats left in measure
		if (!checkRoom(inputMB,dis.currentDUR)) {
			alert('Not enough room in measure.');
			refocus(inputMB);
			return;
		}
		
		//if tuplet, check to see if the conversion is correct
		if (isTuplet(dis.currentDUR)&&inputMB.dur!=dis.currentDUR) {
			var conversion = checkConversion(inputMB,dis.currentDUR);
			if (conversion.error) {
				alert(conversion.error);
				refocus(inputMB);
				return;
			}
		}
		
		//if tuplet there, check to see if can put GS.currentDUR
		if (isTuplet(inputMB.dur)&&dis.currentDUR!=inputMB.dur) {
			//check if ok to continue
			
			//for now, no
			alert('Cannot place this note type into tuplet.');
			refocus(inputMB);
			return;
		}

		//keep it
		var lastDur = inputMB.dur;
		inputMB.dur = dis.currentDUR;
		inputMB.name = key_coord.n;
		dis.keepNote(inputMB,lastDur);

		refocus(inputMB);
	}

	var refocus = function(inputMB) {
		if (inputMB.note) inputMB.note.node.focus();
		if (inputMB.rest) inputMB.rest.node.focus();
	}
	
	function clickExisting(mb) {
		var nt = dis.measures[mb.measure].obs[mb.iBeat].note;
		
		//not note there
		if (!nt) return false;
		else return true;
	}
	
	function removeMBNote(mb) {
		if (mb.flag) {
			mb.flag.remove();
			delete mb.flag;
		}
		if (mb.stem) {
			mb.stem.remove();
			delete mb.stem;
		}
		if (mb.dot) {
			mb.dot.remove();
			delete mb.dot;
		}
		if (mb.beam) {
			mb.beam.remove();
			delete mb.beam;
		}
		if (mb.beams) {
			for (var i=0; i<mb.beams.length; i++)
				mb.beams[i].remove();
			delete mb.beams;
		}
		if (mb.beam2) {
			mb.beam2.remove();
			delete mb.beam2;
		}
		if (mb.axe) {
			mb.axe.remove();
			delete mb.axe;
		}
		if (mb.axeMenu) {
			mb.axeMenu.remove();
			delete dis.axeMenuIsOpen;
		}
		if (mb.tieF) {
			var tie = mb.tieF;

			delete tie.n2.tieB;
			
			tie.remove();
			
			delete mb.tieF;
			delete mb.tied;
		}
		if (mb.tieB) {
			var tie = mb.tieB;

			delete tie.n1.tieF;
			delete tie.n1.tied;
			
			tie.remove();
			
			delete mb.tieB;
		}
		if (mb.t3) {
			mb.t3.remove();
			delete mb.t3;
		}
		if (mb.ledgers) {
			for (var i=0; i<mb.ledgers.length; i++) {
				mb.ledgers[i].remove();
			}
			delete mb.ledgers;
		}

		if (mb.stemBuddies) {
			revertNotes(mb.stemBuddies);
			for (var zz=0; zz<mb.stemBuddies.length; zz++)
				delete mb.stemBuddies[zz].stemBuddies;
			delete mb.stemBuddies;
		}
		
		mb.note.remove();

		//delete all other properties
		delete mb.note;
		delete mb.by;
		delete mb.height;
		delete mb.y;
		delete mb.name;
		delete mb.i;
		delete mb.diff;
		delete mb.width;
		delete mb.axeMenu;
	}
	
	function removeNote(note) {
		if (note.flag)
			note.flag.remove();
		if (note.stem)
			note.stem.remove();
		if (note.dot)
			note.dot.remove();
		if (note.beam)
			note.beam.remove();
		if (note.beams) {
			for (var i=0; i<note.beams.length; i++)
				note.beams[i].remove();
			note.beams = [];
		}
		if (note.beam2)
			note.beam2.remove();
		if (note.axe)
			note.axe.remove();
		if (note.axeMenu) {
			note.axeMenu.remove();
			dis.axeMenuIsOpen = null;
		}
		if (note.tieF) {
			var tie = note.tieF;
			tie.n1 = null;
			tie.n2.tieB = null;
			//update aria-label
			note.node.ariaLabel = '';//nType(note.dur)+" note, "+accessibleName(note.name)+", measure "+(note.measure+1)+", beat "+(note.beat);
			var secondNote = tie.n2;
			secondNote.node.ariaLabel = '';//nType(secondNote.dur)+" note, "+accessibleName(secondNote.name)+", measure "+(secondNote.measure+1)+", beat "+(secondNote.beat);
			
			tie.remove();
			
			note.tieF = null;
		}
		if (note.tieB) {
			var tie = note.tieB;
			tie.n2 = null;
			tie.n1.tieF = null;
			//update aria-label
			note.node.ariaLabel = '';//nType(note.dur)+" note, "+accessibleName(note.name)+", measure "+(note.measure+1)+", beat "+(note.beat);
			var secondNote = tie.n2;
			secondNote.node.ariaLabel = '';//nType(secondNote.dur)+" note, "+accessibleName(secondNote.name)+", measure "+(secondNote.measure+1)+", beat "+(secondNote.beat);
			
			tie.remove();
			
			note.tieB = null;
		}
		if (note.t3)
			note.t3.remove();
		if (note.ledgers) {
			for (var i=0; i<note.ledgers.length; i++)
				note.ledgers[i].remove();
		}
		note.ledgers = [];
		
		note.remove();
	}
	
	function revertNotes(ary) {
		for (var i=0; i<ary.length; i++) {
			if (ary[i].dur=='16') {
				var x = ary[i].x;			
				if (ary[i].stem) ary[i].stem.remove();
				if (ary[i].flag) ary[i].flag.remove();
				var flag = !( ary[i].i>9 ) ? dis.whole.path(lib.sixFlagPath).transform('t'+(x-68)+' '+(ary[i].y-55-318.75+782)+' s0.06 -0.06') : dis.whole.path(lib.sixFlagPath).transform('t'+(x-89.5)+' '+(ary[i].y-331+800)+' s0.06 0.06');
				ary[i].stem = ( ary[i].i>9 ) ? dis.whole.rect(x+0.05,ary[i].y+1.75,2,55) : dis.whole.rect(x+20.95,ary[i].y-55-1.95,2,55);
				
				ary[i].flag = flag;
				dis.whole.prepend(flag);
				dis.whole.prepend(ary[i].stem);
			}
			if (ary[i].dur=='s16') {
				
				var x = ary[i].x;			
				if (ary[i].stem) ary[i].stem.remove();
				if (ary[i].flag) ary[i].flag.remove();
				if (ary[i].t3) ary[i].t3.remove();
				var flag = !( ary[i].i>9 ) ? dis.whole.path(lib.sixFlagPath).transform('t'+(x-68)+' '+(ary[i].y-55-318.75+782)+' s0.06 -0.06') : dis.whole.path(lib.sixFlagPath).transform('t'+(x-89.5)+' '+(ary[i].y-331+800)+' s0.06 0.06');
				ary[i].stem = ( ary[i].i>9 ) ? dis.whole.rect(x+0.05,ary[i].y+1.75,2,55) : dis.whole.rect(x+20.95,ary[i].y-55-1.95,2,55);
				
				ary[i].flag = flag;
				
				var x3 = x+10;
				var y3 = ary[i].y-40;
				var t3 = dis.whole.text(x3,y3,'6').attr({fontStyle:'italic'});
				ary[i].t3 = t3;
				if (ary[i].i%2) t3.transform('t0 -9');
				
				dis.whole.prepend(flag);
				dis.whole.prepend(ary[i].stem);
			}
			if (ary[i].dur=='8') {
				
				var x = ary[i].x;
				var flag = ( ary[i].i>9 ) ? dis.whole.path(lib.eighthFlagPath).transform('t'+(x-117.9)+' '+(ary[i].y-368.75+40)+' s0.06 -0.06') : dis.whole.path(lib.eighthFlagPath).transform('t'+(x-97)+' '+(ary[i].y-379)+' s0.06 0.06');
				if (ary[i].stem) ary[i].stem.remove();
				if (ary[i].flag) ary[i].flag.remove();
				ary[i].stem = ( ary[i].i>9 ) ? dis.whole.rect(x+0.05,ary[i].y+1.75,2,40) : dis.whole.rect(x+20.95,ary[i].y-40-1.95,2,40);
				
				ary[i].flag = flag;
				dis.whole.prepend(flag);
				dis.whole.prepend(ary[i].stem);
			}
			if (ary[i].dur=='8.') {
				
				var x = ary[i].x;
				var flag = ( ary[i].i>9 ) ? dis.whole.path(lib.eighthFlagPath).transform('t'+(x-117.9)+' '+(ary[i].y-368.75+40)+' s0.06 -0.06') : dis.whole.path(lib.eighthFlagPath).transform('t'+(x-97)+' '+(ary[i].y-379)+' s0.06 0.06');
				if (ary[i].stem) ary[i].stem.remove();
				if (ary[i].flag) ary[i].flag.remove();
				ary[i].stem = ( ary[i].i>9 ) ? dis.whole.rect(x+0.05,ary[i].y+1.75,2,40) : dis.whole.rect(x+20.95,ary[i].y-40-1.95,2,40);
				
				ary[i].flag = flag;
				dis.whole.prepend(flag);
				dis.whole.prepend(ary[i].stem);
			}
			if (ary[i].dur=='t8') {
				
				var x = ary[i].x;
				var flag = ( ary[i].i>9 ) ? dis.whole.path(lib.eighthFlagPath).transform('t'+(x-117.9)+' '+(ary[i].y-368.75+40)+' s0.06 -0.06') : dis.whole.path(lib.eighthFlagPath).transform('t'+(x-97)+' '+(ary[i].y-379)+' s0.06 0.06');
				if (ary[i].stem) ary[i].stem.remove();
				if (ary[i].flag) ary[i].flag.remove();
				if (ary[i].t3) ary[i].t3.remove();
				ary[i].stem = ( ary[i].i>9 ) ? dis.whole.rect(x+0.05,ary[i].y+1.75,2,40) : dis.whole.rect(x+20.95,ary[i].y-40-1.95,2,40);
				
				ary[i].flag = flag;
				
				var x3 = x+10;
				var y3 = ary[i].y-15;
				var t3 = dis.whole.text(x3,y3,'3').attr({fontStyle:'italic'});
				ary[i].t3 = t3;
				if (ary[i].i%2) t3.transform('t0 -9');
				
				dis.whole.prepend(flag);
				dis.whole.prepend(ary[i].stem);
			}
			if (ary[i].dur=='d8') {
				
				var x = ary[i].x;
				var flag = ( ary[i].i>9 ) ? dis.whole.path(lib.eighthFlagPath).transform('t'+(x-117.9)+' '+(ary[i].y-368.75+40)+' s0.06 -0.06') : dis.whole.path(lib.eighthFlagPath).transform('t'+(x-97)+' '+(ary[i].y-379)+' s0.06 0.06');
				if (ary[i].stem) ary[i].stem.remove();
				if (ary[i].flag) ary[i].flag.remove();
				if (ary[i].t3) ary[i].t3.remove();
				ary[i].stem = ( ary[i].i>9 ) ? dis.whole.rect(x+0.05,ary[i].y+1.75,2,40) : dis.whole.rect(x+20.95,ary[i].y-40-1.95,2,40);
				
				ary[i].flag = flag;
				
				var x3 = x+10;
				var y3 = ary[i].y-15;
				var t3 = dis.whole.text(x3,y3,'2').attr({fontStyle:'italic'});
				ary[i].t3 = t3;
				if (ary[i].i%2) t3.transform('t0 -9');
				
				dis.whole.prepend(flag);
				dis.whole.prepend(ary[i].stem);
			}
			if (ary[i].dur=='f8') {
				
				var x = ary[i].x;
				var flag = ( ary[i].i>9 ) ? dis.whole.path(lib.eighthFlagPath).transform('t'+(x-117.9)+' '+(ary[i].y-368.75+40)+' s0.06 -0.06') : dis.whole.path(lib.eighthFlagPath).transform('t'+(x-97)+' '+(ary[i].y-379)+' s0.06 0.06');
				if (ary[i].stem) ary[i].stem.remove();
				if (ary[i].flag) ary[i].flag.remove();
				if (ary[i].t3) ary[i].t3.remove();
				ary[i].stem = ( ary[i].i>9 ) ? dis.whole.rect(x+0.05,ary[i].y+1.75,2,40) : dis.whole.rect(x+20.95,ary[i].y-40-1.95,2,40);
				
				ary[i].flag = flag;
				
				var x3 = x+10;
				var y3 = ary[i].y-15;
				var t3 = dis.whole.text(x3,y3,'4').attr({fontStyle:'italic'});
				ary[i].t3 = t3;
				if (ary[i].i%2) t3.transform('t0 -9');
				
				dis.whole.prepend(flag);
				dis.whole.prepend(ary[i].stem);
			}
		}
	}
	this.revertNotes = revertNotes;
	
	function isTuplet(dur) {
		var ts = ['t','f','s','d'];
		for (var i=0; i<ts.length; i++)
			if (dur.indexOf(ts[i])!=-1) return true;
		return false;
	}

	function checkRoom(mb,dur) {
		var measures = dis.measures;
		var duration = measures[measures.length-1].duration;
		var meas = measures[mb.measure];
		var note = meas.obs[mb.iBeat];
		var beat = note.beat;
		if (beat+lib.rBeat(dis,dur) > (duration+1)) return false;
		return true;
	}

	var cr;
	this.interactiveMeasures = function() {
		//clear rect
		cr = S.rect(0,-100,100,200+17.75*4).attr({opacity:0});
		this.cr = cr;
		cr.click(
			function(m) {
				if (dis.disabled) return;
				if (dis.removeTupletIsOpen) {
					dis.removeTupletIsOpen.remove();
					dis.removeTupletIsOpen = null;
					return;
				}
				
				if (dis.axeMenuIsOpen) {
					dis.axeMenuIsOpen.hide();
					dis.axeMenuIsOpen = null;
					return;
				}

				var cp = lib.cursorPoint(m,S,cr); //x,y of mouse
				var inputMB = dis.findMB(cp); //index in measure, which beat or part of beat we're on
				
				if (TIETIME) { //handle drawing of ties
					lib.handleTie(dis,inputMB);
					return;
				}
				
				//remove
				if (clickExisting(inputMB)) {
					removeMBNote(inputMB); //removes note and all its stuff
					
					dis.updateAxe(inputMB);
					
					dis.recalculateDisplay();
					
					dis.removeTester();

					return;
				}
				
				//check if have enough beats left in measure
				if (!checkRoom(inputMB,dis.currentDUR)) {
					alert('Not enough room in measure.');
					return;
				}
				
				//if tuplet, check to see if the conversion is correct
				if (isTuplet(dis.currentDUR)&&inputMB.dur!=dis.currentDUR) {
					var conversion = checkConversion(inputMB,dis.currentDUR);
					if (conversion.error) {
						alert(conversion.error);
						return;
					}
				}
				
				//if tuplet there, check to see if can put GS.currentDUR
				if (isTuplet(inputMB.dur)&&dis.currentDUR!=inputMB.dur) {
					//check if ok to continue
					
					//for now, no
					alert('Cannot place this note type into tuplet.');
					return;
				}

				//keep it
				var lastDur = inputMB.dur;
				inputMB.dur = dis.currentDUR;
				inputMB.name = findNote(cp) || inputMB.name;
				dis.keepNote(inputMB,lastDur);
			}
		)
		cr.node.onmousemove = function(m) {
			if (dis.disabled) return;
			inputMove(m,cr);
		}
		cr.hover(
			function() {if (dis.disabled) return;},
			function() {if (dis.disabled) return; dis.removeTester();}
		)
		cr.node.addEventListener('contextmenu', function(ev) {
			ev.preventDefault();
			if (dis.disabled) return;
			var cp = lib.cursorPoint(ev,S,cr); //x,y of mouse
			var inputMB = dis.findMB(cp); //index in measure, which beat or part of beat we're on
			if (clickExisting(inputMB,inputNote.voice)) {
				if (dis.measures[inputMB.measure].obs[inputMB.iBeat].tieF || dis.measures[inputMB.measure].obs[inputMB.iBeat].tieB) {
					alert('Can\'t add accidental to a tied note!'); return;
				}
				dis.axeOpen(inputMB,inputNote.voice);
			}
			
			//check if on a tuplet member
			var tuplet = clickTuplet(inputMB);
			if (tuplet) {
				var checkFirst = onFirstBeat(inputMB);
				if (tuplet.rest) {
					if (!checkFirst) return;
					
					//show the remove tuplet button
					new removeTuplet(tuplet);
				} else {
					if (!checkFirst) return;
					//the note version
					new removeTuplet(tuplet,true);
				}
			}
			return false;
		});
		this.whole.append(cr);
	}
	
	//tuplet shit
	function onFirstBeat(mb) {
		var nt = dis.measures[mb.measure].obs[mb.iBeat];
		if (nt.beat==Math.floor(nt.beat)) return true;
		return false;
	}
	
	function removeTuplet(mb,tog) {
		//if one open
		if (dis.removeTupletIsOpen)
			dis.removeTupletIsOpen.remove();
		
		var ele = tog ? mb.note : mb.rest;
		var x = mb.x - 55 + 12;
		var y = -70;//tog ? mb.snapNote.getBBox().y-70 : -18;
		var stf = dis.whole;
		var bg = stf.rect(x,y,111,24,5).attr({fill:'#E0E5E6',stroke:'black'});
		var text = stf.text(x+4,y+18,'Remove tuplet').attr({fontFamily:'lato'});
		var bgclr = stf.rect(x,y,111,24).attr({opacity:0});
		bgclr.node.id = 'pointer';
		var bgr = stf.group(bg,text,bgclr);
		dis.whole.append(bgr);
		bgr.click(function(){
			removeTupletBeat(mb);
			
			if (dis.removeTupletIsOpen) {
				dis.removeTupletIsOpen.remove();
				dis.removeTupletIsOpen = null;
			}
			
			dis.removeTester();
		})
		bgr.hover(function(){
			text.attr({fill:'white'});
		},function(){
			text.attr({fill:'black'});
		})
		dis.removeTupletIsOpen = bgr;
	}

	function findFirstBeat(beat,meas) {
		var time = dis.params.tsig;
		if (time.indexOf('/4')!=-1) return Math.floor(beat);
		if (time.indexOf('/8')!=-1) {
			if (beat<4) return 1;
			if (beat<7) return 4;
			if (beat<10) return 7;
			return 10;
		}
		if (time.indexOf('/2')!=-1) {
			return Math.floor(2*beat)/2;
		}
	}

	function removeTupletBeat(mb) {
		//remove all tuplet members from MB array
		var ary = dis.measures[mb.measure].obs;
		
		//remove rests
		dis.removeRests();
		
		//remove notes in the beat
		var tupM = tupMultiple(mb.dur);
		var tupletDuration = tupM*lib.rBeat(dis,mb.dur,mb.measure);
		if (Math.abs(tupletDuration-Math.round(tupletDuration))<0.1) tupletDuration = Math.round(tupletDuration);
		if (Math.abs(tupletDuration-(Math.floor(tupletDuration)+0.5))<0.1) tupletDuration = Math.floor(tupletDuration)+0.5;
		var firstBeat = findFirstBeat(mb.beat,mb.measure);
		var fI;
		for (var i=0; i<ary.length; i++) {
			if (ary[i].beat==firstBeat) fI = i;
			if (ary[i].beat>=firstBeat&&ary[i].beat<(firstBeat+tupletDuration)) {
				if (ary[i].note) {
					removeMBNote(ary[i]);
					ary[i].note = null;
				}
			}
		}
		
		//first beat gets dur set to full duration of tuplet
		var fB = ary[fI];
		fB.dur = lib.numToDur(dis,tupletDuration,mb.measure);
		ary[fI] = fB;
		
		//splice the tuplets out of ary
		tupM--;
		while(tupM) {
			ary.splice(fI+1,1);
			tupM--;
		}

		for (var i=0; i<ary.length; i++)
			ary[i].iBeat = i;
		
		dis.recalculateDisplay();
	}

	function clickTuplet(mb) {
		var nt = dis.measures[mb.measure].obs[mb.iBeat];
		
		var tups = ['t8','d8','f8','t4','d4','s16'];
		if (tups.indexOf(nt.dur)!=-1) return nt;
		return false;
	}
	
	//beaming
	var beatBuckets = function(m) {
		var ts = dis.params.tsig.split('/');
		var num = parseInt(ts[0]);
		var base = ts[1];
		
		var wdw;
		if (base=='4') wdw = 1;
		if (base=='2') wdw = 1;
		if (base=='8') wdw = 3;
		
		//6/4 shit...
		
		//num = num / wdw; //number of windows to look in, 4/4 should give 4, 6/8 should give two
		
		var bucks = [];
		var bw;
		for (var i=0; i<num; i+=wdw) {
			bw = [i+1,i+1+wdw];
			bucks.push(bw);
		}
		return bucks;
	}

	var lessClose = function(target,num) {
		//test if num is less than or very close to target
		var rnd = Math.round(num);
		if (Math.abs(rnd-num)<0.05) num = rnd;
		
		if (target==num) return true; //basically equal to it
		
		if (num < target) return true;
		
		return false;
	}

	var getInWindow = function(nts,wnd,m) {
		var left = wnd[0];
		var right = wnd[1];
		
		var ary = [];
		for (var i=0; i<nts.length; i++) {
			if (nts[i].beat>=left && lessClose(right,nts[i].beat+lib.rBeat(dis,nts[i].dur)))
				ary.push(nts[i]);
		}
		return ary;
	}

	var tooLong = function(mb) {
		var dur = mb.dur;
		if (dur.indexOf('8')==-1&&dur.indexOf('16')==-1) return true;
		return false;
	}
	
	function handleBeaming(measure,indices) {
		var bsNotes = lib.copyAry(hnotes).reverse();
		var middleN;
		switch(dis.params.clef) {
			case 'Treble':
				middleN = 'B4';
				break;
			case 'Bass':
				middleN = 'D3';
				break;
			case 'Alto':
				middleN = 'C4';
				break;
		}
		
		var grp;
		var notes;
		var beam;
		var note;
		var type;
		var tsig = dis.params.tsig;
		for (var z=0; z<indices.length; z++) {
			grp = indices[z]; //a group of notes
			notes = [];
			beam = true; //yes beam
			type = '';
			for (var j=0; j<grp.length; j++) { //loop through the group of notes
				if (!measure[grp[j]].note) {beam = false; continue;} //missing a drawn note, so no beaming
				if (measure[grp[j]].dur=='4'&&tsig.indexOf('/8')!=-1) {beam = false; continue;}
				if (measure[grp[j]].dur=='4'&&tsig.indexOf('/2')!=-1) {beam = false; continue;}
				note = measure[grp[j]];//.note;
				notes.push(note);
			}
			if (beam) {
				for (var j=0; j<notes.length; j++) {
					lib.cleanNote(notes[j]); //remove stem, flag, beam(s)
					type += notes[j].dur;
					if ( j < (notes.length-1) )
						type += ',';
				}
				
				//find stem direction
				var nms = [];
				var diff;
				var nm;
				var negMax = 1000000, posMax = -1000000;
				for (var i=0; i<notes.length; i++) {
					if (!notes[i]) continue; //hopefully rests
					nm = lib.cleanName(notes[i].name);
					diff = bsNotes.indexOf(nm) - bsNotes.indexOf(middleN);
					notes[i].diff = Math.abs(diff);
					if (diff < negMax) negMax = diff;
					if (diff > posMax) posMax = diff;
				}
				
				if (Math.abs(negMax) > Math.abs(posMax)) { //maximum below B4
					lib.drawStems(dis,notes,type,'up');
				}
				
				if (Math.abs(posMax) > Math.abs(negMax)) { //maximum above B4
					lib.drawStems(dis,notes,type,'down');
				}
				
				if (Math.abs(posMax) == Math.abs(negMax)) {
					if (posMax==negMax) { //same note
						if (posMax>=0) lib.drawStems(dis,notes,type,'down');
						else lib.drawStems(dis,notes,type,'up');
					} else { //same distance from middle
						lib.drawStems(dis,notes,type,'down');
					}
				}
			}
		}
	}
	
	this.beamNotes = function(n) {
		var rtms, nts;
		var ms;
		for (var m=0; m<this.measures.length; m++) {
			rtms = [];
			nts = [];
			ms = this.measures[m].obs;
			for (var b=0; b<ms.length; b++) {
				rtms.push(ms[b].dur); //all rhythms in measure
				nts.push(ms[b]);
			}
			
			var bmg = []; //group of indices
			var bbs = beatBuckets(m); //maximum beats to beam
			var bg;
			var bwd;
			for (var i=0; i<bbs.length; i++) {
				bwd = getInWindow(nts,bbs[i],m);
				bg = [];
				for (var j=0; j<bwd.length; j++) {
					if (bwd[j].rest||!bwd[j].note||tooLong(bwd[j])) { //run into a rest
						if (bg.length>1) bmg.push(bg);
						bg = [];
						continue;
					}
					
					bg.push(nts.indexOf(bwd[j]));
				}
				if (bg.length>1) bmg.push(bg); //done with loop
			}
			
			handleBeaming(ms,bmg);
		}
	}
	
	//DUR BUTTON
	var DB;
	var TRIP = false;
	var DUP = false;
	var DURbutton = function(x,y) {
		var S = dis.paper;
		var bg = S.group();
		dis.whole.append(bg);
		var yst = y;//17.75*8 + 17.75*8 + 130;
		var xst = x+25;//290;
		
		var pRs = [];
		var hideAllRs = function() {
			for (var i=0; i<pRs.length; i++) {
				if ( ( pRs[i].rt+dotted() )!=dis.currentDUR || TIETIME)
					pRs[i].attr({opacity:0});
			}
		}
		
		var dotted = function() {
			if (rtog) return '.';
			return '';
		}
		
		var cdurs = ['16','8','4','2','1'];
		var clicker = function(ele,rt,ry) {
			var bb = ele.getBBox();
			var h = 80;
			if (rt=='1w') ry-=14;
			ry+=10;
			
			var r = S.rect(bb.x-25,ry+25,bb.width+50,h).attr({opacity:0});
			var ts = dis.params.tsig;
			
			if (rt=='1q')
				r.attr({opacity:0.1});
			
			//make keyboard label
			var lbl = bg.text(bb.x+bb.width/2,yst,'[ '+( rtms.indexOf(rt) + 1 )+' ]');
			lbl.attr({fontSize:'30px',fontFamily:'lato'});
			lbl.transform('t'+(-lbl.getBBox().width/2)+' 0 s0.4');
			
			ele.append(r);
			r.rt = cdurs[rtms.indexOf(rt)];
			pRs.push(r);
			
			r.click(
				function() {
					if (dis.disabled) return;
					if (TIETIME) return;
					if (!CANEDIT) return;
					
					//clear out tuple select
					TUP = false;
					hideAllTupRs();
					
					dis.currentDUR = cdurs[rtms.indexOf(rt)];
					dis.DB.dotted = false;
					
					//if (inputNote) {
					//	inputNote.dur = dis.currentDUR;
						//if (MB) {
						//	if (!dis.measures[MB.meas].obs[MB.beat].note)
						//		dis.placeNote(MB,inputNote);
						//}
					//}
					
					hideAllRs();
				}
			)
			r.node.id = 'pointer';
			r.hover(
				function() {
					if (dis.disabled) return;
					if (TIETIME) return;
					if (!CANEDIT) return;
					if (cdurs[rtms.indexOf(rt)]+dotted()==dis.currentDUR) return;
					r.attr({opacity:0.1});
				},
				function() {
					if (dis.disabled) return;
					if (TIETIME) return;
					if (!CANEDIT) return;
					if (rt=='1e'&&dis.currentDUR=='t8') return;
					if (cdurs[rtms.indexOf(rt)]+dotted()==dis.currentDUR) return;
					r.attr({opacity:0});
				}
			)
		}
		
		//background
		var bgr = S.rect(xst,yst,488,50,5).attr({fill:'#E0E5E6'});
		bg.append(bgr);
		
		//draw rhythms
		var rtms = ['1s','1e','1q','1h','1w'];
		var drx = xst-150;
		var apvg;
		for (var i=0; i<rtms.length; i++) {
			apvg = lib.paletteRhythm(dis,rtms[i],drx,yst+48);
			bg.append(apvg);
			
			apvg.transform('s0.5');
			
			drx+=45;

			//make clicker
			new clicker(apvg,rtms[i],yst-48);
		}
		
		var tieReturn = function() {
			ttog = false;
			tieR.attr({opacity:0});	
			TIETIME = false;
		}
		
		var rtog;
		var dotR;
		var dotClicker = function(ele,x,y) {
			var w = 30;
			var h = 30;
			dotR = bg.rect(x-w/2,y-h/2,30,30).attr({opacity:0});
			
			//make keyboard label
			var lbl = bg.text(x-w/2,yst,'[ 6 ]').attr({fontSize:'30px',fontFamily:'lato'});
			lbl.transform('t0 0 s0.4');
			lib.moveEle(lbl,-lbl.getBBox().width/2,0);
			
			var r = dotR;
			r.hover(
				function() {
					if (dis.disabled) return;
					if (TIETIME) return;
					if (!CANEDIT) return;
					r.attr({opacity:0.1});
				},
				function() {
					if (dis.disabled) return;
					if (TIETIME) return;
					if (!CANEDIT) return;
					if (!rtog)
						r.attr({opacity:0});
				}
			)
			r.click(
				function() {
					//DB.tied = false;
					if (dis.disabled) return;
					if (TIETIME) return;
					if (!CANEDIT) return;
					
					if (dis.currentDUR=='t8') return;
					if (dis.currentDUR=='16') return;
					if (dis.currentDUR=='d8') return;
					if (dis.currentDUR=='t4') return;
					if (dis.currentDUR=='d4') return;
					//if (GS.currentDUR=='1') return;
					
					//clear out trip, dup stuff
					TUP = false;
					hideAllTupRs();
					
					dis.currentDUR = dis.currentDUR.replace('t','').replace('s','');
					
					rtog = !rtog;
					switch(rtog) {
						case true:
							dis.currentDUR = dis.currentDUR + '.';
							r.attr({opacity:0.1});
							break;
						case false:
							dis.currentDUR = dis.currentDUR.replace('.','');
							r.attr({opacity:0});
							break;
					}
				}
			)
			r.node.id = 'pointer';
		}
		
		Object.defineProperty(this, "dotted", {
			get: function() {return rtog;},
			set: function(v) {rtog = v; if (v) dotR.attr({opacity:0.1}); else dotR.attr({opacity:0});}
		})
		
		//dot
		apvg = bg.circle(drx+160,yst+30,4);
		apvg.transform('s0.5');
		new dotClicker(apvg,drx+160,yst+30);
		
		var ttog;
		var tieR;
		var tieClicker = function(ele,x,y) {
			var w = 30;
			var h = 30;
			tieR = bg.rect(x-w/2,y-h/2,30,30).attr({opacity:0});
			
			//make keyboard label
			var lbl = bg.text(x-w/2,yst,'[ 7 ]').attr({fontSize:'30px',fontFamily:'lato'});
			lbl.transform('t0 0 s0.4');
			lib.moveEle(lbl,-lbl.getBBox().width/2,0);
			
			var r = tieR;
			r.hover(
				function() {
					if (dis.disabled) return;
					if (!CANEDIT) return;
					r.attr({opacity:0.1});
				},
				function() {
					if (!CANEDIT) return;
					if (!ttog)
						r.attr({opacity:0});
				}
			)
			r.click(
				function() {
					if (dis.disabled) return;
					if (!CANEDIT) return;
					dis.DB.dotted = false;
					dis.DB.tied = !dis.DB.tied;
				}
			)
			r.node.id = 'pointer';
		}
		
		Object.defineProperty(this, "tied", {
			get: function() {return ttog;},
			set: function(v) {
				ttog = v;
				
				if (v) tieR.attr({opacity:0.1});
				else tieR.attr({opacity:0});
				
				TIETIME = v;
				
				if (v) {
					hideAllRs();
					dis.removeTester()
				} else {
					dis.DB.setDur(dis.currentDUR);
					
					/*if (inputNote) {
						inputNote.dur = dis.currentDUR;
						if (MB) {
							if (!dis.measures[MB.meas].obs[MB.beat].note)
								dis.placeNote(MB,inputNote);
						}
					}*/
				}
			}
		})
		
		//tie
		var tx = drx+185;
		var ty = yst+15;
		apvg = lib.genTie(bg,tx,ty,tx+30,ty,1);
		new tieClicker(apvg,tx+15,ty+15);
		
		//divider
		var dvd = bg.rect(tx+35,yst,2,50);
		//var tpt = bg.text(tx+40,yst-5,'Tuplets').attr({fontFamily:'lato'});
		
		var getTuplet = function(type) {
			switch(type) {
				case '2':
					if (dis.currentDUR=='8') return 'd8';
					if (dis.currentDUR=='4') return 'd4';
					break;
				case '3':
					if (dis.currentDUR=='8') return 't8';
					if (dis.currentDUR=='4') return 't4';
					break;
				case '4':
					if (dis.currentDUR=='8') return 'f8';
					break;
				case '6':
					if (dis.currentDUR=='16') return 's16';
					break;
			}
		}
		
		var checkTuplet = function(type) {
			var ts = dis.params.tsig;
			//var mb = MB;
			//if (!MB) { mb = {}; mb.meas = 0; }
			var base = ts.split('/')[1];
			switch(type) {
				case '2':
					if (dis.currentDUR!='8') return 'no'; //only duple eighth notes
					if (base!='8') return 'tsig'; //only allowed in compound time
					break;
				case '3':
					if (dis.currentDUR!='8'&&dis.currentDUR!='4') return 'no'; //only eighth or quarter
					if (base=='8') return 'tsig'; //not allowed in compound time
					//if (dis.currentDUR=='4'&&base=='4') return 'tsig'; //not allowed in x/4
					break;
				case '4':
					if (dis.currentDUR!='8') return 'no'; //only quadruplet eighth notes
					if (base!='8') return 'tsig'; //only allowed in compound time
					break;
				case '6':
					if (dis.currentDUR!='16') return 'no'; //only sextuplet 16ths
					if (base=='8') return 'tsig'; //not allowed in compound 
					break;
			}
			return true;
		}
		
		var hideAllTupRs = function() {
			for (var i=0; i<tupRs.length; i++) {
				tupRs[i].attr({opacity:0});
				tupRs[i].tog = false;
			}
		}
		
		//now the tuplets
		var tupletFunction = function(type,r) {
			if (dis.disabled) return;
			if (TIETIME) return;
			if (!CANEDIT) return;
			
			//check for not allowed
			var chk = checkTuplet(type);
			if (chk=='no') {alert('This type of tuplet is not allowed.\nCurrently allowed types are:\nTriplet 8th, Duple 8th, Triplet Quarter, Quadruplet 8th, Sextuplet 16th'); return;}
			if (chk=='tsig') {alert('This type of tuplet is not allowed in the current time signature.'); return;}
			
			r.tog = !r.tog;
			
			switch(r.tog) {
				case true:
					dis.currentDUR = getTuplet(type);
					dis.DB.dotted = false;
					
					hideAllRs();
					hideAllTupRs();
					
					r.tog = true;
					
					var prIndex;
					if (dis.currentDUR.indexOf('16')!=-1) prIndex = 0;
					if (dis.currentDUR.indexOf('8')!=-1) prIndex = 1;
					if (dis.currentDUR.indexOf('4')!=-1) prIndex = 2;
					pRs[prIndex].attr({opacity:0.1});
					
					r.attr({opacity:0.1});
					break;
				case false:
					dis.currentDUR = '8';
					
					hideAllRs();
					break;
			}
		}

		var TUP = false;
		var tupR;
		var tupRs = [];
		var tptogs = [];
		var tupleClicker = function(ele,x,y,type) {
			var w = 45;
			var h = 45;
			var r = bg.rect(x-w/2,y-h/2,w,h).attr({opacity:0});
			var diz = this;
			tupRs.push(r);
			r.hover(
				function() {
					if (dis.disabled) return;
					if (TUP) return;
					if (TIETIME) return;
					if (!CANEDIT) return;
					r.attr({opacity:0.1});
				},
				function() {
					if (dis.disabled) return;
					if (TUP) return;
					if (TIETIME) return;
					if (!CANEDIT) return;
					if (!r.tog)
						r.attr({opacity:0});
				}
			)
			r.click(
				function() {
					tupletFunction(type,r);
				}
			)
			r.node.id = 'pointer';
		}
		
		//tuplets
		tx+=5;
		var trpX = tx+60;
		var trpY = yst+25;
		apvg = bg.text(trpX,trpY+10,'2').attr({fontStyle:'italic',fontSize:'26px'});
		apvg.transform('t'+(-apvg.getBBox().width/2)+' 0');
		bg.append(apvg);
		new tupleClicker(apvg,trpX,trpY,'2');
		//make keyboard label
		var lbl = bg.text(trpX-45/2+8,yst,'[ 8 ]').attr({fontSize:'30px',fontFamily:'lato'});
		lbl.transform('t0 0 s0.4');
		lib.moveEle(lbl,-lbl.getBBox().width/2,0);
		
		trpX+=45;
		apvg = bg.text(trpX,trpY+10,'3').attr({fontStyle:'italic',fontSize:'26px'});
		apvg.transform('t'+(-apvg.getBBox().width/2)+' 0');
		bg.append(apvg);
		new tupleClicker(apvg,trpX,trpY,'3');
		//make keyboard label
		var lbl = bg.text(trpX-45/2+8,yst,'[ 9 ]').attr({fontSize:'30px',fontFamily:'lato'});
		lbl.transform('t0 0 s0.4');
		lib.moveEle(lbl,-lbl.getBBox().width/2,0);
		
		trpX+=45;
		apvg = bg.text(trpX,trpY+10,'4').attr({fontStyle:'italic',fontSize:'26px'});
		apvg.transform('t'+(-apvg.getBBox().width/2)+' 0');
		bg.append(apvg);
		new tupleClicker(apvg,trpX,trpY,'4');
		//make keyboard label
		var lbl = bg.text(trpX-45/2+8,yst,'[ 0 ]').attr({fontSize:'30px',fontFamily:'lato'});
		lbl.transform('t0 0 s0.4');
		lib.moveEle(lbl,-lbl.getBBox().width/2,0);
		
		trpX+=45;
		apvg = bg.text(trpX,trpY+10,'6').attr({fontStyle:'italic',fontSize:'26px'});
		apvg.transform('t'+(-apvg.getBBox().width/2)+' 0');
		bg.append(apvg);
		new tupleClicker(apvg,trpX,trpY,'6');
		//make keyboard label
		var lbl = bg.text(trpX-45/2+8,yst,'[ - ]').attr({fontSize:'30px',fontFamily:'lato'});
		lbl.transform('t0 0 s0.4');
		lib.moveEle(lbl,-lbl.getBBox().width/2,0);
		
		this.setDur = function(d) {
			if (d.indexOf('.')==-1) dis.DB.dotted = false;
			else dis.DB.dotted = true;
			for (var i=0; i<pRs.length; i++)
				if (pRs[i].rt==dis.currentDUR.replace('.',''))
					pRs[i].attr({opacity:0.1});
			hideAllRs();
			hideAllTupRs();
		}
		
		bg.transform('t0 0');
		this.whole = bg;
		
		this.move = function(x) {
			bg.transform('t'+x+' 0');
		}
		
		this.width = bg.getBBox().width;
		
		var wc = dis.container;
		wc.onkeydown = function(evt) {
			if (!CANEDIT) return;
			if (TIETIME&&evt.key!='7') {
				if (evt.key==' ') {inputSpace(); evt.preventDefault();}
				return;
			}
			switch(evt.keyCode) {
				case 38:
					inputUp();
					evt.preventDefault();
					return;
				case 40:
					inputDown();
					evt.preventDefault();
					return;
				default:
					break;
			}
			switch(evt.key) {
				case 'Tab':
					if (evt.shiftKey)
						backTab();
					else
						inputTab();
					return;
				case ' ':
					inputSpace();
					evt.preventDefault();
					return;
				case 'o':
					var newNote = inputUp();
					noteChange(newNote);
					return;
				case 'l':
					var newNote = inputDown();
					noteChange(newNote);
					return;
				case 'd':
					inputD();
					return;
				case 's':
					inputAxe('♯');
					return;
				case 'f':
					inputAxe('\u0231');
					return;
				case 'x':
					inputAxe('x');
					return;
				case 'w':
					inputAxe('bb');
					return;
				case 'n':
					inputAxe('n');
					return;
				case '1':
					dis.currentDUR = '16';
					DB.dotted = false;
					durationChange(dis.currentDUR);
					break;
				case '2':
					dis.currentDUR = '8';
					DB.dotted = false;
					durationChange(dis.currentDUR);
					break;
				case '3':
					dis.currentDUR = '4';
					DB.dotted = false;
					durationChange(dis.currentDUR);
					break;
				case '4':
					dis.currentDUR = '2';
					DB.dotted = false;
					durationChange(dis.currentDUR);
					break;
				case '5':
					dis.currentDUR = '1';
					DB.dotted = false;
					durationChange(dis.currentDUR);
					break;
				case '6':
					if (dis.currentDUR=='t8') return;
					if (dis.currentDUR=='16') return;
					if (dis.currentDUR=='d8') return;
					if (dis.currentDUR=='t4') return;
					if (dis.currentDUR=='d4') return;
					DB.dotted = !DB.dotted;
					switch(DB.dotted) {
						case true:
							dis.currentDUR = dis.currentDUR + '.';
							break;
						case false:
							dis.currentDUR = dis.currentDUR.replace('.','');
							break;
					}
					durationChange(dis.currentDUR);
					break;
				case '7':
					DB.dotted = false;
					DB.tied = !DB.tied;
					tieChange(DB.tied);
					return;
					break;
				case '8':
					tupletFunction('2',tupRs[0]);
					if (inputNote) {
						inputNote.dur = dis.currentDUR;
					}
					durationChange(dis.currentDUR);
					return;
					break;
				case '9':
					tupletFunction('3',tupRs[1]);
					if (inputNote) {
						inputNote.dur = dis.currentDUR;
					}
					durationChange(dis.currentDUR);
					return;
					break;
				case '0':
					tupletFunction('4',tupRs[2]);
					if (inputNote) {
						inputNote.dur = dis.currentDUR;
					}
					durationChange(dis.currentDUR);
					return;
					break;
				case '-':
					tupletFunction('6',tupRs[3]);
					if (inputNote) {
						inputNote.dur = dis.currentDUR;
					}
					durationChange(dis.currentDUR);
					return;
					break;
				default:
					return;
			}
			
			if (evt.key!='7') {
				DB.setDur(dis.currentDUR);
				
				if (inputNote) {
					inputNote.dur = dis.currentDUR;
				}
			}
		}
	}

	var keyChangeAlert;
	var durationChange = function(dur) {
		if (keyChangeAlert) {
			document.body.removeChild(keyChangeAlert);
		}
		keyChangeAlert = document.createElement("p");
		keyChangeAlert.setAttribute("role", "alert");
		var keyChangeAlertText = '';//document.createTextNode("Duration set to "+nType(dur)+" note");
		keyChangeAlert.appendChild(keyChangeAlertText);
		document.body.appendChild(keyChangeAlert);
		keyChangeAlert.style.left = '-99999px';
		keyChangeAlert.style.position = 'absolute';
	}

	var noteChangeAlert;
	var noteChange = function(note) {
		var ele = document.getElementById(dis.container.id+'-notechange');
		ele.innerHTML = "";//"Input note set to "+accessibleName(note);
	}

	var accessAlert;
	var tieChange = function(tieState) {
		if (accessAlert) {
			document.body.removeChild(accessAlert);
		}
		accessAlert = document.createElement("p");
		accessAlert.setAttribute("role", "alert");
		var text = tieState ? "on" : "off";
		var accessAlertText = document.createTextNode("Tied note input toggled "+text);
		accessAlert.appendChild(accessAlertText);
		document.body.appendChild(accessAlert);
		accessAlert.style.left = '-99999px';
		accessAlert.style.position = 'absolute';
	}
	
	var wc = this.container;//getByClass(this.container,'widget-holder-input');
	var ERRORS = [];
	wc.onscroll = function(dx) {
		dis.DB.move(wc.scrollLeft);
		for (var i=0; i<ERRORS.length; i++) {
			ERRORS[i].style.left = (ERRORS[i].keepLeft-wc.scrollLeft)+'px';
		}
	}
	wc.onmousemove = function() {
		wc.focus();
	}
	wc.onmouseover = function() {
		wc.focus();
	}
	wc.onmouseenter = function() {
		wc.focus();
	}
	wc.onmouseout = function() {
		wc.blur();
	}

	wc.onfocus = function() {
		key_coord = {m:0,b:-1,n:'B4'};
		console.log('resetting shit');
	}
	
	//DISPLAY SHIT
	this.drawRests = function() {
		var obs;
		for (var i=0; i<this.measures.length; i++) {
			obs = this.measures[i].obs;
			for (var j=0; j<obs.length; j++) {
				if (obs[j].rest) {
					obs[j].rest.remove();
					delete obs[j].rest;
				}
				if (!obs[j].note)
					lib.drawRest(this,this.whole,obs[j],i);
			}
		}
	}
	
	this.removeRests = function() {
		var ts, bs;
		for (var i=0; i<this.measures.length; i++) {
			bs = this.measures[i].obs;
			for (var j=0; j<bs.length; j++) {
				if (bs[j].rest) {
					bs[j].rest.remove();
					delete bs[j].rest;
				}
			}
		}
	}
	
	function axeWidth(mb) {
		if (!mb.note) return 0;
		if (!mb.axe) return 0;
		return mb.axe.getBBox().width;
	}
	
	this.recalculateXs = function() { //calculates the Xs for spacing
		var tB = 1, bB = 1;
		var tI = 1, bI = 1;
		var tD, bD;
		var beatX;
		var tLMB, bLMB;
		
		var curB = 0; //if start at 0, then in while loop can just start indices at 0
		
		//while still have members of array
		var tbs, bbs;
		var tsig = this.params.tsig; //initial time sig
		for (var i=0; i<this.measures.length; i++) { //loop through measures
			bbs = this.measures[i].obs;
			
			bI = 1;
			
			//if start of a new measure
			if (i>0) {
				bLMB = this.measures[i-1].obs[this.measures[i-1].obs.length-1];
				bbs[0].x = bLMB.x + lib.noteW(bLMB.dur) + lib.newX(bLMB.dur) + barlineMARGIN + 1 + axeWidth(bbs[0]);
			}
			
			while( bbs[bI] ) { //while there is a treble or bass measure-beat
				bbs[bI].x = bbs[bI-1].x + lib.noteW(bbs[bI-1].dur) + lib.newX(bbs[bI-1].dur) + axeWidth(bbs[bI]);
				bI++;
			}
			
			this.measures[i].left = bbs[0].x; //update left of measure
			
			if (i==this.measures.length-1) {
				bLMB = this.measures[i].obs[this.measures[i].obs.length-1];
				this.measures[i].right = bLMB.x + lib.noteW(bLMB.dur) + lib.newX(bLMB.dur) + barlineMARGIN + 1 + axeWidth(bLMB);
			} else {
				bLMB = this.measures[i].obs[this.measures[i].obs.length-1];
				this.measures[i].right = bLMB.x + lib.noteW(bLMB.dur) + lib.newX(bLMB.dur) + 1 + axeWidth(bLMB);
			}
		}
	}
	
	this.recalculateCXs = function() { //calculates the CXs for placing notes
		var ary;
		var ms = this.measures;
		var eleW;
		for (var i=0; i<ms.length; i++) {
			
				ary = ms[i].obs;
				for (var k=1; k<ary.length; k++) { //looping through measure-beats
					eleW = 0;//lib.noteW(ary[i].dur) + lib.newX(ary[i].dur);
					if (ary[k-1].rest) eleW = ary[k-1].rest.getBBox().width;
					if (ary[k-1].snapNote) eleW = ary[k-1].snapNote.getBBox().width;
					ary[k-1].cx = ( ary[k-1].x + eleW + ary[k].x ) / 2;
				}
				if (ms[i+1])
					ary[ary.length-1].cx = ms[i+1].obs[0].x - (barlineMARGIN+1);
		}
	}
	
	this.redrawBarlines = function() { //draws the barlines and sizes the staffs
		var blns = this.barlines;
		for (var i=0; i<blns.length; i++) {
			blns[i].remove();
		}
		blns = [];
		this.barlines = [];
		
		for (var i=1; i<this.measures.length; i++) {
			this.drawBarline(this.measures[i].left-(barlineMARGIN+1+axeWidth(this.measures[i].obs[0])));
		}
	}
	
	this.transformNote = function(mb) {
		var note = mb.note;
		var x = mb.note.x;
		var nx = mb.x;
		var dx = nx - x;
		
		lib.moveEle(note,dx,0);
		if (mb.stem)
			lib.moveEle(mb.stem,dx,0);
		if (mb.flag)
			lib.moveEle(mb.flag,dx,0);
		if (mb.axe)
			lib.moveEle(mb.axe,dx,0);
		if (mb.beam)
			lib.moveEle(mb.beam,dx,0);
		if (mb.beam2)
			lib.moveEle(mb.beam2,dx,0);
		if (mb.ledgers) {
			for (var i=0; i<mb.ledgers.length; i++)
				lib.moveEle(mb.ledgers[i],dx,0);
		}
		if (mb.axeMenu) {
			mb.axeMenu.move(dx);
		}
		if (mb.tieF) {
			if (TIESMOVED.indexOf(mb.tieF)==-1) {
				lib.moveEle(mb.tieF,dx,0);
				TIESMOVED.push(mb.tieF);
			}
		}
		if (mb.tieB) {
			if (TIESMOVED.indexOf(mb.tieB)==-1) {
				lib.moveEle(mb.tieB,dx,0);
				TIESMOVED.push(mb.tieB);
			}
		}
		if (mb.t3) {
			if (THREESMOVED.indexOf(mb.t3)==-1) {
				lib.moveEle(mb.t3,dx,0);
				THREESMOVED.push(mb.t3);
			}
		}
		if (mb.dot)
			lib.moveEle(mb.dot,dx,0);
		
		mb.note.x = nx;
		mb.x = nx;
	}
	
	this.transformAllNotes = function() {
		var ary;
		TIESMOVED = [];
		THREESMOVED = [];
		for (var i=0; i<this.measures.length; i++) {
			ary = this.measures[i].obs;
			for (var k=0; k<ary.length; k++) {
				if (ary[k].note) {
					this.transformNote(ary[k]);
				}
			}
		}
	}
	
	this.recalculateDisplay = function() {
		//calculate xs
		this.recalculateXs();
		
		width = this.measures[this.measures.length-1].right;
		//staff line
		lib.drawStaffLines(this,width);
		
		//move all existing notes
		this.transformAllNotes();
		
		//barlines
		this.redrawBarlines();
		
		//beam notes
		this.beamNotes();
		
		//draw rests
		this.drawRests();
		
		//calculate cxs
		this.recalculateCXs();
		
		//clear cover for interactivity
		this.cr.attr({width:width});

		//put everything in the correct tab order (append to main svg from left to right), then append cover
		this.appendAll();
		
		S.attr({width:Math.max(width,dis.DB.width+25)});
	}

	var nprops = ['beat','iBeat','measure','dur','voice','name','staff','type'];
	var rprops = ['beat','dur','iBeat','measure','voice','type'];
	this.save = function() {
		var obj = JSON.parse(JSON.stringify(this.params));
		obj.melody = [];
		obj.type = 'Melodic Dictation';
		for (var i=0; i<this.measures.length; i++) {
			obj.melody.push([]);
			for (var j=0; j<this.measures[i].obs.length; j++) {
				var ob = this.measures[i].obs[j];
				var nbj = {};
				if (ob.note) {
					for (var k=0; k<nprops.length; k++)
						nbj[nprops[k]] = ob[nprops[k]];
					if (ob.tied) nbj.tied = true;
				}
				if (ob.rest) {
					for (var k=0; k<rprops.length; k++)
						nbj[rprops[k]] = ob[rprops[k]];
				}
				obj.melody[i].push(nbj);
			}
		}
		return obj;
	}

	this.promptMelody = function() {
		var melody = [];
		var ary = [];
		var obs = this.measures[0].obs; //stuff in first measure
		var ob;
		var nbj;

		//find first note in measure
		var beat = obs[0].beat;
		var dur = 0;
		for (var i=0; i<obs.length; i++) {
			ob = obs[i];
			nbj = {};
			//if rest, put in
			if (ob.rest) {
				for (var k=0; k<rprops.length; k++)
					nbj[rprops[k]] = ob[rprops[k]];
				beat += lib.rBeat(dis,nbj.dur);
				dur += lib.rBeat(dis,nbj.dur);

				beat = lib.quantBeat(beat);
				dur = lib.quantBeat(dur);

				ary.push(nbj);
			}

			//if note, put in and stop
			if (ob.note) {
				for (var k=0; k<nprops.length; k++)
					nbj[nprops[k]] = ob[nprops[k]];
				beat += lib.rBeat(dis,nbj.dur);
				dur += lib.rBeat(dis,nbj.dur);

				beat = lib.quantBeat(beat);
				dur = lib.quantBeat(dur);

				ary.push(nbj);
				i = 1000000;
			}
		}

		//check if has tuplet
		var tupTypes = ['s16','t8','t4','d4','d8','f8'];
		var hasT = false;
		for (var i=0; i<ary.length; i++) {
			if (tupTypes.indexOf(ary[i].dur)!=-1) {
				hasT = true; //has a tuplet
			}
		}
		if (hasT) { //if we has tuplet, fill with rests to next integer beat, track dur
			var beatRemain = lib.quantBeat(1 - (beat-Math.floor(beat)));
			var iBeat = ary.length-1;
			var lastNote = ary[iBeat];
			if (beatRemain<1) {
				while(beatRemain>0) {
					nbj = {type:'rest',dur:lastNote.dur,beat:beat,iBeat:iBeat,voice:ary[0].voice,measure:0};
					beat += lib.rBeat(dis,lastNote.dur);
					dur += lib.rBeat(dis,lastNote.dur);
					beatRemain -= lib.rBeat(dis,lastNote.dur);

					beat = lib.quantBeat(beat);
					dur = lib.quantBeat(dur);
					beatRemain = lib.quantBeat(beatRemain);
					ary.push(nbj);
				}
			}
		}

		var remainder = findRemainder(this.measures[0].duration-dur);
		var iBeat = ary.length-1;
		var voice = ary[0].voice;
		for (var i=0; i<remainder.length; i++) {
			ary.push({beat:beat,iBeat:iBeat,measure:0,dur:remainder[i],voice:voice,type:'rest'});
			beat += lib.rBeat(dis,remainder[i]);
			iBeat++;
		}

		melody.push(ary);

		if (this.measures.length==1) return melody;
		//all other measures are empty

		var fullMeasure = wholeMeasure();
		var mAry;
		for (var i=1; i<this.measures.length; i++) {
			beat = 1;
			mAry = [];

			//get full measure, init
			for (var j=0; j<fullMeasure.length; j++) {
				mAry.push({beat:beat,iBeat:j,measure:i,dur:fullMeasure[j].dur,voice:voice,type:'rest'});
				beat += lib.rBeat(dis,fullMeasure[j].dur);
			}

			melody.push(mAry);
		}

		return melody;
	}

	this.blankMelody = function() {
		var melody = [];

		var mAry;
		var pOff = 0;
		var beat, iBeat;
		var voice = this.measures[0].obs[0].voice;
		if (this.params.pickup!=0) {
			mAry = [];
			pOff = 1;
			beat = findBeatStart();
			var remainder = findRemainder(this.measures[0].duration);
			iBeat = 0;
			for (var i=0; i<remainder.length; i++) {
				mAry.push({beat:beat,iBeat:iBeat,measure:0,dur:remainder[i],voice:voice,type:'rest'});
				beat += lib.rBeat(dis,remainder[i]);
				iBeat++;
			}

			melody.push(mAry);
		}

		var fullMeasure = wholeMeasure();
		for (var i=pOff; i<this.measures.length; i++) {
			beat = 1;
			mAry = [];

			//get full measure, init
			for (var j=0; j<fullMeasure.length; j++) {
				mAry.push({beat:beat,iBeat:j,measure:i,dur:fullMeasure[j].dur,voice:voice,type:'rest'});
				beat += lib.rBeat(dis,fullMeasure[j].dur);
			}

			melody.push(mAry);
		}

		return melody;
	}

	this.load = function(parameters) {
		this.loadMeasures(parameters);

		var melody = parameters.melody;
		var props = ['name','staff','type','tied'];
		//melody is array of measurebeats, change measurebeats to what's in melody
		this.removeRests();

		//load notes from the end, because they won't be affected by accidentals before them
		var mlen = this.measures.length-1;
		for (var i=mlen; i>-1; i--) {
			var oblen = this.measures[i].obs.length-1;
			for (var j=oblen; j>-1; j--) {
				
				//get name, staff, and type
				for (var k=0; k<props.length; k++) {
					if (!melody[i][j][props[k]]) continue;
					this.measures[i].obs[j][props[k]] = melody[i][j][props[k]];
				}

				if (this.measures[i].obs[j].type=='note') {
					this.placeNote(this.measures[i].obs[j],true);
				}
			}
		}

		//go through from beginning and update accidentals
		for (var i=0; i<this.measures.length; i++) {
			for (var j=0; j<this.measures[i].obs.length; j++) {
				if (this.measures[i].obs[j].type=='note') {
					axeUpdate(this.measures[i].obs[j]);
				}
			}
		}

		this.recalculateDisplay();
		this.center();

		//draw ties
		for (var i=0; i<this.measures.length; i++) {
			for (var j=0; j<this.measures[i].obs.length; j++) {
				if (this.measures[i].obs[j].tied)
					lib.handleTie(this,this.measures[i].obs[j]);
			}
		}
	}
	
	//center it
	this.center = function() {
		var bb = this.whole.getBBox();
		var w = bb.width;
		var h = bb.height;
		var botMargin = 0;
		this.whole.transform('t0 '+(100+botMargin));
		
		S.attr('height',h+(botMargin));
	}

	var S;
	var g;
	var hnotes;
	var measures;
	var tsMargin;
	var nts, mids;
	var TIESMOVED;
	var THREESMOVED;
	this.initializeMeasures = function(parameters) {
		this.lnsp = 17.75;
		//keep params
		
		//parse pickup
		parameters.pickup = parseFloat(parameters.pickup);
		
		this.params = parameters;

		var pele = document.getElementById(id);
		pele.innerHTML = '';

		//this.audioManager = new audioManager(parameters);

		var pid = id+'-music';
		var ele = document.createElement('div');
		ele.id = pid;
		ele.className = 'noizy-musicholder';
		ele.tabIndex = "0";
		/*ele.onmouseover = function() {
			ele.focus();
		}*/
		ele.onmouseout = function() {
			ele.blur();
		}
		ele.onblur = function() {
			dis.removeTester();
		}
		pele.appendChild(ele);

		S = lib.makeSnap(pid, 100, 500);
		this.paper = S;

		//main svg group
		g = S.group();
		this.whole = g;

		//initialize clef
		if (this.params.clef)
			lib.drawClef(this,this.params.clef);
		
		hnotes = hnotes1;
		if (this.params.clef=='Bass') hnotes = hnotes2;
		if (this.params.clef=='Alto') hnotes = hnotes3;

		//key signature
		this.keyAx = [];
		lib.keySig(this,this.params.clef,this.params.key,this.params.mode);
		
		//time signature
		lib.drawTimeSignature(this,this.params.tsig);

		this.moveTSIG();

		TIESMOVED = [];
		THREESMOVED = [];

		//INITIALIZE MEASURES
		//initialize measure array
		this.barlines = [];
		
		measures = [];
		//pickup here?
		var beatStart;
		var pickup;
		if (this.params.pickup) {
			this.pickup = lib.findPickup(this,this.params.pickup); //get a blank pickup measure
			measures.push(this.pickup);
			beatStart = findBeatStart();//this.pickup,this.params.tsig);
		}
		var mAry;
		for (var i=0; i<this.params.length; i++) {
			mAry = wholeMeasure();
			measures.push(mAry);
		}
		
		//create measures
		tsMargin = 35;
		var x = this.timeRight()+tsMargin;
		var mbj;
		this.measures = [];
		var bstart;
		for (var i=0; i<measures.length; i++) {
			if (this.params.pickup) {
				if (i==0) bstart = beatStart;
				else bstart = 1;
			} else {
				bstart = 1;
			}

			mbj = new measureObj(measures[i],x,i,bstart);
			this.measures.push(mbj);
			
			x = mbj.right; //end of measure
			
			x+=barlineMARGIN;
		}

		switch(this.params.clef) {
			case 'Treble':
				nts = ['F3','G3','A3','B3','C4','D4','E4','F4','G4','A4','B4','C5','D5','E5','F5','G5','A5','B5','C6','D6','E6']; //treble notes
				mids = [53,55,57,59,60,62,64,65,67,69,71,72,74,76,77,79,81,83,84,86,88];
				break;
			case 'Bass':
				nts = ['A1','B1','C2','D2','E2','F2','G2','A2','B2','C3','D3','E3','F3','G3','A3','B3','C4','D4','E4','F4','G4','A4']; //bass notes
				mids = [33,35,36,38,40,41,43,45,47,48,50,52,53,55,57,59,60,62,64,65,67,69];
				break;
			case 'Alto':
				nts = ['G2','A2','B2','C3','D3','E3','F3','G3','A3','B3','C4','D4','E4','F4','G4','A4','B4','C5','D5','E5','F5','G5']; //bass notes
				mids = [43,45,47,48,50,52,53,55,57,59,60,62,64,65,67,69,71,72,74,76,77,79];
				break;
		}

		//put rectangle over it for interactivity
		this.interactiveMeasures();

		//rhythm palette
		DB = new DURbutton(0,180);
		this.DB = DB;

		//recalculate x, cx, etc.
		this.recalculateDisplay();
		this.center();
	}

	this.loadMeasures = function(parameters) {
		this.lnsp = 17.75;
		//keep params
		
		//parse pickup
		parameters.pickup = parseFloat(parameters.pickup);
		
		this.params = parameters;

		var pele = document.getElementById(id);
		pele.innerHTML = '';

		//this.audioManager = new audioManager(parameters);

		var pid = id+'-music';
		var ele = document.createElement('div');
		ele.id = pid;
		ele.className = 'noizy-musicholder';
		ele.tabIndex = "0";
		/*ele.onmouseover = function() {
			ele.focus();
		}*/
		ele.onmouseout = function() {
			ele.blur();
		}
		ele.onblur = function() {
			dis.removeTester();
		}
		pele.appendChild(ele);

		S = lib.makeSnap(pid, 100, 500);
		this.paper = S;

		var ary = this.params.melody;

		//main svg group
		g = S.group();
		this.whole = g;

		//initialize clef
		if (this.params.clef)
			lib.drawClef(this,this.params.clef);
		
		hnotes = hnotes1;
		if (this.params.clef=='Bass') hnotes = hnotes2;
		if (this.params.clef=='Alto') hnotes = hnotes3;

		//key signature
		this.keyAx = [];
		lib.keySig(this,this.params.clef,this.params.key,this.params.mode);
		
		//time signature
		lib.drawTimeSignature(this,this.params.tsig);

		this.moveTSIG();

		TIESMOVED = [];
		THREESMOVED = [];

		this.barlines = [];

		//INITIALIZE MEASURES
		//initialize measure array
		
		measures = [];
		//pickup here?
		var beatStart;
		var poff = 0;
		if (this.params.pickup) {
			this.pickup = ary[0]; //get first measure stored in melody
			measures.push(this.pickup);
			beatStart = findBeatStart();//pickup,this.params.tsig);
			poff = 1;
		}
		var mAry;
		for (var i=0; i<this.params.length; i++) {
			mAry = ary[i+poff];
			measures.push(mAry);
		}
		
		//create measures
		tsMargin = 35;
		var x = this.timeRight()+tsMargin;
		var mbj;
		this.measures = [];
		var bstart;
		for (var i=0; i<measures.length; i++) {
			if (this.params.pickup) {
				if (i==0) bstart = beatStart;
				else bstart = 1;
			} else {
				bstart = 1;
			}

			mbj = new measureObj(measures[i],x,i,bstart);
			this.measures.push(mbj);
			
			x = mbj.right; //end of measure
			
			x+=barlineMARGIN;
		}

		switch(this.params.clef) {
			case 'Treble':
				nts = ['F3','G3','A3','B3','C4','D4','E4','F4','G4','A4','B4','C5','D5','E5','F5','G5','A5','B5','C6','D6','E6']; //treble notes
				mids = [53,55,57,59,60,62,64,65,67,69,71,72,74,76,77,79,81,83,84,86,88];
				break;
			case 'Bass':
				nts = ['A1','B1','C2','D2','E2','F2','G2','A2','B2','C3','D3','E3','F3','G3','A3','B3','C4','D4','E4','F4','G4','A4']; //bass notes
				mids = [33,35,36,38,40,41,43,45,47,48,50,52,53,55,57,59,60,62,64,65,67,69];
				break;
			case 'Alto':
				nts = ['G2','A2','B2','C3','D3','E3','F3','G3','A3','B3','C4','D4','E4','F4','G4','A4','B4','C5','D5','E5','F5','G5']; //bass notes
				mids = [43,45,47,48,50,52,53,55,57,59,60,62,64,65,67,69,71,72,74,76,77,79];
				break;
		}

		//put rectangle over it for interactivity
		this.interactiveMeasures();

		//rhythm palette
		DB = new DURbutton(0,180);
		this.DB = DB;

		this.currentDUR = '4';
	}

	//this.initializeMeasures(this.params);
}