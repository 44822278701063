import { useSearchParams } from 'react-router-dom';

export default function InvalidScope() {
    const [searchParams, setSearchParams] = useSearchParams();

    return (
        <div>
            <p>There was a problem retrieving your courses. Please see below for more information:</p>
            <p>{searchParams.get('error_description')}</p>
            <a href="mailto:cameron@eartrainer.io"><button>Contact Support</button></a>
        </div>
    )
}