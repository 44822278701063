import { useParams, useNavigate } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import Canvas from './../../icons/Canvas.js';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setQuestions, clearQuestions } from './../../../slices/question/questionSlice';
import { assignmentAdded, assignmentUpdated } from './../../../slices/assignment/assignmentSlice';
import { changeType, changeIndex } from './../../../slices/editor/editorSlice';
import { nanoid } from '@reduxjs/toolkit';
import toast, { Toaster } from 'react-hot-toast';
import rhythmicDictation from './../../lib/teacher/rhythmicdictation.js';
import melodicDictation from './../../lib/teacher/melodicdictation.js';
import axios from 'axios';
import DatePicker from 'react-date-picker';
import Loader from './../../Loader.js';

export default function AssignmentEditor() {
    const assignments = useSelector(state => state.assignments)
    const courses = useSelector(state => state.courses)
    const [loading, setLoading] = useState(false)
    const [saving, setSaving] = useState(false)
    const { assignmentid, courseid } = useParams()
    const assTitle = useRef('')
    const navigate = useNavigate()

    const dispatch = useDispatch()
    const setType = (type) => {
        dispatch(
            changeType(type)
        )
        dispatch(
            clearQuestions()
        )
    }

    useEffect(()=>{
        if (assignmentid) {
            let assignment = assignments.find((ass)=>ass.id==assignmentid)
            //let res = await axios.get('/get-assignment?id='+assignmentid);
            //let assignment = res.data;
            dispatch(
                setQuestions(assignment.questions)
            )
            dispatch(
                changeIndex(0)
            )
            setLoading(false)
        } else {
            //start out with nothing
            console.log('start fresh')
            setLoading(false)
        }

        return () => {
            dispatch(clearQuestions())
            dispatch(changeIndex(null))
        }
    },[])

    if (loading) return <div>Loading</div>
    if (saving) return <div>Saving</div>

    let assignment = assignments.find((ass)=>ass.id==assignmentid)
    let course = courses.find((ass)=>ass.id==courseid)
    let title = assignment ? assignment.name : "";
    let dueDate = assignment ? assignment.dueDate : "";
    window.assignmentDueDate = dueDate;
    return (
        <div>
            <div className="course-topbar">
                <div style={{fontSize:'1.25em',fontWeight:'bold',padding:'0.5em',display:'flex',alignItems:'center',gap:'5px'}}>
                    <div style={{color:"#0374B5",cursor:'pointer'}} onClick={()=>{navigate('/courses/'+courseid+'/assignments')}}>{course.name}</div>
                    <div>{">"}</div>
                    <div>{assignmentid?"Edit Assignment":"Create Assignment"}</div>
                </div>
                {course.linked && 
                <div style={{display:'flex',alignItems:'center',gap:'0.5em',marginRight:'1em'}}>
                    <div>Linked to</div>
                    <Canvas/>
                </div>
                }
            </div>
            <div className="assignment-creator">
                <div className="left-side">
                    <div>
                        <div style={{padding:'5px',display:'flex',flexDirection:'column',gap:'0.5em',borderBottom:'1px solid lightgray'}}>
                            <div>
                                <div className="overtext" style={{fontSize:'16px'}}>Title</div>
                                <input style={{width:'calc(100% - 8px)',fontSize:'18px',height:'23px',lineHeight:'20px'}} id="assignmentTitle" placeholder="An amazing title" defaultValue={title} type="text"/>
                            </div>
                            <div>
                                <div className="overtext" style={{fontSize:'16px'}}>Type</div>
                                <select style={{width:'100%',fontSize:'18px',height:'29px',lineHeight:'20px'}} id="assignmentType" onChange={(e)=>{setType(e.target.value)}}>
                                    <option>Ear Training</option>
                                    <option>Singing</option>
                                    <option>Counterpoint</option>
                                    <option>Analysis</option>
                                </select>
                            </div>
                            <div>
                                <div className="overtext" style={{fontSize:'16px'}}>Due Date</div>
                                <DueDate dueDate={dueDate}/>
                            </div>
                        </div>
                        <QuestionNav/>
                    </div>
                    <div>
                        <SaveAndPreview setSaving={setSaving}/>
                    </div>
                </div>
                <div style={{padding:'10px'}}>
                    <Instructions/>
                    <div className="recording-settings-bar">
                        <Recording/>
                        <QuestionSettings/>
                    </div>
                    <MusicEditor/> 
                </div>
                <Toaster/>
            </div>
        </div>
    )
}

function SaveAndPreview(props) {
    const editor = useSelector(state => state.editor)
    const questions = useSelector(state => state.questions)
    const courses = useSelector(state => state.courses)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { courseid, assignmentid } = useParams()
    let CourseId = courseid;

    const saveAssignment = async () => {
        //check inputs
        if (!window.assignmentDueDate) {
            toast.error('Please specify a due date!');
            return;
        }

        //if all good, then save
        let data = JSON.parse(JSON.stringify(questions));
        let currentQuestion = data[editor.index];
        if (currentQuestion) {
            //remove url in case recording was removed
            delete currentQuestion.url;

            //settings
            let settings = {
                listenings: document.getElementById('question-listenings').value,
                retries: document.getElementById('question-retries').value,
                firstNoteGiven: document.getElementById('question-firstnotegiven').value,
            }

            currentQuestion.musicJSON = window.musicEditor.save(settings);
            //if recording, save its url
            if (document.getElementById('recordingElement'))
                currentQuestion.url = document.getElementById('recordingElement').src;
            currentQuestion.instructions = document.getElementById('question-instructions').value;

            //settings
            currentQuestion.settings = settings;
        }

        //questions is data
        let title = document.getElementById('assignmentTitle').value;
        let qstns = data;
        let dueDate = window.assignmentDueDate;
        let module = editor.type;
        let settings = {};

        //prepare database object
        let abj = {};
        abj.id = assignmentid;
        abj.name = title;
        abj.dueDate = dueDate;
        abj.module = ['Ear Training','Singing','Counterpoint','Analysis'].indexOf(module) + 1;
        abj.published = true;
        abj.questions = qstns;
        abj.CourseId = CourseId;

        props.setSaving(true);

        let course = courses.find((crs)=>crs.id==courseid);
        let res;
        var assignmentConnection = course.linked ? 'lms' : 'none';
        if (assignmentid) {
            switch(assignmentConnection) {
                case 'lms':
                    await axios.post('/api/lms/update-lms-assignment',abj);
                    break;
                case 'none':
                    await axios.post('/api/dashboard/update-assignment',abj);
                    break;
            }   
            dispatch(
                assignmentUpdated({id:assignmentid, name:title, settings:settings, module:module, questions:qstns, dueDate:dueDate+''})
            )
        } else {
            switch(assignmentConnection) {
                case 'lms':
                    res = await axios.post('/api/lms/create-lms-assignment',abj);
                    break;
                case 'none':
                    res = await axios.post('/api/dashboard/create-assignment',abj);
                    break;
            }
            dispatch(
                assignmentAdded(res.data)
            )
        }

        //back to assignments list
        navigate('/courses/'+courseid+'/assignments')
    }

    return (
        <div style={{marginBottom:'1em',paddingLeft:'5px',paddingRight:'5px'}}>
            <div style={{display:'flex',justifyContent:'center'}}>
                <button style={{width:'100%'}}>Preview</button>
            </div>
            <div>
                <button onClick={()=>{saveAssignment()}} style={{width:'100%'}}>Save</button>
            </div>
        </div>
    )
}

function DueDate(props) {
    const [date, setDate] = useState(props.dueDate)

    const setDueDate = (e) => {
        setDate(e);
        window.assignmentDueDate = e;
    }
    
    return (
        <DatePicker dayPlaceholder="dd" monthPlaceholder="mm" yearPlaceholder="yyyy" onChange={(e)=>{setDueDate(e)}} value={date}/>
    )
}

/*class DueDate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {date:null}
        this.changeDate = this.changeDate.bind(this);
    }

    changeDate(e) {
        this.setState({date:e})
    }

    render() {
        return (
            <DatePicker dayPlaceholder="dd" monthPlaceholder="mm" yearPlaceholder="yyyy" onChange={this.changeDate} value={this.state.date}/>
        )
    }
}*/

function Recording() {
    const [status, setStatus] = useState(null)
    const [open, setOpen] = useState(false)
    const [openGenerate, setOpenGenerate] = useState(false)
    const menuRef = useRef()
    const editor = useSelector(state => state.editor)
    const questions = useSelector(state => state.questions)
    const question = questions[editor.index]
    const [url, setUrl] = useState(question?question.url:null)

    const openMenu = () => {
        setOpen(true);
    }
    useEffect(()=>{
        if (!menuRef.current) return;
        let handler = (e) => {
            if (!menuRef.current.contains(e.target))
                setOpen(false)
        }

        document.addEventListener('mousedown', handler);

        return () => {
            document.removeEventListener('mousedown', handler);
        }
    })

    useEffect(()=>{
        if (question)
            setUrl(question.url)
    },[question])

    const uploadRecording = async () => {
        var uploader = document.getElementById("uploadRecording");
        if (uploader.files.length!=1) return;

        setStatus("generating");
        var formData = new FormData();
        formData.append("recording", uploader.files[0]);

        try {
            var res = await axios.post('https://compute.noizy.io:1221/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            setStatus("display")
            setUrl(res.data.URL)
        } catch(e) {
            setStatus("display")
        }
    }

    const generateRecording = async () => {
        setStatus("generating");
        var obj = {};
        obj.musicJSON = window.musicEditor.save();
        obj.tempo = document.getElementById("generateTempo").value;
        try {
            var res = await axios.post('https://compute.noizy.io:1221/generate',obj);
            setStatus("display")
            setUrl(res.data.URL)
        } catch(e) {
            setStatus("display")
        }
    }

    if (!question) return <></>
    if (status=="generating") {
        return (
            <div>
                <div className="overdiv">
                    <span className="overtext">Recording</span>
                </div>
                <Loader/>
            </div>
        )
    }
    if (url) {
        return (
            <div key={"recording-"+question.id}>
                <div className="overdiv">
                    <span className="overtext">Recording</span>
                </div>
                <div style={{display:'flex',alignItems:'center',gap:'0.5em'}}>
                    <audio id="recordingElement" src={url} controls={true}/>
                    <button onClick={()=>{setUrl(null)}}>Remove</button>
                </div>
            </div>
        )
    }
    return (
        <div ref={menuRef}>
            <div className="overdiv">
                <span className="overtext">Recording</span>
            </div>
            <button onClick={()=>{openMenu()}}>Add Recording</button>
            <div style={{display:(open?"":"none")}} className="question-menu">
                <div onClick={()=>{document.getElementById("uploadRecording").click(); setOpen(false);}} className="question-menu-item">Upload</div>
                <div onClick={()=>{setOpenGenerate(true); setOpen(false);}} style={{display:'flex',alignItems:'center'}} className="question-menu-item">Generate</div>
            </div>
            <input id="uploadRecording" onChange={uploadRecording} type="file" style={{display:'none'}}></input>
            {openGenerate&&
                <div className="generate-recording">
                    <div style={{opacity:0.2,background:'black',height:'100%',width:'100%',position:'absolute',top:0,left:0}}></div>
                    <div style={{position:'absolute',top:'50%',left:'50%',transform:'translate(-50%,-50%)',background:'white',padding:'20px',boxShadow:'rgba(149, 157, 165, 0.2) 0px 8px 24px',borderRadius:'5px'}}>
                        <div style={{marginBottom:'0.5em'}}>Generate recording at </div>
                        <input id="generateTempo" type="text" placeholder="Enter a tempo (bpm)"></input>
                        <div style={{display:'flex',gap:'0.25em',alignItems:'center',marginTop:'0.5em'}}>
                            <button onClick={()=>{setOpenGenerate(false);}}>Cancel</button>
                            <button onClick={()=>{setOpenGenerate(false); generateRecording();}}>Generate</button>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

function Instructions() {
    const editor = useSelector(state => state.editor)
    const questions = useSelector(state => state.questions)
    const question = questions[editor.index]

    if (!question) return <></>
    let text = question.instructions;
    if (!text) {
        switch(question.type) {
            case 'Rhythmic Dictation':
                text = 'Listen to the recording and notate the rhythm you hear.';
                break;
            case 'Melodic Dictation':
                text = 'Listen to the recording and notate the melody you hear.';
                break;
        }
    }
    
    return (
        <div>
            <div className="overdiv">
                <span className="overtext">Instructions</span>
            </div>
            <div>
                <textarea key={"instructions-"+question.id} id={"question-instructions"} defaultValue={text} style={{resize:'none',width:'100%',border:'1px solid lightgray'}}/>
            </div>
        </div>
    )
}

function QuestionSettings() {
    const editor = useSelector(state => state.editor)
    const questions = useSelector(state => state.questions)
    const question = questions[editor.index]

    //CAN VARY SIGNIFICANTLY DEPENDING ON QUESTION TYPE
    //points
    //retries
    //listenings
    //firstNoteGiven
    if (!question) return <></>
    switch(question.type) {
        case 'Rhythmic Dictation':
            return (
                <div key={"settings"+question.id} className="question-settings">
                    <div>
                        <div className="overdiv">
                            <span className="overtext">Listenings</span>
                        </div>
                        <input id="question-listenings" defaultValue={question.settings.listenings} type="text"></input>
                    </div>
                    <div>
                        <div className="overdiv">
                            <span className="overtext">Retries</span>
                        </div>
                        <input id="question-retries" defaultValue={question.settings.retries} type="text"></input>
                    </div>
                    <div>
                        <div className="overdiv">
                            <span className="overtext">First Note Given</span>
                        </div>
                        <select defaultValue={question.settings.firstNoteGiven} id="question-firstnotegiven">
                            <option>True</option>
                            <option>False</option>
                        </select>
                    </div>
                </div>
            )
            break;
        case 'Melodic Dictation':
            return (
                <div key={"settings"+question.id} className="question-settings">
                    <div>
                        <div className="overdiv">
                            <span className="overtext">Listenings</span>
                        </div>
                        <input id="question-listenings" defaultValue={question.settings.listenings} type="text"></input>
                    </div>
                    <div>
                        <div className="overdiv">
                            <span className="overtext">Retries</span>
                        </div>
                        <input id="question-retries" defaultValue={question.settings.retries} type="text"></input>
                    </div>
                    <div>
                        <div className="overdiv">
                            <span className="overtext">First Note Given</span>
                        </div>
                        <select defaultValue={question.settings.firstNoteGiven} id="question-firstnotegiven">
                            <option>True</option>
                            <option>False</option>
                        </select>
                    </div>
                </div>
            )
            break;
    }
}

function QuestionNav() {
    const editor = useSelector(state => state.editor)
    const [open, setOpen] = useState(false)
    const questions = useSelector(state => state.questions)
    const dispatch = useDispatch()
    const menuRef = useRef()

    //question list stuff
    const updateCurrent = (data) => {
        let currentQuestion = data[editor.index];
        if (currentQuestion) {
            //remove url in case recording was removed
            delete currentQuestion.url;

            let settings = {
                listenings: document.getElementById('question-listenings').value,
                retries: document.getElementById('question-retries').value,
                firstNoteGiven: document.getElementById('question-firstnotegiven').value,
            }
            currentQuestion.musicJSON = window.musicEditor.save(settings);

            //if recording, save its url
            if (document.getElementById('recordingElement'))
                currentQuestion.url = document.getElementById('recordingElement').src;
            currentQuestion.instructions = document.getElementById('question-instructions').value;

            currentQuestion.settings = settings;
        }
    }
    const setIndex = (e,index) => {
        if (e.target.id==("fo-"+index)) return;
        let data = JSON.parse(JSON.stringify(questions));

        //update current question before changing
        updateCurrent(data);

        //update store
        dispatch(
            setQuestions(data)
        )
        dispatch(
            changeIndex(index)
        )
    }
    const addQuestion = (type) => {
        //new question with default params
        let ind = questions.length;
        let data = JSON.parse(JSON.stringify(questions));
        
        //update current question before changing
        updateCurrent(data);

        //add new question
        let params = getDefaults(type);
        data.push(params);
        dispatch(
            setQuestions(data)
        )
        dispatch(
            changeIndex(ind)
        )
    }
    const deleteQuestion = (index) => {
        let data = [...questions];
        let oLength = data.length;
        data.splice(index,1);

        //if currently selected
        let ei = editor.index;
        let ind = ei;
        if (ei==index) {
            if (ei==oLength-1) ind = Math.max(ei-1,0);
        } else { //if before deleted one
            if (ei >= index) ind = ei-1;
        }
        dispatch(
            setQuestions(data)
        )

        //if no more questions, index is null
        if (data.length==0) ind = null;
        dispatch(
            changeIndex(ind)
        )
    }
    const insertQuestion = (origin,destination) => {
        let data = JSON.parse(JSON.stringify(questions));
        let oData = data[origin];

        //update current question object
        updateCurrent(data);

        //get data from origin
        data.splice(origin,1); //remove origin
        data.splice(destination,0,oData); //splice in origin data to destination
        dispatch(
            setQuestions(data)
        )
        dispatch(
            changeIndex(destination)
        )
    }
    const questionList = () => {
        return questions.map((item,index)=>{
            return (
                <div key={"question-"+index} className={"question-nav-item"+(editor.index==index?" question-selected":"")} id={"fuckoff-"+index} onDrop={drop} onDragStart={start} onDragLeave={off} onDragOver={fuck} draggable={true} style={{flex:1,padding:'3px'}} onClick={(e)=>{setIndex(e,index)}}>
                    <div>{item.type}</div>
                    <button id={"fo-"+index} onClick={()=>{deleteQuestion(index)}}>X</button>
                </div>
            )
        })
    }

    //drag and drop shit
    const start = (e) => {
        e.dataTransfer.clearData();
        e.dataTransfer.setData("text/plain", e.target.id);
    }

    const drop = (e) => {
        let dropDaddy = e.target;
        if (dropDaddy.id.indexOf("fuckoff")==-1) dropDaddy = dropDaddy.parentElement;
        if (dropDaddy.id == e.dataTransfer.getData("text")) {
            dropDaddy.classList.remove("hubber");
            return;
        }

        //insert question N before question M
        var destinationIndex = dropDaddy.id.replace("fuckoff-","");
        var originIndex = e.dataTransfer.getData("text").replace("fuckoff-","");
        insertQuestion(originIndex,destinationIndex);

        //remove hubbers
        document.getElementById(e.dataTransfer.getData("text")).classList.remove("hubber");
        dropDaddy.classList.remove("hubber");
        
    }

    const fuck = (e) => {
        e.preventDefault();
        let dropDaddy = e.target;
        if (dropDaddy.id.indexOf("fuckoff")==-1) dropDaddy = dropDaddy.parentElement;
        dropDaddy.classList.add("hubber");
    }

    const off = (e) => {
        e.target.classList.remove("hubber");
    }

    //handle question adding menu
    const getTypes = () => {
        let ary = [];
        let divs = [];
        switch(editor.type) {
            case 'Ear Training':
                ary = ['Rhythmic Dictation','Melodic Dictation'];
                break;
            case 'Singing':
                ary = ['Singing','Tapping'];
                break;
        }
        return ary.map((item,index)=>{
            return <div onClick={()=>{addQuestion(item); setOpen(false);}} className="question-menu-item" key={"question-menu-"+item}>{item}</div>
        })
    }
    const openMenu = () => {
        setOpen(true);
    }
    useEffect(()=>{
        let handler = (e) => {
            if (!menuRef.current.contains(e.target))
                setOpen(false)
        }

        document.addEventListener('mousedown', handler);

        return () => {
            document.removeEventListener('mousedown', handler);
        }
    })

    return <div className="question-nav">
        <div ref={menuRef}>
            <div style={{display:'flex',justifyContent:'center'}}><button onClick={()=>{openMenu()}}>Add Question</button></div>
            <div style={{display:(open?"":"none")}} className="question-menu">{getTypes()}</div>
        </div>
        <div>{questionList()}</div>
    </div>
}

function MusicEditor() {
    const editor = useSelector(state => state.editor)
    const questions = useSelector(state => state.questions)
    const question = questions[editor.index]

    useEffect(()=>{
        if (!questions[editor.index]) return;
        let question = JSON.parse(JSON.stringify(questions[editor.index]));
        let params = question.musicJSON;
        let musicEditor;
        switch(question.type) {
            case 'Rhythmic Dictation':
                musicEditor = new rhythmicDictation("score-editor",params);
                musicEditor.load(params);
                break;
            case 'Melodic Dictation':
                musicEditor = new melodicDictation("score-editor",params);
                musicEditor.load(params);
                break;
        }
        window.musicEditor = musicEditor;
    },[editor.index])

    if (!question) return <></>
    return (
        <div className="answer-key">
            <div className="overdiv">
                <span className="overtext">Answer Key</span>
            </div>
            <div className="music-editor" id="score-editor"></div>
        </div>
    )
}

//title
//settings
//questions
//

function getDefaults(type) { //default empty state for everything
    switch(type) {
        case 'Rhythmic Dictation':
            return {
                id: nanoid(),
                type: type,
                musicJSON: {
                    tsig: '4/4',
                    length: 4,
                    pickup: 0,
                    melody: [[{dur:'1',name:'rest'}],[{dur:'1',name:'rest'}],[{dur:'1',name:'rest'}],[{dur:'1',name:'rest'}]]
                },
                instructions: '',
                settings: {
                    points: 10,
                    retries: 3,
                    listenings: 5,
                    firstNoteGiven: true,
                },
            }
        case 'Melodic Dictation':
            return {
                id: nanoid(),
                type: type,
                musicJSON: {
                    tsig: '4/4',
                    length: 4,
                    pickup: 0,
                    clef: 'Treble',
                    key: 'C',
                    mode: 'Major',
                    melody: [[{dur:'1',name:'rest'}],[{dur:'1',name:'rest'}],[{dur:'1',name:'rest'}],[{dur:'1',name:'rest'}]]
                },
                instructions: '',
                settings: {
                    points: 10,
                    retries: 3,
                    listenings: 5,
                    firstNoteGiven: true,
                },
            }
    }
}