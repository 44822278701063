import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; 
import { useStytchSession } from '@stytch/react';
import LogOutButton from './auth/Logout.js';
import axios from 'axios';

export default function Onboarding() {
  const { session } = useStytchSession();

  useEffect(() => {
    if (!session) {
      window.location.replace('/login');
    }
  }, [session]);

  if (!session) {
    return null;
  }

  return <div>
    <Choice/>
  </div>;
};

function Choice() {
  const [choice, setChoice] = useState(null);
  const navigate = useNavigate();

  const cancelSet = () => {
    setChoice(null);
  }

  const setStudent = () => {
    setChoice('student');
  }

  const setTeacher = () => {
    setChoice('teacher');
  }

  const createStudent = async () => {
    //send first name, last name, join code, section number
    var props = ['firstName','lastName','sectionNumber'];
    var obj = {};
    for (var i=0; i<props.length; i++) {
      obj[props[i]] = document.getElementById(props[i]).value;
    }
    
    try {
      var res = await axios.post('/api/account/create-student',obj);
      //go to dashboard
      navigate('/courses');
    } catch(e) {
      //let them know
    }
  }

  const createTeacher = async () => {
    //send first name, last name
    var props = ['firstName','lastName'];
    var obj = {};
    for (var i=0; i<props.length; i++) {
      obj[props[i]] = document.getElementById(props[i]).value;
    }

    try {
      var res = await axios.post('/api/account/create-teacher',obj);
      //go to dashboard
      window.location.replace('/courses');
    } catch(e) {
      //let them know
    }
  }

  if (choice=='student') {
    return <div style={{padding:'15px'}}>
      <div style={{display:'flex',alignItems:'center',gap:'1em'}}>
        <h2 style={{marginTop:'0em'}}>Student Account Creation</h2>
      </div>
      <div>
        <input id="firstName" placeholder="First Name"></input>
        <input id="lastName" placeholder="Last Name"></input>
      </div>
      <div>
        <span>Section</span><select id="sectionNumber">
          <option>1</option>
          <option>2</option>
          <option>3</option>
          <option>4</option>
          <option>5</option>
          <option>6</option>
          <option>7</option>
          <option>8</option>
          <option>9</option>
          <option>10</option>
          <option>11</option>
          <option>12</option>
          <option>13</option>
          <option>14</option>
          <option>15</option>
          <option>16</option>
          <option>17</option>
          <option>18</option>
          <option>19</option>
          <option>20</option>
        </select>
      </div>
      <div style={{marginTop:'1em',marginBottom:"1em"}}>
        <span>Agree to <a target="_blank" href="https://www.eartrainer.io/userterms">User Terms</a> and <a target="_blank" href="https://www.eartrainer.io/customerterms">Customer Terms</a></span><input type="checkbox"></input>
      </div>
      <div>
        <button onClick={createStudent}>Create Student Account</button>
        <button onClick={cancelSet}>Cancel</button>
      </div>
    </div>
  }

  if (choice=='teacher') {
    return <div style={{padding:'15px'}}>
      <div style={{display:'flex',alignItems:'center',gap:'1em'}}>
        <h2 style={{marginTop:'0em'}}>Teacher Account Creation</h2>
      </div>
      <div>
        <input id="firstName" placeholder="First Name"></input>
        <input id="lastName" placeholder="Last Name"></input>
      </div>
      <div style={{marginTop:'1em',marginBottom:"1em"}}>
        <span>Agree to <a target="_blank" href="https://www.eartrainer.io/userterms">User Terms</a> and <a target="_blank" href="https://www.eartrainer.io/customerterms">Customer Terms</a></span><input type="checkbox"></input>
      </div>
      <div>
        <button onClick={createTeacher}>Create Teacher Account</button>
        <button onClick={cancelSet}>Cancel</button>
      </div>
    </div>
  }

  if (!choice)
    return <div style={{padding:'15px'}}>
      <h2 style={{marginTop:'0em'}}>Eartrainer.io Account Setup</h2>
      <div style={{marginBottom:'1em'}}>Which type of account would you like to create?</div>
      <div style={{border:'2px solid grey',padding:'10px',borderRadius:'5px'}}><h2>Student Account</h2><p>This account is for students in universities, colleges, and high schools who use Eartrainer.io for their music classes. <span style={{color:'red'}}>NOTE: IF YOU ACCESS THROUGH CANVAS, STOP HERE AND ACCESS THROUGH EXTERNAL TOOL</span></p><button onClick={setStudent}>Create Student Account</button></div>
      <div style={{marginTop:'1em',border:'2px solid grey',padding:'10px',borderRadius:'5px'}}><h2>Teacher Account</h2><p>This account allows you to create courses, assignments, track student grades, and sync Eartrainer.io with your LMS.</p><button onClick={setTeacher}>Create Teacher Account</button></div>
      <div style={{marginTop:'1em'}}><LogOutButton/></div>
    </div>
}

function Student() {
  return <div>
    <div>I is student</div>
    <div><LogOutButton/></div>
  </div>
}