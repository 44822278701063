import React from 'react';
import { Products } from '@stytch/vanilla-js';
import { StytchLogin } from '@stytch/react';
import Logo from './../logo/Logo.js';

const callbacks = {
  onEvent: ({ type, data }) => {
    switch(type) {
      case 'PASSWORD_AUTHENTICATE':
        window.location.replace('/courses');
        break;
      case 'PASSWORD_CREATE':
        window.location.replace('/onboarding');
        break;
    }
  },
  onError: (data) => {
    console.log(data);
  },
};

const config = {
  sessionOptions: {
    sessionDurationMinutes: 60 * 24
  },
  passwordOptions: {
    loginExpirationMinutes: 30,
    loginRedirectURL: process.env.REACT_APP_LOGIN_REDIRECT_URL,
    resetPasswordExpirationMinutes: 30,
    resetPasswordRedirectURL: process.env.REACT_APP_RESET_PASSWORD_REDIRECT_URL,
  },
  products: [
    Products.passwords,
  ],
};

export default function Login() {
  return (
    <div style={{paddingTop:'1em',width:'100%'}}>
      <div>
        <div style={{display:'flex',alignItems:'center',justifyContent:'center',marginBottom:'1em'}}>
          <Logo/>
          <div style={{fontFamily:'Courier New',fontSize:'2em'}}>Eartrainer.io</div>
        </div>
        <div style={{display:'flex',justifyContent:'center'}}>
          <StytchLogin config={config} callbacks={callbacks}/>
        </div>
      </div>
    </div>
  )
};