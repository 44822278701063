import { configureStore } from '@reduxjs/toolkit';
/*import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'*/
//import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import courseReducer from '../slices/course/courseSlice';
import assignmentReducer from '../slices/assignment/assignmentSlice';
import questionReducer from '../slices/question/questionSlice';
import editorReducer from '../slices/editor/editorSlice';
import userReducer from '../slices/user/userSlice';

//const persistConfig = {
//  key: 'root',
//  storage,
//}

//const persistedReducer = persistReducer(persistConfig, courseReducer)

const store = configureStore({
  reducer: {
    courses: courseReducer,
    assignments: assignmentReducer,
    questions: questionReducer,
    editor: editorReducer,
    user: userReducer,
  },
  /*middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),*/
});

export default store;

//export const persistor = persistStore(store);
