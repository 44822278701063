import React, { useState, useEffect } from 'react';
import { Products } from '@stytch/vanilla-js';
import { StytchPasswordReset } from '@stytch/react';
import { useNavigate, useParams } from 'react-router-dom';
import { useStytchSession } from '@stytch/react';
import Logo from './../logo/Logo.js';

const config = {
  passwordOptions: {
    loginExpirationMinutes: 30,
    loginRedirectURL: process.env.REACT_APP_LOGIN_REDIRECT_URL,
    resetPasswordExpirationMinutes: 30,
    resetPasswordRedirectURL: process.env.REACT_APP_RESET_PASSWORD_REDIRECT_URL,
  },
  products: [
    Products.passwords,
  ],
};

const Reset = () => {
  const [passwordResetToken, setPasswordResetToken] = useState('');
  const { session } = useStytchSession();
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const token = params.get('token');
    setPasswordResetToken(token ?? '');
  }, []);

  const callbacks = {
    onEvent: ({ type, data }) => {
      switch(type) {
        case 'PASSWORD_RESET_BY_EMAIL':
          //go to authenticate page?
          setTimeout(
            function() {
              navigate('/courses');
            },1000
          )
          break;
      }
      console.log(type);
      console.log(data);
    },
    onError: (data) => {
      console.log(data);
    },
  };

  return (
    <div style={{paddingTop:'1em',width:'100%'}}>
      <div>
        <div style={{display:'flex',alignItems:'center',justifyContent:'center',marginBottom:'1em'}}>
          <Logo/>
          <div style={{fontFamily:'Courier New',fontSize:'2em'}}>Eartrainer.io</div>
        </div>
        <div style={{display:'flex',justifyContent:'center'}}>
          <StytchPasswordReset
            config={config}
            passwordResetToken={passwordResetToken}
            callbacks={callbacks}
          />
        </div>
      </div>
    </div>
  );
};

export default Reset;