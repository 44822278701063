import React from 'react'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { courseDeleted } from './../../../slices/course/courseSlice';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Canvas from './../../icons/Canvas.js';
import Copy from './../../icons/Copy.js';
import toast, { Toaster } from 'react-hot-toast';
import axios from 'axios';

function copyStuff(text) {
  // Copy the text inside the text field
 navigator.clipboard.writeText(text);
 toast.success("Course code copied!");
}

export default function Courses() {
    const courses = useSelector(state => state.courses)
    const user = useSelector(state => state.user)
    const navigate = useNavigate()
    const connected = user.connectedInstitutionId ? true : false;

    const dispatch = useDispatch();

    const deleteCourse = async (id) => {
      dispatch(
        courseDeleted({id:id})
      )
      await axios.post('/delete-course',{id:id})
    }

    const viewCourse = (id) => {
      navigate('/courses/'+id+'/assignments')
    }

    const link = async () => {
      var res = await axios.post('/api/lms/tokenaction?token_action=link');
      if (res.data.token) {
        navigate('/courses/link');
      } else {
        window.location.href = res.data.flowURL;
      }
    }
    
    const renderedPosts = courses.map(post => (
      <div className="course" key={post.id}>
        <div style={{flex:1,padding:'15px'}} onClick={()=>{viewCourse(post.id)}}>
          <h3 style={{marginTop:'0px',marginBottom:'0px'}}>{post.name}</h3>
        </div>
        <div style={{display:'flex',alignItems:'center',gap:'0.5em',padding:'15px'}}>
          {(post.linked) ? <div style={{display:'flex'}}><Canvas/></div> : <div style={{fontSize:'14px',display:'flex',alignItems:'center',gap:'0.25em'}}><div className="course-code" onClick={()=>{copyStuff(post.courseCode)}} style={{border:'1px solid lightgray',display:'flex',alignItems:'center',padding:'5px',borderRadius:'5px',gap:'5px'}}><span id="courseCode">{post.courseCode}</span><Copy/></div></div>}
          {/*<div style={{display:'flex',alignItems:'center',gap:'0.5em'}}>
              <button>Edit</button>
              {(post.lmsId) ? <button>Unlink</button> : <button onClick={()=>{deleteCourse(post.id)}}>Delete</button>}
          </div>*/}
        </div>
      </div>
    ))
    
    return (
      <div className="course-list">
        <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',width:'800px'}}>
          <h2>My Courses</h2>
          <div style={{display:'flex',alignItems:'center'}}>
            {connected && <button onClick={()=>{link()}}>Link Course(s)</button>}
            <button onClick={()=>{navigate('/courses/create')}}>Create Course</button>
          </div>
        </div>
        {courses.length>0?renderedPosts:<p>{connected ? "No courses yet, click Create Course or link a course from Canvas to get started.":"No courses yet, click Create Course to get started."}</p>}
        <Toaster/>
      </div>
    )
}