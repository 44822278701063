import React from 'react';
import axios from 'axios';
import Loader from '../Loader.js';
import Save from '../icons/Save.js';
import Submit from '../icons/Submit.js';
import { Tooltip } from 'react-tooltip';
import ReactHowler from 'react-howler';
import abbreviation from './../lib/abbreviation.js';

//questions
import {rdHandler, mdHandler} from './WidgetHandlers.js';

export default class Assignment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {loading:true,selected:null};
        this.assignmentView = React.createRef();

        this.submit = this.submit.bind(this);
        this.save = this.save.bind(this);
        this.changeQuestion = this.changeQuestion.bind(this);
        this.updateSubmission = this.updateSubmission.bind(this);
    }

    async submit() {
        var obj = {};
        obj.questions = [];
        var sbj;
        for (var i=0; i<this.submission.answers.length; i++) {
            sbj = {};
            sbj.answer = this.assignment.questions[i];
            sbj.submission = this.submission.answers[i];
            obj.questions.push(sbj);
        }
        var res = await axios.post('https://compute.noizy.io:1221/submit',obj); //will grade all
        var results = res.data;
        //if no problem, then submit assignment
        for (var i=0; i<results.length; i++) {
            this.submission.answers[i].errors = results[i].errors;
            this.submission.answers[i].score = results[i].score;
        }

        if (this.assignment.lmsId) {
            var res = await axios.post('/api/assignment/submit-lms-assignment',this.submission); //this will mark it closed
        } else {
            var res = await axios.post('/api/assignment/submit-assignment',this.submission); //this will mark it closed
        }

        //if error, show it
        window.location.replace('/course/'+this.assignment.CourseId+'/assignment/'+this.assignment.id+'/submission/'+this.submission.id);
    }

    async save() {
        var obj = this.submission;
        var res = await axios.post('/api/assignment/save-submission',obj);
        //do something with res
        window.close();
    }

    async updateSubmission(prop,value) {
        //just part of it, listens, checks, musicJSON
        var obj = {};
        obj.field = prop;
        obj.value = value;
        obj.id = this.submission.id;
        var res = await axios.post('/api/assignment/update-submission',obj);
        console.log('submission updated');
    }

    changeQuestion(val) {
        //save current state
        this.submission.answers[this.state.selected].musicJSON = this.assignmentView.current.doer.current.save();
        this.setState({selected:val});
    }

    async componentDidMount() {
        //get assignment data
        //if not member of course, return nonono bad student
        var url = window.location.href;
        var courseid = url.split('/course/')[1].split('/assignment/')[0];
        var assignmentid = url.split('/assignment/')[1];
        var res = await axios.get('/api/assignment/assignment?courseid='+courseid+'&assignmentid='+assignmentid);
        this.assignment = res.data;

        //if LMS, look for lms-submission
        if (this.assignment.lmsId) {
            var res = await axios.get('/api/assignment/lms-submission?assignmentid='+assignmentid);
            if (res.data.no_submission) {
                res = await axios.post('/api/assignment/create-lms-submission',{AssignmentId:assignmentid});
            }
        } else {
            var res = await axios.get('/api/assignment/submission?assignmentid='+assignmentid);
            if (res.data.no_submission) {
                res = await axios.post('/api/assignment/create-submission',{AssignmentId:assignmentid});
            }
        }
        this.submission = res.data;

        //if already submitted
        if (this.submission.status=='submitted') {
            window.location.replace('/course/'+courseid+'/assignment/'+assignmentid+'/submission/'+this.submission.id);
            return;
        }
        this.setState({loading:false,selected:0});
    }

    render() {
        if (this.state.loading) return <Loader/>
        return (
            <div className="assignment-bro">
                <AssignmentNav changeQuestion={this.changeQuestion} selected={this.state.selected} questions={this.assignment.questions}/>
                <AssignmentView updateSubmission={this.updateSubmission} save={this.save} submit={this.submit} ref={this.assignmentView} selected={this.state.selected} submission={this.submission} assignment={this.assignment}/>
            </div>
        )
    }
}

class AssignmentView extends React.Component {
    constructor(props) {
        super(props);

        this.doer = React.createRef();
    }

    render() {
        return (
            <div className="assignment-view">
                <TopBar selected={this.props.selected} questions={this.props.assignment.questions} save={this.props.save} submit={this.props.submit} assignment={this.props.assignment}/>
                <Instructions questions={this.props.assignment.questions} selected={this.props.selected}/>
                <QuestionDoer updateSubmission={this.props.updateSubmission} ref={this.doer} selected={this.props.selected} submission={this.props.submission} assignment={this.props.assignment}/>
            </div>
        )
    }
}

class Instructions extends React.Component {
    constructor(props) {
        super(props);
        this.instructions = this.props.questions[this.props.selected].instructions;
    }

    render() {
        if (this.instructions!='')
            return (
                <div className="instructions">
                    <div style={{fontWeight:'bold'}}>Instructions</div>
                    <p>{this.instructions}</p>
                </div>
            )
    }
}

class TopBar extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
                <div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                    <h2>{this.props.assignment.name}</h2>
                    <div style={{display:'flex',alignItems:'center',gap:'0.5em'}}>
                        <button onClick={this.props.save} style={{gap:'5px',fontSize:'20px',display:'flex',alignItems:'center'}}><Save/>Save and exit</button>
                        <SubmitAssignment submit={this.props.submit}/>
                    </div>
                </div>
                <div>
                    <h3 style={{marginTop:0}}>{"Question "+(this.props.selected+1)+" of "+this.props.questions.length+" - "+this.props.questions[this.props.selected].type}</h3>
                </div>
            </>
        )
    }
}

class SubmitAssignment extends React.Component {
    constructor(props) {
      super(props);
      this.id = Math.random();

      this.submitAssignment = this.submitAssignment.bind(this);
      this.openSubmitAssignment = this.openSubmitAssignment.bind(this);
      this.closeSubmitAssignment = this.closeSubmitAssignment.bind(this);
    }

    async submitAssignment() {
        this.props.submit();
    }
    
      openSubmitAssignment() {
        document.getElementById("bigshit"+this.id).style.display = '';
      }
      
      closeSubmitAssignment() {
        document.getElementById("bigshit"+this.id).style.display = 'none';
      }
    
    render() {
      return (
        <>
          <button onClick={this.openSubmitAssignment} style={{gap:'5px',fontSize:'20px',display:'flex',alignItems:'center'}}><Submit/>Submit assignment</button>
          <div id={"bigshit"+this.id} style={{width:'100%',height:'100%',position:'absolute',top:'0px',left:'0px',display:'none',zIndex:100000}}>
            <div style={{width:'100%',height:'100%',opacity:0.5,background:'black'}}></div>
            <div style={{background:'white',position:'absolute',top:'50%',left:'50%',transform:'translate(-50%,-50%)',padding:'25px'}}>
              <h2 style={{marginTop:'0px'}}>Submit Assignment?</h2>
              <div>
                <p>Are you sure you want to submit this assignment?</p>
              </div>
              <div style={{marginTop:'1em',display:'flex',alignItems:'center',justifyContent:'center',gap:'0.25em'}}>
                <button onClick={this.closeSubmitAssignment}>Cancel</button>
                <button onClick={this.submitAssignment}>Submit Assignment</button>
              </div>
            </div>
          </div>
        </>
      )
    }
  }

class QuestionDoer extends React.Component {
    constructor(props) {
        super(props);
        this.id = Math.random();

        this.checkWork = this.checkWork.bind(this);
        this.updateMusicJSON = this.updateMusicJSON.bind(this);
        this.updateAnswers = this.updateAnswers.bind(this);
        this.showQuestion = this.showQuestion.bind(this);

        this.recording = React.createRef();
        this.checker = React.createRef();
        this.score = React.createRef();
    }

    async checkWork() {
        var answer = this.props.assignment.questions[this.props.selected];
        var submission = this.props.submission.answers[this.props.selected];
        var obj = {answer:answer,submission:submission};
        var res = await axios.post('https://compute.noizy.io:1221/grade',obj);
        var score = res.data.score;
        this.score.current.setState({score:score});
        var errors = res.data.errors;

        //show on editor
        this.editor.createErrorLists(errors);
        this.editor.hideAllErrorButtons();

        //update database
        this.props.submission.answers[this.props.selected].errors = errors;
        this.props.submission.answers[this.props.selected].score = score;
        this.updateAnswers();
    }

    updateMusicJSON(json) {
        this.props.submission.answers[this.props.selected].musicJSON = json;
        this.updateAnswers();
    }

    updateAnswers() {
        //update answers
        this.props.updateSubmission('answers',this.props.submission.answers);
    }

    showQuestion() {
        switch(this.props.assignment.questions[this.props.selected].type) {
            case 'Rhythmic Dictation':
                rdHandler(this);
                break;
            case 'Melodic Dictation':
                mdHandler(this);
                break;
        }
    }

    componentDidUpdate() {
        this.showQuestion();
    }

    componentDidMount() {
        this.showQuestion();
    }

    save() {
        return this.editor.save();
    }

    render() {
        //switch on question type
        return (
            <div className="question-doer">
                <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',border:'1px solid lightgray',borderBottom:'none'}}>
                    <div style={{display:'flex',alignItems:'center'}}>
                        <DictationRecording updateAnswers={this.updateAnswers} ref={this.recording} question={this.props.assignment.questions[this.props.selected]} answer={this.props.submission.answers[this.props.selected]} recording={this.props.submission.answers[this.props.selected].recording}/>
                        <DictationChecker parent={this} checkWork={this.checkWork} updateAnswers={this.updateAnswers} ref={this.checker} question={this.props.assignment.questions[this.props.selected]} answer={this.props.submission.answers[this.props.selected]}/>
                    </div>
                    <Score ref={this.score}/>
                </div>
                <div className="music-editor" id={this.id+"-score"}></div>
            </div>
        )
    }
}

class Score extends React.Component {
    constructor(props) {
        super(props);
        this.state = {score:null}
    }

    render() {
        if (!this.state.score) return <></>
        var col = 'green';
        var text = 'Nice work!';
        if (this.state.score < 0.85) {
            col = 'orange';
            text = 'Good job!';
        }
        if (this.state.score < 0.7) {
            col = '#E60000';
            text = 'Needs improvement!';
        }
        var s = this.state.score.toLocaleString(undefined,{style: 'percent', minimumFractionDigits:0}); 
        return (
            <div style={{margin:'5px',padding:'5px',display:'flex',alignItems:'center',gap:'0.3em'}}>
                <div style={{color:col,fontWeight:'bold'}}>{text}</div>
                <div style={{color:'white',fontWeight:'bold',background:col,borderRadius:'5px',padding:'5px'}}>{s}</div>
            </div>
        )
    }
}

class DictationChecker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {checked:this.props.answer.checked}
        this.handleToggle = this.handleToggle.bind(this);

        this.counter = React.createRef();
    }

    handleToggle() {
        //if counter is 0, no more
        if (this.counter.current.state.count==0) return;

        var status = this.state.checked;
        this.props.answer.checked = !status;

        switch(status) {
            case true:
                //retry
                this.props.parent.editor.clearErrors(); //clears out error divs
                if (this.props.parent.editor.DB)
                    this.props.parent.editor.DB.whole.attr({display:''}); //shows the dur palette
                this.props.updateAnswers();
                break;
            case false:
                //check
                this.counter.current.decrement(); //and send a listen to the server
                this.props.checkWork();
                break;
        }

        //update state
        this.setState({checked:!status});
    }

    render() {
        return (
            <div className="check-holder">
                <button onClick={this.handleToggle}>
                {(this.state.checked) ? 'Retry' : 'Check work'}
                </button>
                <CheckCounter ref={this.counter} type={"check"} answer={this.props.answer} question={this.props.question}/>
            </div>
        )
    }
}

class DictationRecording extends React.Component {
    constructor(props) {
        super(props);
        this.state = {playing:false}

        this.handleToggle = this.handleToggle.bind(this);
        this.handleStop = this.handleStop.bind(this);
        this.handleOnEnd = this.handleOnEnd.bind(this);

        this.stop = this.stop.bind(this);

        this.counter = React.createRef();
        //retries -> this.props.question.settings.retries - this.props.answer.checks
        //listens -> this.props.question.settings.listenings - this.props.answer.listenings
    }

    stop() {
        this.setState({playing:false});
    }

    handleOnEnd () {
        this.setState({
          playing: false
        })
    }

    handleToggle () {
        var status = this.state.playing;
        if (!this.state.playing) {
            //if counter is 0, no more
            if (this.counter.current.state.count==0) return;
            this.counter.current.decrement(); //and send a listen to the server
        }
        this.setState({
          playing: !this.state.playing
        })
        if (!this.state.playing) this.player.stop();

        //update answers
        this.props.updateAnswers();
    }

    handleStop () {
        this.player.stop()
        this.setState({
          playing: false // Need to update our local state so we don't immediately invoke autoplay
        })
    }

    render() {
        return (
            <div className="recording-holder">
                <ReactHowler
                    src={[this.props.recording]}
                    playing={this.state.playing}
                    onEnd={this.handleOnEnd}
                    ref={(ref) => (this.player = ref)}
                />
                <button onClick={this.handleToggle}>
                {(this.state.playing) ? 'Stop' : 'Play recording'}
                </button>
                <LACounter ref={this.counter} type={"listening"} answer={this.props.answer} question={this.props.question}/>
            </div>
        )
    }
}

class CheckCounter extends React.Component {
    constructor(props) {
        super(props);
        //var count = parseInt(this.props.question.settings.listenings) - this.props.answer.listens;
        this.state = {count:null}

        this.decrement = this.decrement.bind(this);
    }

    decrement() {
        if (this.props.question.settings.retries=='unlimited') return;
        var count = this.state.count;
        this.props.answer.checks = this.props.answer.checks + 1;
        this.setState({count:count-1});
    }

    render() {
        if (this.props.question.settings.retries=='unlimited')
            return (<></>)
        return (
            <div>
                {this.state.count+" "+this.props.type+((this.state.count==1)?"":"s")+" remaining"}
            </div>
        )
    }
}

class LACounter extends React.Component {
    constructor(props) {
        super(props);
        //var count = parseInt(this.props.question.settings.listenings) - this.props.answer.listens;
        this.state = {count:null}

        this.decrement = this.decrement.bind(this);
    }

    decrement() {
        if (this.props.question.settings.listenings=='unlimited') return;
        var count = this.state.count;
        this.props.answer.listens = this.props.answer.listens + 1;
        this.setState({count:count-1});
    }

    render() {
        if (this.props.question.settings.listenings=='unlimited')
            return (<></>)
        return (
            <div>
                {this.state.count+" "+this.props.type+((this.state.count==1)?"":"s")+" remaining"}
            </div>
        )
    }
}

class AssignmentNav extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <QuestionList changeQuestion={this.props.changeQuestion} selected={this.props.selected} questions={this.props.questions}/>
            </div>
        )
    }
}

//question list
class QuestionList extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (<div className="student-sidebar">
            {this.props.questions.map((item,index)=>{
                return <div className={index==this.props.selected?"qlist-active":"qlist"} key={"question-number"+index}>
                            <div className="question-item" id={"fuckoff-"+index} style={{flex:1,padding:'3px',display:'flex',justifyContent:'space-between'}} onClick={()=>{this.props.changeQuestion(index)}}>
                                <div>{"Q"+(index+1)}</div>
                                <div data-tooltip-id={"question-type-tooltip-"+index} data-tooltip-content={item.type} className="abbreviation">{abbreviation(item)}</div>
                            </div>
                            <Tooltip opacity={1} variant="info" id={"question-type-tooltip-"+index}/>
                        </div>
            })}
        </div>)
    }
}