import Logo from './logo/Logo.js';
import Courses from './icons/Courses.js';
import Insights from './icons/Insights.js';
import Library from './icons/Library.js';
import Account from './icons/Account.js';
import Community from './icons/Community.js';
import Logout from './icons/Logout.js';
import { useStytchSession, useStytch } from '@stytch/react';
import { useNavigate } from 'react-router-dom';

export default function Navbar() {
    const { session } = useStytchSession();
    const stytchClient = useStytch();
    const navigate = useNavigate()
    if (!session) return <></>
    return <div className="noizy-navbar">
        <div>
            <Logo/>
            <NavItems/>
        </div>
        <div style={{marginBottom:'1em'}}>
            <div onClick={()=>{navigate('/account')}} className="nav-menu-bottom">
                <Account/>
                <div className="nav-title">Account</div>
            </div>
            <div onClick={()=>{stytchClient.session.revoke()}} className="nav-menu-bottom">
                <Logout/>
                <div className="nav-title">Logout</div>
            </div>
        </div>
    </div>
}

function NavItems() {
    const navigate = useNavigate()
    const path = window.location.pathname;
    let coursesSelected = false;
    if (path.split('/')[1]=='courses') coursesSelected = true;
    return <div>
        <div className={"nav-menu-item"+(coursesSelected?" nav-select":"")} onClick={()=>{navigate('/courses')}}>
            <Courses/>
            <div className="nav-title">Courses</div>
        </div>
        <div className="nav-menu-item">
            <Insights/>
            <div className="nav-title">Insights</div>
        </div>
        <div className="nav-menu-item">
            <Library/>
            <div className="nav-title">Library</div>
        </div>
        <div className="nav-menu-item">
            <Community/>
            <div className="nav-title">Community</div>
        </div>
    </div>
}