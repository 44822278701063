import { createSlice, nanoid } from '@reduxjs/toolkit';

const initialState = [];

export const questionSlice = createSlice({
  name: 'question',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    questionAdded: {
      reducer(state, action) {
        state.push(action.payload)
      },
      prepare({type, musicJSON, instructions, settings}) {
        return {
          payload: {
            id: nanoid(),
            type: type,
            musicJSON: musicJSON,
            instructions: instructions,
            settings: settings,
          }
        }
      }
    },
    questionDeleted(state, action) {
      const { id } = action.payload;
      let arr = state.filter(item => item.id !== id)
      return arr;
    },
    questionUpdated(state, action) {
      const { id, musicJSON, instructions, settings } = action.payload
      const existingQuestion = state.find(post => post.id === id)
      if (existingQuestion) {
        existingQuestion.musicJSON = musicJSON
        existingQuestion.instructions = instructions
        existingQuestion.settings = settings
      }
    },
    musicUpdated(state, action) {
      const { id, musicJSON } = action.payload
      const existingQuestion = state.find(post => post.id === id)
      if (existingQuestion) {
        existingQuestion.musicJSON = musicJSON
      }
    },
    setQuestions(state, action) {
      return action.payload;
    },
    clearQuestions() {
      return [];
    },
  },
});

export const { questionAdded, questionUpdated, questionDeleted, setQuestions, clearQuestions, musicUpdated } = questionSlice.actions;
//export const selectquestion = (state) => state.user.value;

export default questionSlice.reducer;
