import React from 'react';
import axios from 'axios';
import Loader from '../Loader.js';
import Logo from '../logo/Logo.js';
import Save from '../icons/Save.js';
import Submit from '../icons/Submit.js';
import { Tooltip } from 'react-tooltip';
import ReactHowler from 'react-howler';
import toast, { Toaster } from 'react-hot-toast';

//questions
import rhythmicDictation from './../lib/student/rhythmicdictation.js';
import melodicDictation from './../lib/student/melodicdictation.js';

export default class Submission extends React.Component {
    constructor(props) {
        super(props);
        this.state = {loading:true}
    }

    async componentDidMount() {
        //get assignment data
        //if not member of course, return nonono bad student
        var url = window.location.href;
        var submissionid = url.split('/submission/')[1];
        var res = await axios.get('/api/assignment/get-submission?submissionid='+submissionid);
        this.submission = res.data.submission;
        this.assignment = res.data.assignment;

        //get role
        //var res = await axios.get('/me');
        //this.role = res.data.role;

        this.setState({loading:false});
    }

    render() {
        if (this.state.loading) return <Loader/>
        return (
            <div style={{padding:'1em'}}>
                <TopBar assignment={this.assignment} submission={this.submission} role={this.role}/>
                <ViewSubmission submission={this.submission}/>
                <Toaster/>
            </div>
        )
    }
}

class ViewSubmission extends React.Component {
    constructor(props) {
        super(props);
        this.answers = this.props.submission.answers;
        this.id = Math.random();
    }

    render() {
        return (
            <div>{
                this.answers.map((item,index)=>{
                    return <QuestionViewer answer={item} index={index} key={"answer-viewer-"+index+"-"+this.id}/>
                })
            }</div>
        )
    }
}

class ResetButton extends React.Component {
    constructor(props) {
        super(props);

        this.reset = this.reset.bind(this);
    }

    async reset() {
        var res = await axios.post('/reset-submission',this.props.submission);
        toast.success('Submission reset!');
    }

    render() {
        return (
            <div>
                <button onClick={this.reset}>Reset Submission</button>
            </div>
        )
    }
}

class OverallScore extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        var col = 'green';
        var text = 'Nice work!';
        if (this.props.grade < 0.85) {
            col = 'orange';
            text = 'Good job!';
        }
        if (this.props.grade < 0.7) {
            col = '#E60000';
            text = 'Needs improvement!';
        }
        var s = this.props.grade.toLocaleString(undefined,{style: 'percent', minimumFractionDigits:0}); 
        return (
            <div style={{display:'flex',alignItems:'center',gap:'1em'}}>
                {(this.props.late) ? <div style={{color:'#E60000',fontWeight:'bold',fontSize:'20px'}}>LATE</div> : <></>}
                <div style={{display:'flex',alignItems:'center',gap:'0.3em',background:col,padding:'10px',color:'white',borderRadius:'5px',fontWeight:'bold',fontSize:'20px'}}>
                    <div>Overall Score:</div>
                    <div>{s}</div>
                </div>
            </div>
        )
    }
}

class TopBar extends React.Component {
    constructor(props) {
        super(props);

        var dueTime = new Date(this.props.assignment.dueDate).getTime();
        var submitTime = parseInt(this.props.submission.submitDate);
        this.late = (submitTime > (dueTime + 1000 * 60 * 60 * 24)) ? true : false;
    }

    render() {
        return (
            <div className="submission-bar">
                <h2 style={{marginTop:0,marginBottom:0}}>{this.props.assignment.name}</h2>
                <OverallScore late={this.late} grade={this.props.submission.grade}/>
            </div>
        )
        /*if (this.props.role=='teacher') { //show button
            return (
                <div className="submission-bar">
                    <h2 style={{marginTop:0,marginBottom:0}}>{this.props.assignment.name}</h2>
                    <OverallScore late={this.late} grade={this.props.submission.grade}/>
                    <ResetButton submission={this.props.submission}/>
                </div>
            )
        } else {
            return (
                <div className="submission-bar">
                    <h2 style={{marginTop:0,marginBottom:0}}>{this.props.assignment.name}</h2>
                    <OverallScore late={this.late} grade={this.props.submission.grade}/>
                </div>
            )
        }*/
    }
}

function rdHandler(parent) {
    var params = parent.props.answer.musicJSON;
    var id = parent.id+"-score";
    parent.editor = new rhythmicDictation(id,params);
    parent.editor.load(params);
    parent.editor.createErrorLists(parent.props.answer.errors);
    parent.editor.hideAllErrorButtons();
}

function mdHandler(parent) {
    var params = parent.props.answer.musicJSON;
    var id = parent.id+"-score";
    parent.editor = new melodicDictation(id,params);
    parent.editor.load(params);
    parent.editor.createErrorLists(parent.props.answer.errors);
    parent.editor.hideAllErrorButtons();
}

class QuestionViewer extends React.Component {
    constructor(props) {
        super(props);
        this.id = Math.random();

        this.score = React.createRef();
    }

    componentDidMount() {
        switch(this.props.answer.type) {
            case 'Rhythmic Dictation':
                rdHandler(this);
                break;
            case 'Melodic Dictation':
                mdHandler(this);
                break;
        }

        //score
        var score = this.props.answer.score;
        this.score.current.setState({score:score});
    }

    render() {
        return (
            <div className="question-doer submission-viewer">
                <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',border:'1px solid lightgray',borderBottom:'none'}}>
                    <div style={{fontWeight:'bold',padding:'5px'}}>{"Question "+(this.props.index+1)+" - "+this.props.answer.type}</div>
                    <Score ref={this.score}/>
                </div>
                <div className="music-editor" id={this.id+"-score"}></div>
            </div>
        )
    }
}

class Score extends React.Component {
    constructor(props) {
        super(props);
        this.state = {score:null}
    }

    render() {
        if (!this.state.score) return <></>
        var col = 'green';
        var text = 'Nice work!';
        if (this.state.score < 0.85) {
            col = 'orange';
            text = 'Good job!';
        }
        if (this.state.score < 0.7) {
            col = '#E60000';
            text = 'Needs improvement!';
        }
        var s = this.state.score.toLocaleString(undefined,{style: 'percent', minimumFractionDigits:0}); 
        return (
            <div style={{margin:'5px',padding:'5px',display:'flex',alignItems:'center',gap:'0.3em'}}>
                <div style={{color:col,fontWeight:'bold'}}>{text}</div>
                <div style={{color:'white',fontWeight:'bold',background:col,borderRadius:'5px',padding:'5px'}}>{s}</div>
            </div>
        )
    }
}